import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { messages } from '@dap/common/i18n';
import { useTranslation } from 'react-i18next';
import { SanityBody } from '../pageContent';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useSanityContextActions } from '@dap/sanity/utils';
import { ContactCard } from '@dap/common/ui';
import { Link } from 'react-router-dom';
import { MosaicModuleConfig } from '@dap/sanity/types';

export default function MosaicDetails({
  textBlock,
  contact,
  callToAction,
  attachments,
  image,
  getOrderRoute,
}: Pick<MosaicModuleConfig, 'textBlock' | 'contact' | 'callToAction' | 'attachments' | 'image'> & {
  getOrderRoute: ({ moduleSlug }: { moduleSlug: string }) => string;
}) {
  const { t } = useTranslation('mosaic');
  const { getImageUrlBuilder } = useSanityContextActions();

  return (
    <Paper elevation={0} sx={paperSx}>
      <Grid container>
        <Grid item xs>
          <Stack direction="row" spacing={2}>
            <Box flexGrow={1}>
              {textBlock && <SanityBody body={textBlock} />}
              <Stack direction="row" spacing={2} mt={4}>
                {callToAction?.externalUrl ||
                  (callToAction?.internalUrl && (
                    <Button
                      variant="contained"
                      component={Link}
                      to={
                        callToAction.externalUrl ||
                        getOrderRoute({ moduleSlug: callToAction.internalUrl.slug })
                      }
                    >
                      {callToAction.linkText}
                    </Button>
                  ))}
                {attachments?.length && (
                  <Button
                    variant="outlined"
                    href={attachments[0].url}
                    startIcon={<DescriptionOutlinedIcon />}
                  >
                    {attachments[0].label}
                  </Button>
                )}
              </Stack>
            </Box>
            {image && <img src={getImageUrlBuilder(image).url()} alt="" height="20px" />}
          </Stack>
        </Grid>
        {contact && (
          <>
            <Divider orientation="vertical" flexItem sx={dividerSx} />
            <Grid item xs={12} md={4} minWidth="255px">
              <Box
                display="flex"
                flexDirection="column"
                alignItems={{ xs: 'left', md: 'center' }}
                mt={{ xs: 4, md: 0 }}
              >
                <Typography variant="h4" mb={2} textAlign={{ xs: 'left', md: 'center' }}>
                  {t(messages.mosaic.contact.title)}
                </Typography>
                <ContactCard
                  {...contact}
                  userPicture={getImageUrlBuilder(contact.image).url()}
                  phoneNumber={contact.phone}
                />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
}

const paperSx: SxProps<Theme> = ({ palette }) => ({
  p: 3,
  // ASO THEME bgcolor: palette.cardBackgrounds.lightPurple,
  bgcolor: 'deeppink',
});

const dividerSx: SxProps<Theme> = ({ spacing }) => ({
  margin: spacing(0, 4),
  display: { xs: 'none', md: 'block' },
});
