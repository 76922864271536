// Based on https://github.com/tryhuset/mestergruppen-houses/blob/main/packages/shared/components/lightbox.tsx

import { type PhotoSwipe } from 'photoswipe/lightbox';
import 'photoswipe/dist/photoswipe.css';
import { Gallery, GalleryProps } from 'react-photoswipe-gallery';
import './photoswipe-overview-plugin.css';

import ObjectPositionPlugin from './photoswipe-object-position-plugin';
import PhotoSwipeOverviewPlugin from './photoswipe-overview-plugin';

export function Lightbox(props: GalleryProps & { onSlideChange?: (index: number) => void }) {
  const onBeforeOpen = (pswpInstance: PhotoSwipe) => {
    pswpInstance.on('change', () => {
      if (
        typeof props.onSlideChange === 'function' &&
        pswpInstance?.currIndex !== undefined &&
        pswpInstance.isOpen &&
        !pswpInstance.isDestroying
      ) {
        props.onSlideChange?.(pswpInstance.currIndex);
      }
    });
  };

  return (
    <Gallery
      {...props}
      onBeforeOpen={onBeforeOpen}
      withCaption
      plugins={(photoswipeLightbox) => {
        new PhotoSwipeOverviewPlugin(photoswipeLightbox as any);
        new ObjectPositionPlugin(photoswipeLightbox as any);
      }}
    />
  );
}
