import { Stack, Typography, Card } from '@mui/material';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { ErrorBoundary } from '@sentry/react';
import { FillMinMax } from '@shared/components';
import { Link } from 'react-router-dom';
import { useSanityContextActions } from '@dap/sanity/utils';
import { SanityBody } from '../pageContent';
import ProffInfoBanner from './ProffInfoBanner';
import { ProffModuleConfig } from '@dap/sanity/types';

interface Props extends ProffModuleConfig {
  brandImageUrl: string;
}

function AllProffCategoriesList({
  header,
  frontPageProffImage,
  infoText,
  brandImageUrl,
  categories,
}: Props) {
  return (
    <Stack spacing={3}>
      <ProffInfoBanner brandImageUrl={brandImageUrl} backgroundImage={frontPageProffImage}>
        <>
          <Typography variant="h1">{header}</Typography>
          <SanityBody body={infoText} />
        </>
      </ProffInfoBanner>

      <Card>
        <FillMinMax minWidth="160px" gridGap="2.5rem 1.5rem">
          {categories.map((category) => (
            <CategoryLink
              key={category.id}
              title={category.title}
              to={category.slug}
              backgroundImage={category.image}
            />
          ))}
        </FillMinMax>
      </Card>
    </Stack>
  );
}

interface CategoryLinkProps {
  title: string;
  to: string;
  backgroundImage?: SanityImageSource;
}

function CategoryLink({ title, to, backgroundImage }: CategoryLinkProps) {
  const { getImageUrlBuilder } = useSanityContextActions();
  return (
    <ErrorBoundary>
      <Card
        component={Link}
        to={to}
        sx={{
          aspectRatio: 1,
          display: 'flex',
          alignItems: 'flex-end',
          p: 0,
          textDecoration: 'none',
          background: backgroundImage
            ? `url(${getImageUrlBuilder(backgroundImage).height(600).url()})`
            : '#0006',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Typography
          variant="body1"
          fontWeight={600}
          color="white"
          component="span"
          sx={{
            lineHeight: 1.235,
            padding: 2,
            background: ({ palette }) =>
              `linear-gradient(180deg, transparent 0%, ${palette.common.black} 100%)`,
            flexGrow: '1',
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      </Card>
    </ErrorBoundary>
  );
}

export default AllProffCategoriesList;
