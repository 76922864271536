import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AjaxError } from '@shared/fetch-utils';
import { UserData } from '@dap/admin/types';
import { LoadingState, LoadingStatus } from '@dap/common/types';

export interface UserState {
  userData: LoadingState<UserData, AjaxError>;
  userPicture: string;
}

export const userInitialState: UserState = {
  userData: { status: LoadingStatus.notLoaded },
  userPicture: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    addUserPicture: (state, action: PayloadAction<string>) => {
      state.userPicture = action.payload;
    },
  },
});

export const { addUserPicture } = userSlice.actions;

export default userSlice.reducer;
