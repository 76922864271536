import { SvgIcon, SvgIconProps } from '@mui/material';

export const IncreaseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="11"
        viewBox="0 0 13 11"
        fill="none"
      >
        <path
          d="M5.63398 0.499999C6.01888 -0.166667 6.98112 -0.166667 7.36603 0.5L12.5622 9.5C12.9471 10.1667 12.466 11 11.6962 11H1.30385C0.534047 11 0.0529221 10.1667 0.437822 9.5L5.63398 0.499999Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
