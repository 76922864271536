import {
  BrandConfig,
  BrandConfigurationRequest,
  BrandConfiguration,
  BrandNavigation,
  Category,
  ModuleType,
  OrderCategory,
  OrderCategoryExtended,
  SanityArticlePreview,
  SanityEvent,
  SanityEventPreview,
  SanityModuleArticle,
  SanityNewsArticlePreview,
  SanityServiceArticle,
  SanityServicePreview,
  SanityStandardArticle,
  SanityLatestArticle,
  HelpCenterModuleConfig,
  EventSourceType,
  SanityDashboard,
  TopicPage,
  TopicPageRequest,
  SubjectFrontpage,
  SubjectTopicPage,
} from '@dap/sanity/types';
import {
  replaceBrandPlaceholderInArticle,
  replaceBrandPlaceholderInArticlePreview,
  transformHelpCentreResponseToHelpCentre,
} from '@dap/sanity/utils';
import { createApi } from '@reduxjs/toolkit/query/react';
import { startOfMonth } from 'date-fns';
import { dapSanityClient } from '../client/dapSanityClient';
import { getBaseQueryFn } from './baseQueryFn';
import {
  GetModuleArticleQueryProps,
  getModuleArticleQuery,
  GetStandardArticleQueryProps,
  getStandardArticleQuery,
  getArticleByIdQuery,
  getLatestArticlesQuery,
} from './queries/articleQueries';
import { getEventPreviewsQuery, getEventQuery, GetEventQueryProps } from './queries/eventQueries';
import {
  GetFremdriftArticlesPreviewQueryProps,
  getFremdriftArticlePreviewsQuery,
} from './queries/fremdriftQueries';
import { getHelpCentreModule, getModuleConfigQuery } from './queries/moduleQueries';
import {
  GetNewsArticlesPreviewQueryProps,
  getNewsArticlePreviewsQuery,
} from './queries/newsQueries';
import { getOrderCategoriesQuery, getOrderCategoryQuery } from './queries/orderQueries';
import { getProffCategoriesQuery, getProffCategoryQuery } from './queries/proffQueries';
import { getServiceArticleQuery, getServicePreviewsQuery } from './queries/servicesQueries';
import {
  getBrandConfiguration,
  getBrandDashboardQuery,
  getBrandNavigationQuery,
} from './queries/brandConfigQueries';
import { getTopicPageQuery } from './queries/topicPageQueries';
import {
  subjectFrontpageQuery,
  SubjectFrontpageQueryProps,
  subjectQuery,
  SubjectQueryProps,
  subjectTopicPageQuery,
  SubjectTopicPageQueryProps,
} from './queries/subjectQueries';

export const sanityApi = createApi({
  reducerPath: 'sanityApi',
  baseQuery: getBaseQueryFn(dapSanityClient),
  endpoints: (builder) => ({
    getBrandConfig: builder.query<BrandConfiguration, BrandConfigurationRequest>({
      query: (props) => ({ query: getBrandConfiguration(props) }),
    }),

    getBrandNavigation: builder.query<BrandNavigation, BrandConfigurationRequest>({
      query: (props) => ({ query: getBrandNavigationQuery(props) }),
    }),

    getModuleConfig: builder.query<BrandConfig, { brandKey: string; brandName?: string }>({
      query: (props) => ({ query: getModuleConfigQuery(props) }),
      transformResponse: (config: BrandConfig, _, { brandName }) => {
        if (config[ModuleType.helpCentreModule])
          return {
            ...config,
            [ModuleType.helpCentreModule]: transformHelpCentreResponseToHelpCentre(
              config.help_centre_module,
              brandName || ''
            ),
          };

        return config;
      },
    }),

    getModuleArticle: builder.query<
      SanityModuleArticle,
      GetModuleArticleQueryProps & { brandName: string }
    >({
      query: ({ moduleId, categorySlug, brandKey, slug }) => ({
        query: getModuleArticleQuery({ moduleId, categorySlug, brandKey, slug }),
      }),
      transformResponse: (article: SanityModuleArticle, _, { brandName }) =>
        replaceBrandPlaceholderInArticle(article, brandName),
    }),

    getStandardArticle: builder.query<
      SanityStandardArticle,
      { brandName: string } & GetStandardArticleQueryProps
    >({
      query: ({ brandName, ...props }) => ({
        query: getStandardArticleQuery(props),
      }),
      transformResponse: (article: SanityStandardArticle, _, { brandName }) =>
        replaceBrandPlaceholderInArticle(article, brandName),
    }),

    getHelpCentreModule: builder.query<
      HelpCenterModuleConfig,
      { brandKey: string; brandName: string }
    >({
      query: ({ brandKey }) => ({ query: getHelpCentreModule({ brandKey }) }),
      transformResponse: (config: HelpCenterModuleConfig, _, { brandName }) =>
        transformHelpCentreResponseToHelpCentre(config, brandName),
    }),

    getFremdriftArticles: builder.query<
      SanityArticlePreview[],
      GetFremdriftArticlesPreviewQueryProps & { brandName: string }
    >({
      query: ({ categoryId, brandKey }) => ({
        query: getFremdriftArticlePreviewsQuery({ categoryId, brandKey }),
      }),
      transformResponse: (articles: SanityArticlePreview[], _, { brandName }) =>
        articles.map((article) => replaceBrandPlaceholderInArticlePreview(article, brandName)),
    }),

    getOrderCategories: builder.query<
      Array<OrderCategory>,
      { moduleId: string; brandName: string }
    >({
      query: ({ moduleId }) => ({
        query: getOrderCategoriesQuery(moduleId),
      }),
      transformResponse: (categories: Array<OrderCategory>, _, { brandName }) =>
        categories.map((category) => ({
          ...category,
          items: category?.items?.map((article) =>
            replaceBrandPlaceholderInArticle(article, brandName)
          ),
        })),
    }),

    getOrderCategory: builder.query<
      OrderCategoryExtended,
      { moduleId: string; brandName: string; categorySlug: string }
    >({
      query: ({ moduleId, categorySlug }) => ({
        query: getOrderCategoryQuery(moduleId, categorySlug),
      }),
      transformResponse: (category: OrderCategoryExtended, _, { brandName }) => ({
        ...category,
        items: category?.items?.map((article) =>
          replaceBrandPlaceholderInArticle(article, brandName)
        ),
      }),
    }),

    getProffCategory: builder.query<
      Category,
      { moduleId: string; brandName: string; categorySlug: string }
    >({
      query: ({ moduleId, categorySlug }) => ({
        query: getProffCategoryQuery(moduleId, categorySlug),
      }),
      transformResponse: (category: Category, _, { brandName }) => ({
        ...category,
        items: category?.items?.map((article) =>
          replaceBrandPlaceholderInArticle(article, brandName)
        ),
      }),
    }),

    getProffCategories: builder.query<Array<Category>, { moduleId: string; brandName: string }>({
      query: ({ moduleId }) => ({
        query: getProffCategoriesQuery(moduleId),
      }),
      transformResponse: (categories: Array<Category>, _, { brandName }) =>
        categories.map((category) => ({
          ...category,
          items: category?.items?.map((article) =>
            replaceBrandPlaceholderInArticle(article, brandName)
          ),
        })),
    }),

    getNewsArticlePreviews: builder.query<
      SanityNewsArticlePreview[],
      GetNewsArticlesPreviewQueryProps
    >({
      query: (props) => ({
        query: getNewsArticlePreviewsQuery(props),
      }),
      transformResponse: (
        articles: SanityNewsArticlePreview[],
        _,
        { brandName, articles: predefinedArticles }
      ) => {
        const mappedArticles = articles.map((article) =>
          replaceBrandPlaceholderInArticlePreview(article, brandName)
        );

        // Return the mapped articles if we don't have any predefined articles to use as a sort criteria
        if (!predefinedArticles || predefinedArticles.length === 0) {
          return mappedArticles;
        }

        // Iterate the mapped articles, find their index in the predefined article and position it in the returned array accordingly
        return mappedArticles.reduce((acc, article) => {
          const index = predefinedArticles.findIndex(
            (predefinedArticle) => predefinedArticle._ref === article.id
          );
          if (index === -1) {
            return acc;
          }
          acc[index] = article;
          return acc;
        }, [] as SanityNewsArticlePreview[]);
      },
    }),

    getServiceArticlePreviews: builder.query<
      SanityServicePreview[],
      { brandName: string; brandKey: string }
    >({
      query: ({ brandKey }) => ({
        query: getServicePreviewsQuery({ brandKey }),
      }),
      transformResponse: (articles: SanityServicePreview[], _, { brandName }) =>
        articles.map((article) => replaceBrandPlaceholderInArticlePreview(article, brandName)),
    }),

    getServiceArticle: builder.query<
      SanityServiceArticle,
      { slug: string; brandKey: string; brandName: string }
    >({
      query: ({ brandKey, slug }) => ({
        query: getServiceArticleQuery({ brandKey, slug }),
      }),
      transformResponse: (article: SanityServiceArticle, _, { brandName }) => {
        return replaceBrandPlaceholderInArticle(article, brandName);
      },
    }),

    getEventPreviews: builder.query<
      SanityEventPreview[],
      {
        brandName: string;
        brandKey: string;
        source: EventSourceType;
        maxNumberToLoad?: number;
        fromFirstOfMonth?: boolean;
        subject?: string;
      }
    >({
      query: ({ brandKey, source, maxNumberToLoad, fromFirstOfMonth = false, subject }) => {
        const today = new Date();
        const date = fromFirstOfMonth ? startOfMonth(today) : today;
        return {
          query: getEventPreviewsQuery({
            brandKey,
            date: date.toISOString(),
            source,
            maxNumberToLoad,
            subject,
          }),
        };
      },
      transformResponse: (eventPreviews: SanityEventPreview[], _, { brandName }) =>
        eventPreviews.map((eventPreview) =>
          replaceBrandPlaceholderInArticlePreview(eventPreview, brandName)
        ),
    }),

    getEvent: builder.query<SanityEvent, { brandName: string } & GetEventQueryProps>({
      query: ({ brandName, ...props }) => ({
        query: getEventQuery(props),
      }),
      transformResponse: (eventResult: SanityEvent, _, { brandName }) =>
        replaceBrandPlaceholderInArticle(eventResult, brandName),
    }),

    getBrandDashboard: builder.query<SanityDashboard, BrandConfigurationRequest>({
      query: (props) => ({ query: getBrandDashboardQuery(props) }),
    }),

    getArticleById: builder.query<SanityStandardArticle, { id: string; brandName: string }>({
      query: ({ id }) => ({
        query: getArticleByIdQuery({ id, modules: [ModuleType.newsModule] }),
      }),
      transformResponse: (article: SanityStandardArticle, _, { brandName }) =>
        replaceBrandPlaceholderInArticle(article, brandName),
    }),

    getLatestArticles: builder.query<
      SanityLatestArticle[],
      { brandKey: string; brandName: string; limit: number }
    >({
      query: ({ brandKey, limit }) => ({
        query: getLatestArticlesQuery({ brandKey, limit }),
      }),
      transformResponse: (articles: SanityLatestArticle[], _, { brandName }) =>
        articles.map((article) => replaceBrandPlaceholderInArticlePreview(article, brandName)),
    }),

    getTopicPage: builder.query<TopicPage, TopicPageRequest>({
      query: (props) => ({ query: getTopicPageQuery(props) }),
    }),

    // Subject queries
    getSubjectFrontpage: builder.query<SubjectFrontpage, SubjectFrontpageQueryProps>({
      query: (props) => ({ query: subjectFrontpageQuery(props) }),
    }),

    getSubjectTopicPage: builder.query<SubjectTopicPage, SubjectTopicPageQueryProps>({
      query: (props) => ({ query: subjectTopicPageQuery(props) }),
    }),

    // TODO Add correct types and props
    getSubject: builder.query<any, SubjectQueryProps>({
      query: (props) => ({ query: subjectQuery(props) }),
    }),
  }),
});

export const {
  useGetBrandConfigQuery,
  useGetModuleConfigQuery,
  useGetModuleArticleQuery,
  useGetStandardArticleQuery,
  useGetFremdriftArticlesQuery,
  useGetHelpCentreModuleQuery,
  useGetOrderCategoriesQuery,
  useGetOrderCategoryQuery,
  useGetProffCategoryQuery,
  useGetProffCategoriesQuery,
  useGetNewsArticlePreviewsQuery,
  useGetServiceArticlePreviewsQuery,
  useGetServiceArticleQuery,
  useGetEventPreviewsQuery,
  useGetEventQuery,
  useGetBrandDashboardQuery,
  useGetArticleByIdQuery,
  useGetLatestArticlesQuery,
  useGetBrandNavigationQuery,
  useGetTopicPageQuery,
  // Subject queries
  useGetSubjectFrontpageQuery,
  useGetSubjectTopicPageQuery,
  useGetSubjectQuery,
} = sanityApi;
