import {
  BrandConfigurationRequest,
  FremdriftModuleConfig,
  ModuleConfigRequest,
  ModuleType,
  MosaicModuleConfig,
  OrderModuleConfig,
  ProffModuleConfig,
  SanityModuleConfigBase,
  TopicPageRequest,
} from '@dap/sanity/types';
import { SanityQueryResult } from './types';
import {
  useGetBrandConfigQuery,
  useGetModuleConfigQuery,
  useGetBrandDashboardQuery,
  useGetBrandNavigationQuery,
  useGetTopicPageQuery,
  useGetSubjectFrontpageQuery,
  useGetSubjectTopicPageQuery,
  useGetSubjectQuery,
} from './sanityApi';

interface BrandProps {
  brandKey?: string;
  brandName?: string;
}

export const useGetBrandConfig = ({ brandKey }: BrandConfigurationRequest) => {
  const brandConfigurationState = useGetBrandConfigQuery({ brandKey }, { skip: !brandKey });

  if (brandConfigurationState.isError) {
    return undefined;
  }

  return brandConfigurationState.data;
};

export const useGetBrandNavigation = ({ brandKey }: BrandConfigurationRequest) => {
  const brandNavigationState = useGetBrandNavigationQuery({ brandKey }, { skip: !brandKey });

  if (brandNavigationState.isError) {
    return undefined;
  }

  return brandNavigationState.data;
};

export const useGetBrandConfigState = <
  ModuleT extends SanityModuleConfigBase = SanityModuleConfigBase
>({
  brandKey,
  moduleType,
}: ModuleConfigRequest) => {
  const brandConfigurationState = useGetBrandConfigQuery({ brandKey }, { skip: !brandKey });

  const module = brandConfigurationState.data?.[moduleType] as ModuleT | undefined;

  return {
    ...brandConfigurationState,
    data: module,
  } as SanityQueryResult<ModuleT>;
};

export const useGetModuleConfig = <
  ModuleT extends SanityModuleConfigBase = SanityModuleConfigBase
>({
  brandKey = '',
  brandName = '',
  moduleType,
}: BrandProps & { moduleType: ModuleType }) => {
  const moduleConfigState = useGetModuleConfigQuery(
    { brandKey, brandName },
    { skip: !brandKey || !brandName }
  );
  if (moduleConfigState.isError) {
    return undefined;
  }

  return moduleConfigState.data?.[moduleType] as ModuleT | undefined;
};

export const useGetModuleBySlug = <
  ModuleT extends SanityModuleConfigBase = SanityModuleConfigBase
>({
  brandKey = '',
  brandName = '',
  moduleType,
  moduleSlug,
}: BrandProps & { moduleType: ModuleType; moduleSlug: string }) => {
  const moduleConfigState = useGetModuleConfigQuery(
    { brandKey, brandName },
    { skip: !brandKey || !brandName }
  );

  if (moduleConfigState.isError) {
    return undefined;
  }

  // TODO: Change so that all module types are arrays
  if (moduleType === ModuleType.fremdriftModule) {
    return moduleConfigState.data?.fremdrift_module?.find(
      (fremdriftModule: FremdriftModuleConfig) => fremdriftModule.slug === moduleSlug
    ) as ModuleT | undefined;
  } else if (moduleType === ModuleType.proffModule) {
    return moduleConfigState.data?.proff_module?.find(
      (proffModule: ProffModuleConfig) => proffModule.slug === moduleSlug
    ) as ModuleT | undefined;
  } else if (moduleType === ModuleType.mosaicModule) {
    return moduleConfigState.data?.mosaic_module?.find(
      (mosaicModule: MosaicModuleConfig) => mosaicModule.slug === moduleSlug
    ) as ModuleT | undefined;
  } else if (moduleType === ModuleType.orderModule) {
    return moduleConfigState.data?.order_module?.find(
      (orderModule: OrderModuleConfig) => orderModule.slug === moduleSlug
    ) as ModuleT | undefined;
  }

  return moduleConfigState.data?.[moduleType] as ModuleT | undefined;
};

export const useGetBrandDashboard = ({ brandKey }: BrandConfigurationRequest) => {
  const state = useGetBrandDashboardQuery({ brandKey }, { skip: !brandKey });

  if (state.isError) {
    return undefined;
  }

  return state.data;
};

export const useGetTopicPage = (props: TopicPageRequest) => {
  const state = useGetTopicPageQuery(props, { skip: !props.brandKey });

  if (state.isError) {
    return undefined;
  }

  if (state.status === 'pending') {
    return undefined;
  }

  return state.data;
};

interface SubjectFrontpageProps {
  brandKey: string;
  subject: string;
}

export const useGetSubjectFrontpage = (props: SubjectFrontpageProps) => {
  const state = useGetSubjectFrontpageQuery(props);

  if (state.isError) {
    return undefined;
  }

  if (state.status === 'pending') {
    return undefined;
  }

  return state.data;
};

interface SubjectQueryProps {
  subject: string;
}

export const useGetSubject = (props: SubjectQueryProps) => {
  const state = useGetSubjectQuery(props);

  if (state.isError) {
    return undefined;
  }

  if (state.status === 'pending') {
    return undefined;
  }

  return state.data;
};

interface SubjectTopicPageProps {
  brandKey: string;
  subject: string;
  topic: string;
}

export const useGetSubjectTopicPage = (props: SubjectTopicPageProps) => {
  const state = useGetSubjectTopicPageQuery(props);

  if (state.isError) {
    return undefined;
  }

  if (state.status === 'pending') {
    return undefined;
  }

  return state.data;
};
