import { Person } from '@dap/sanity/types';
import { Stack, Typography, Divider, SxProps, Avatar, Theme } from '@mui/material';
import { messages } from '@dap/common/i18n';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useSanityContextActions } from '@dap/sanity/utils';
import { CalendarTodayOutlined } from '@mui/icons-material';

interface Props {
  author?: Person;
  publishedAt: string | undefined;
  sx?: SxProps<Theme>;
}

export default function PublisherInfo({ author, publishedAt, sx }: Props) {
  const { t } = useTranslation(['newsArticles']);
  const { getImageUrlBuilder } = useSanityContextActions();

  const imageUrl: string | undefined =
    author && author.image
      ? getImageUrlBuilder(author.image).width(64).height(64).quality(50).url()
      : undefined;

  return (
    <Stack direction="row" sx={sx}>
      {author && (
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar src={imageUrl} alt={author.name} sx={{ width: 32, height: 32 }} />
          <Typography variant="body2">
            {t(messages.newsArticles.meta.by)}{' '}
            <Typography variant="body2" component="span" sx={{ fontWeight: 600 }}>
              {author.name}
            </Typography>
          </Typography>
        </Stack>
      )}
      {publishedAt && (
        <>
          {author && <Divider orientation="vertical" flexItem sx={{ marginInline: 2.5 }} />}
          <Stack direction="row" spacing={0.5} alignItems="center">
            <CalendarTodayOutlined />
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              {format(new Date(publishedAt), 'dd.MM.yyyy')}
            </Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
}
