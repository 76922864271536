import { SanityPageHeader } from '@dap/sanity/ui';
import { Typography, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getDashboardRoute } from '@dap/common/consts';

interface Props {
  title: string;
  message: string;
}

function NotConfiguredPage({ title, message }: Props) {
  const { t } = useTranslation();

  return (
    <Card>
      <SanityPageHeader header={title} />
      <Typography>{message}</Typography>
    </Card>
  );
}

export default NotConfiguredPage;
