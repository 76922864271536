import { AppTFunction } from '@dap/common/i18n';
import { CountryCode } from '@shared/types';
import * as yup from 'yup';

const testValue = (regex: RegExp) => (value: string | undefined) => {
  if (!value) {
    return true;
  }
  return regex.test(value);
};

const nameAndNumbersRegex = /^[a-zA-Z0-9 .'\-æøåäö]+$/i;
const nameAndNumbers = (t: AppTFunction) =>
  yup
    .string()
    .test(
      'isNameAndNumbers',
      t('validation.nameAndNumbers.format'),
      testValue(nameAndNumbersRegex)
    );

const lettersRegex = /^[a-zæøåäö]+$/i;

const letters = yup
  .string()
  .test('isLetters', 'Feltet kan kun inneholde bokstaver', testValue(lettersRegex));

const lettersAndSpaceRegex = /^[a-zæøåäö\s]+$/i;
const lettersAndSpace = (t: AppTFunction) =>
  yup
    .string()
    .test(
      'isLettersAndSpace',
      t('validation.lettersAndSpace.format'),
      testValue(lettersAndSpaceRegex)
    );

const lettersAndSpecialCharactersRegex = /^[a-zA ,.'\-æøåäö]+$/i;
const lettersAndSpecialCharacters = (t: AppTFunction) =>
  yup
    .string()
    .test(
      'isLettersAndSpecialCharacters',
      t('validation.lettersAndSpecialCharacters.format'),
      testValue(lettersAndSpecialCharactersRegex)
    );

const numbersRegex = /^\d+$/;
const numberString = (t: AppTFunction) =>
  yup.string().test('isNumbers', t('validation.number.format'), testValue(numbersRegex));

const decimalNumberRegex = /^\d+(\.\d+)?$/;
const decimalNumberString = (t: AppTFunction) =>
  yup
    .string()
    .test('isDecimalNumber', t('validation.decimalNumber.format'), testValue(decimalNumberRegex));

const phoneRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const phone = (errorMessage: string) =>
  yup.string().matches(phoneRegex, {
    message: errorMessage,
    excludeEmptyString: true,
  });

const country = yup.string().oneOf(['SE', 'NO', 'FI']);

const norwegianOrgNoRegex = /^\d{9}$/;
const swedishOrgNoRegex = /^\d{10}$/;
const finnishOrgNoRegex = /^\d{8}$/;
const orgNo = (t: AppTFunction) =>
  yup.string().test('countryOrgNo', (value, { parent, createError }) => {
    if (value) {
      switch (parent.country) {
        case CountryCode.Sweden:
          return swedishOrgNoRegex.test(value)
            ? true
            : createError({ message: t('validation.orgNo.formatSe') });
        case CountryCode.Finland:
          return finnishOrgNoRegex.test(value)
            ? true
            : createError({ message: t('validation.orgNo.formatFi') });
        default:
          return norwegianOrgNoRegex.test(value)
            ? true
            : createError({ message: t('validation.orgNo.formatNo') });
      }
    }
    return true;
  });

const nowegianPostalCodeRegex = /^\d{4}$/;
const swedishPostalCodeRegex = /^\d{5}$/;
const finnishPostalCodeRegex = /^\d{5}$/;

const postalCode = (siblingField: string, t: AppTFunction) =>
  yup.string().test('postalCodeByCountry', (value, { parent, createError }) => {
    if (value) {
      if (parent[siblingField] === CountryCode.Norway) {
        return (
          nowegianPostalCodeRegex.test(value) ||
          createError({ message: t('validation.postalCode.formatNo') })
        );
      } else if (parent[siblingField] === CountryCode.Sweden) {
        return (
          swedishPostalCodeRegex.test(value) ||
          createError({ message: t('validation.postalCode.formatSe') })
        );
      } else {
        return (
          finnishPostalCodeRegex.test(value) ||
          createError({ message: t('validation.postalCode.formatFi') })
        );
      }
    }
    return true;
  });

const attachments = (maxSizeInMb: number, t: AppTFunction) =>
  yup
    .mixed()
    .default([])
    .test('fileSize', t('common.fileUpload.max', { max: `${maxSizeInMb} MB` }), (files: File[]) => {
      const maxSizeInBytes = maxSizeInMb * 1024 * 1024;
      if (!files) return true;
      const totalFileSize = files.reduce((total, file) => total + file.size, 0);
      return totalFileSize <= maxSizeInBytes;
    });

const date = (t: AppTFunction) =>
  yup
    .date()
    .typeError(t('validation.typeError.date'))
    .default(null)
    .nullable()
    .transform((current, original) => (original === '' ? null : current));

const validations = {
  nameAndNumbers,
  letters,
  lettersAndSpace,
  lettersAndSpecialCharacters,
  numberString,
  decimalNumberString,
  phone,
  orgNo,
  country,
  postalCode,
  attachments,
  date,
  norwegianOrgNoRegex,
  swedishOrgNoRegex,
  finnishOrgNoRegex,
};

export default validations;
