import { CustomError } from '@dap/admin/types';

export function isCustomError(error: unknown): error is CustomError {
  return (
    typeof error === 'object' &&
    error != null &&
    'status' in error &&
    'data' in error &&
    error.data !== null &&
    'message' in (error as any).data
  );
}
