import { DAP_SANITY_CONFIG } from '@dap/common/consts';
import { createClient, ClientConfig } from '@sanity/client';

const { projectId, dataset, apiVersion, useCdn } = DAP_SANITY_CONFIG;

const config: ClientConfig = {
  projectId,
  apiVersion,
  dataset,
  useCdn,
};

export const dapSanityClient = createClient(config);
