import { Box } from '@mui/material';
import { styled } from '@mui/system';

const envLabels: Record<string, { text: string; bgcolor: string; textcolor: string }> = {
  local: {
    text: 'LOCAL',
    bgcolor: 'radial-gradient(89.96% 89.96% at 50% 50%, #FB3438 7.19%, #9D3234 93.81%)',
    textcolor: 'white',
  },
  dev: {
    text: 'DEVELOPMENT',
    bgcolor: 'radial-gradient(69.88% 69.88% at 50% 50%, #56D99A 0%, #228B59 100%)',
    textcolor: '#3E3F5B',
  },
  stage: {
    text: 'STAGE',
    bgcolor: 'radial-gradient(83.27% 83.27% at 50% 50%, #3B86DB 27.76%, #13447C 100%)',
    textcolor: 'white',
  },
};

const StyledEnvironmentLabel = styled(Box)<{ bgcolor: string; textcolor: string }>(
  ({ bgcolor, textcolor }) => ({
    background: bgcolor,
    color: textcolor,
    width: '254px',
    height: '100%',
    textAlign: 'center',
    alignContent: 'center',
    fontWeight: 'bold',
    paddingTop: '0px',
    paddingBottom: '0px',
  })
);

const EnvironmentLabel = () => {
  const env = (process.env['NX_PUBLIC_REACT_APP_ENV'] || 'local') as 'dev' | 'stage' | 'local';
  const envLabel = envLabels[env];

  return envLabel ? (
    <StyledEnvironmentLabel bgcolor={envLabel.bgcolor} textcolor={envLabel.textcolor}>
      {envLabel.text}
    </StyledEnvironmentLabel>
  ) : null;
};

export default EnvironmentLabel;
