import { Box, Drawer, IconButton, Stack, Typography, Divider } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { messages } from '@dap/common/i18n';
import { CheckboxGroup, CheckboxGroupOptionItem } from '@dap/common/ui';
import { RadioToggleGroup } from '../RadioToggleGroup/RadioToggleGroup';

export type GroupOption = 'central' | 'local';

interface FilterItemToggleOption {
  value: string;
  label: string;
}

interface FilterItemGroup {
  name: string;
  title?: string;
  items: CheckboxGroupOptionItem[];
  selectedItems?: string[];
}

interface FilterItemToggle {
  name: string;
  label: string;
  labelAll: string;
  options: FilterItemToggleOption[];
  selectedOption?: string;
}

interface FilterDrawerProps {
  title?: string;
  open: boolean;
  onClose: () => void;
  filters?: FilterItemGroup[];
  onChangeFilter?: (name: string, value: string[]) => void;
  toggles?: FilterItemToggle[];
  onChangeToggle?: (name: string, value: string) => void;
}

export const FilterDrawer = ({
  title,
  open,
  onClose,
  onChangeFilter,
  onChangeToggle,
  toggles,
  filters,
}: FilterDrawerProps) => {
  const { t } = useTranslation();

  if (!filters && !toggles) {
    return null;
  }

  return (
    <Drawer open={open} onClose={onClose} anchor="right">
      <Box sx={{ maxWidth: '450px', width: '90vw', padding: 3 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Stack divider={<Divider />} spacing={2.5}>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
              {title && <Typography variant="h5">{title}</Typography>}
              <IconButton
                onClick={onClose}
                aria-label="Close"
                edge="end"
                sx={{
                  color: 'common.black',
                  gap: 0.5,
                }}
              >
                <CloseIcon fontSize="small" />
                <Typography variant="body2" fontWeight={500} component="span">
                  {t(`common:${messages.common.close}`)}
                </Typography>
              </IconButton>
            </Stack>

            {onChangeToggle &&
              toggles?.map((t, index) => (
                <RadioToggleGroup
                  key={t.name}
                  name={t.name}
                  label={t.label}
                  labelAll={t.labelAll}
                  options={t.options}
                  selectedOption={t.selectedOption ?? ''}
                  onChangeOption={onChangeToggle}
                  size="small"
                />
              ))}

            {onChangeFilter &&
              filters?.map((group, index) => (
                <Box key={group.name} sx={{ paddingBottom: 3 }}>
                  {group.title && <Typography variant="h6">{group.title}</Typography>}
                  <CheckboxGroup
                    onChange={(value) => onChangeFilter(group.name, value)}
                    value={group?.selectedItems ?? []}
                    options={group.items}
                    fullWidth
                    direction="column"
                  />
                </Box>
              ))}
          </Stack>
        </form>
      </Box>
    </Drawer>
  );
};
