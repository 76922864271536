import { useAuth0 } from '@auth0/auth0-react';
import { useLanguageSelection } from '@dap/common/data-access';
import { Sidebar, Header } from '@dap/common/modules';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { mgprisHeaderHeight } from '@shared/styles';
import { Box } from '@mui/material';
import { LanguageCode } from '@shared/types';

interface Props {
  children: React.ReactNode;
}

export default function AppLayout({ children }: Props) {
  const { switchLanguage, language } = useLanguageSelection();
  const { logout } = useAuth0();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box display="flex" flexGrow="1" id="box">
      <Header switchLanguage={switchLanguage} language={language as LanguageCode} />
      <Sidebar logoutCallback={logout} />

      <Box
        marginTop={mgprisHeaderHeight}
        sx={{
          flexGrow: 1,
          p: 0,
          minHeight: `calc(100dvh - ${mgprisHeaderHeight})`,
          background: ({ palette }) => palette.grey[100],
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
