import { Typography } from '@mui/material';
import { PortableTextReactComponents } from '@portabletext/react';
import InlineExternalLink from './../../pageContent/InlineExternalLink';
import InlineInternalLink from './../../pageContent/InlineInternalLink';
import { CustomLinkField, CustomLinkType } from '@dap/sanity/types';
import { getUrlAndTitleFromCustomLink } from '@dap/sanity/utils';

export const richTextPortableComponents: Partial<PortableTextReactComponents> = {
  marks: {
    customLink: ({ children, value }) => {
      const link = value?.link as CustomLinkField;
      if (!link) {
        return children;
      }

      const [href] = getUrlAndTitleFromCustomLink(link);

      if (!href) {
        return children;
      }

      if (link._type == CustomLinkType.ExternalLink) {
        return (
          <InlineExternalLink href={href} blank={link.blank}>
            {children}
          </InlineExternalLink>
        );
      }

      return <InlineInternalLink to={href}>{children}</InlineInternalLink>;
    },
  },
  types: {
    span: ({ value }) => <span>{value.text}</span>,
  },
  block: {
    h1: ({ children }) => (
      <Typography variant="h4" component="h1" marginTop={8}>
        {children}
      </Typography>
    ),
    h2: ({ children }) => (
      <Typography variant="h4" component="h2" marginTop={7}>
        {children}
      </Typography>
    ),
    h3: ({ children }) => (
      <Typography variant="h6" component="h3" marginTop={6}>
        {children}
      </Typography>
    ),
    h4: ({ children }) => (
      <Typography variant="h6" component="h4" marginTop={5}>
        {children}
      </Typography>
    ),
    h5: ({ children }) => (
      <Typography variant="h6" component="h5" marginTop={4}>
        {children}
      </Typography>
    ),
    h6: ({ children }) => (
      <Typography variant="h6" component="h6">
        {children}
      </Typography>
    ),
    blockquote: ({ children }) => (
      <Typography
        variant="h4"
        component="blockquote"
        sx={(theme) => ({
          marginBlock: 10,
          marginInline: 6,
          fontStyle: 'italic',
          display: 'flex',
          fontWeight: '400',
          lineHeight: 30.72 / 24,
          [theme.breakpoints.up('md')]: {
            marginInline: 15,
          },
          '&::before': {
            content: '""',
            width: theme.spacing(1.5),
            display: 'inline-block',
            marginRight: 3,
            borderRadius: 0.5,
            background: theme.palette.gradient.secondary,
          },
        })}
      >
        {children}
      </Typography>
    ),
  },
};
