import { Card } from '@mui/material';
import { CardsCarousel, LinkCard } from '../../components';
import { LinkListItem, BlockLinkListPalette } from '@dap/sanity/types';

interface Props {
  title: string;
  palette: BlockLinkListPalette;
  links: LinkListItem[];
}

export function LinkList({ title, palette = 'darkGrey', links }: Props) {
  const cards = links
    ?.map((item) => <LinkCard key={item._key} item={item} palette={palette} />)
    .filter(Boolean);

  return (
    <Card>
      <CardsCarousel title={title} cards={cards} />
    </Card>
  );
}
