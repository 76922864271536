export enum ViewMode {
  List = 'List',
  Grid = 'Grid',
}

export enum SortOptions {
  ascName = 'nameAsc',
  descName = 'nameDesc',
}

export enum EmployeeSortOptions {
  ascFamilyName = 'familyNameAsc',
  descFamilyName = 'familyNameDesc',
  ascGivenName = 'givenNameAsc',
  descGivenName = 'givenNameDesc',
}

export enum RoleType {
  Bedriftsutvikler = 'bedriftsutvikler',
  Regionschef = 'regionschef',
  Leder = 'leder',
  Vd = 'vd',
  Kjedeadmin = 'kjedeadmin',
  SuperUser = 'superuser',
  BrandAdmin = 'brandadmin',
  DataownerAdmin = 'dataowneradmin',
  LocationAdmin = 'locationadmin',
  MasterdataAdmin = 'masterdata',
  BrandObserver = 'ba-brand-observer',
}

export enum AppType {
  BrandAdmin = 'brandadmin',
}

export enum SanityDapUserRole {
  Administrator = 'dap-sanity-administrator',
  BrandAdministrator = 'dap-sanity-brandadministrator',
  BrandEditor = 'dap-sanity-editor',
  ShopEditor = 'dap-sanity-shop-editor',
  SubjectEditor = 'dap-sanity-subject-editor',
}

export enum SanityHuskjedeUserRole {
  Administrator = 'sanity-admin-hus',
  Editor = 'sanity-redaktor-hus',
}
export type SanityUserRole = SanityDapUserRole | SanityHuskjedeUserRole;
