import { CustomLinkField, CustomLinkType, ModuleType } from '@dap/sanity/types';
import {
  getModuleRoute,
  getMosaicModuleRoute,
  getOrderRoute,
  getSubjectTopicPageRoute,
  getTopicPageRoute,
  routes,
} from '@dap/common/consts';
import { getUrlToInternalContent } from './getUrlToInternalContent';

// Returns the correct route for a static module
export const staticModuleToRoute = (module: ModuleType): string => {
  switch (module) {
    case ModuleType.eventsModule:
      return `/${routes.events}`;
    case ModuleType.newsModule:
      return `/${routes.news}`;
    case ModuleType.servicesModule:
      return `/${routes.services}`;
    case ModuleType.helpCentreModule:
      return `/${routes.helpCentre}`;
    default:
      return '';
  }
};

// Returns the correct href for a custom link field
// Returns the title, if it's possible to extract from the type of link
export const getUrlAndTitleFromCustomLink = (
  link: CustomLinkField
): [string | undefined, string | undefined] => {
  let title: string | undefined;
  let href: string | undefined;

  if (!link) {
    // console.warn('Could not resolve link for custom link', link);
    return [undefined, undefined];
  }

  switch (link._type) {
    case CustomLinkType.ReferencedModule: {
      // Get the referenced module
      const module = link.referencedModule;

      title = module.header;

      switch (module._type) {
        case ModuleType.proffModule:
        case ModuleType.fremdriftModule:
          href = getModuleRoute({ moduleSlug: module.slug });
          break;

        case ModuleType.mosaicModule:
          href = getMosaicModuleRoute({ moduleSlug: module.slug });
          break;

        case ModuleType.orderModule:
          href = getOrderRoute({ moduleSlug: module.slug });
          break;
      }
      break;
    }

    case CustomLinkType.StaticModule: {
      title = link.staticModuleData?.header;
      href = staticModuleToRoute(link.module_type as ModuleType);
      break;
    }

    case CustomLinkType.OrderModule: {
      const module = link.referencedModule;
      if (!module) {
        // console.warn('Could not resolve order module for navigation item');
        break;
      }

      title = module.header;
      href = getOrderRoute({ moduleSlug: module.slug });
      break;
    }

    case CustomLinkType.TopicPage: {
      title = link.referencedTopic.title;
      if (link.referencedTopic.subject) {
        href = getSubjectTopicPageRoute({
          subject: link.referencedTopic.subject.slug,
          slug: link.referencedTopic.slug,
        });
      } else {
        href = getTopicPageRoute(link.referencedTopic.slug);
      }
      break;
    }

    case CustomLinkType.RelativeLink: {
      href = link.link;
      break;
    }

    case CustomLinkType.ExternalLink: {
      href = link.href;
      break;
    }

    case CustomLinkType.Content: {
      if (!link.referencedArticle) {
        // console.warn('Could not resolve article for navigation item');
        href = undefined;
        title = undefined;
        break;
      }

      const articleHref = getUrlToInternalContent(link.referencedArticle);
      href = articleHref || undefined;
      title = link.referencedArticle.title;
      break;
    }
  }

  return [href, title];
};
