import { BRREG_ENHETER } from '@dap/admin/config';
import { BrregSearchArgs, BrregSearchResult } from '@dap/admin/types';
import { pruneObject } from '@shared/utils';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { mapBrregResult } from '@dap/common/utils';

export const brregApi = createApi({
  reducerPath: 'brregApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (build) => ({
    searchBrreg: build.mutation<Array<BrregSearchResult>, BrregSearchArgs>({
      query: (args) => ({
        method: 'GET',
        url: BRREG_ENHETER,
        params: pruneObject(args),
      }),
      transformResponse: mapBrregResult,
    }),
  }),
});

export const { useSearchBrregMutation } = brregApi;
