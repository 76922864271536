import { useAppTFunction } from '@dap/common/i18n';
import { Check } from '@mui/icons-material';
import { Alert, Stack, Typography } from '@mui/material';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { TypedUseMutationResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import React, { PropsWithChildren } from 'react';
import LoadingDefaultComponent from '../Fallbacks/LoadingDefaultComponent';
import { CenterElement } from '../Layout';
import { LoadingErrorComponent } from './LoadingErrorComponent';
import { getErrorMessage } from '@dap/admin/utils';

interface Props<DataT, ErrorT> {
  loadingState: TypedUseMutationResult<DataT, any, BaseQueryFn<any, unknown, ErrorT>>;
  LoadingComponent?: React.ComponentType;
  ErrorComponent?: LoadingErrorComponent<ErrorT>;
  SuccessComponent?: React.ComponentType;
}

function RtkStateWrapper<DataT, ErrorT>({
  loadingState: { error, isError, isLoading, isSuccess },
  LoadingComponent = LoadingDefaultComponent,
  ErrorComponent = GenericErrorComponent,
  SuccessComponent = GenericSuccessComponent,
  children,
}: PropsWithChildren<Props<DataT, ErrorT>>) {
  if (isLoading) {
    return <LoadingComponent />;
  }
  if (isSuccess) {
    return <SuccessComponent />;
  }

  if (isError) {
    return (
      <Stack gap={2}>
        <ErrorComponent error={error as ErrorT} />
        {children}
      </Stack>
    );
  }

  return children;
}

export default RtkStateWrapper;

const GenericSuccessComponent = () => {
  const t = useAppTFunction();
  return (
    <CenterElement>
      <Stack width="100%" spacing={4} alignItems="center" justifyContent="center" mt={3}>
        <Check sx={{ fontSize: '128px', color: ({ palette }) => palette.success[500] }} />
        <Typography variant="h3" fontSize={48} letterSpacing={'-0.96px'} pb={1}>
          {t('common.completed')}
        </Typography>
      </Stack>
    </CenterElement>
  );
};

const GenericErrorComponent: LoadingErrorComponent<any> = ({ error }) => {
  const t = useAppTFunction();
  return (
    <Alert severity="error">
      <Typography variant="body2" mr={0.5}>
        {t('brandadmin.error.generic')}
      </Typography>
      <Typography variant="body3">
        {t('common.error.title', { status: error?.status })}: {getErrorMessage(error)}
      </Typography>
    </Alert>
  );
};
