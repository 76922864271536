import { Category } from '@dap/sanity/types';
import { Tabs } from '@shared/components';

interface Props {
  tabs: Array<Category>;
  arialLabel: string;
  fremdriftUrl: string;
  currentTab: string;
}

export default function FremdriftTabsComponent({
  tabs,
  arialLabel,
  fremdriftUrl,
  currentTab,
}: Props) {
  const tabValue = (category: string, index: number) => {
    return index === 0 ? fremdriftUrl : `${fremdriftUrl}/${category}`;
  };

  const tabItems = tabs.map((t) => ({
    label: t.title,
    value: tabValue(t.slug.toLowerCase(), tabs.indexOf(t)),
    to: tabValue(t.slug.toLowerCase(), tabs.indexOf(t)),
  }));

  return <Tabs currentTab={currentTab} aria-label={arialLabel} prefixId="" tabs={tabItems} />;
}
