import { AppFeatureConfig } from '@dap/dashboard/types';
import { defaultBrandFeatures } from './defaultBrandFeatures';

const xlbyggSverigeFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
  intranetBanner: { resolveToggle: () => 'on' },
};

export const xlbyggSverigeFeaturesDev: AppFeatureConfig = {
  ...xlbyggSverigeFeaturesDefault,
};
export const xlbyggSverigeFeaturesStage: AppFeatureConfig = {
  ...xlbyggSverigeFeaturesDefault,
};
export const xlbyggSverigeFeaturesProd: AppFeatureConfig = {
  ...xlbyggSverigeFeaturesDefault,
  completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
};
