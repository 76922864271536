import type PhotoSwipeLightbox from 'photoswipe/lightbox';

export default class PhotoSwipeOverviewPlugin {
  lightbox: PhotoSwipeLightbox;
  overviewEl?: HTMLElement = undefined;

  constructor(lightbox: PhotoSwipeLightbox) {
    this.lightbox = lightbox;
    // eslint-disable-next-line
    const self = this;

    lightbox.on('uiRegister', () => {
      lightbox.pswp?.ui?.registerElement({
        name: 'overview',
        order: 9,
        isButton: true,
        title: 'Bildeoversikt',
        html: {
          isCustomSVG: true,
          inner:
            '<path d="M7.75 7.75V15.0833H15.0833V7.75H7.75ZM13.25 13.25H9.58333V9.58333H13.25V13.25ZM7.75 16.9167V24.25H15.0833V16.9167H7.75ZM13.25 22.4167H9.58333V18.75H13.25V22.4167ZM16.9167 7.75V15.0833H24.25V7.75H16.9167ZM22.4167 13.25H18.75V9.58333H22.4167V13.25ZM16.9167 16.9167V24.25H24.25V16.9167H16.9167ZM22.4167 22.4167H18.75V18.75H22.4167V22.4167Z"/>',
        },
        onClick() {
          self.showOverview();
        },
      });
    });
  }

  showOverview() {
    const pswp = this.lightbox.pswp;

    if (!pswp) {
      return;
    }

    let dataSource = pswp.options.dataSource;
    const imagesByType: Record<string, HTMLImageElement[]> = {};
    const defaultType = 'Bilder'; // Default group name for images without type

    if (Array.isArray(dataSource)) {
      dataSource = dataSource.map((item) => ({
        ...item,
        type: (item.element?.querySelector('[data-type]') as any)?.dataset?.type || defaultType,
      }));

      dataSource?.forEach((item) => {
        // Split srcset parameter into a map of width -> src
        const srcset = item.srcset
          ? (item.srcset.split('w,').reduce((carry, s) => {
              const [src, width] = s.trim().split(' ');
              return {
                ...carry,
                [parseInt(width, 10)]: src,
              };
            }, {}) as Record<number, string>)
          : undefined;

        // Get the first source from srcset (the smallest image) or use the src attribute, to use as the overview thumnail image
        const firstSrcsetSource = srcset ? Object.values(srcset)[0] : undefined;
        const source = firstSrcsetSource ?? item.src;

        if (source) {
          const image = document.createElement('img');
          image.src = source;

          const type = item.type ?? defaultType;
          if (!imagesByType[type]) {
            imagesByType[type] = [];
          }
          imagesByType[type].push(image);
        }
      });
    }

    this.overviewEl = document.createElement('div');
    this.overviewEl.className = 'pswp-overview';
    this.overviewEl.style.opacity = '1';

    // Add close button
    const toolbar = document.createElement('div');
    toolbar.className = 'pswp__top-bar';

    const closeBtn = document.createElement('button');
    closeBtn.className = 'pswp__button pswp__button--close';
    closeBtn.title = 'Lukk bildeoversikt';
    closeBtn.innerHTML =
      '<svg aria-hidden="true" class="pswp__icn" viewBox="0 0 32 32" width="32" height="32"><use class="pswp__icn-shadow" xlink:href="#pswp__icn-close"></use><path d="M24 10l-2-2-6 6-6-6-2 2 6 6-6 6 2 2 6-6 6 6 2-2-6-6z" id="pswp__icn-close"></path></svg>';
    closeBtn.onclick = () => {
      this.closeOverview();
    };

    toolbar.appendChild(closeBtn);
    this.overviewEl.appendChild(toolbar);

    // Create image area
    const imageWrapper = document.createElement('div');
    imageWrapper.className = 'pswp__image-wrapper';

    const imageInnerWrapper = document.createElement('div');
    imageInnerWrapper.className = 'pswp__image-inner-wrapper';

    // Create sections for each type
    Object.entries(imagesByType).forEach(([type, images]) => {
      const section = document.createElement('div');
      section.className = 'pswp__section';

      // Only add heading if there's more than one type
      if (Object.keys(imagesByType).length > 1) {
        const heading = document.createElement('h2');
        heading.className = 'pswp__heading';
        heading.textContent = type;
        section.appendChild(heading);
      }

      const imageGrid = document.createElement('div');
      imageGrid.className = 'pswp__image-grid';

      images.forEach((image) => {
        const imageButton = document.createElement('button');
        imageButton.className = 'pswp__image-button';

        const img = document.createElement('img');
        img.src = image.src;
        img.className = 'pswp__image';

        imageButton.onclick = () => {
          // Find the overall index in the flattened array
          const allImages = Object.values(imagesByType).flat();
          pswp.goTo(allImages.indexOf(image));
          this.closeOverview();
        };
        imageButton.appendChild(img);
        imageGrid.appendChild(imageButton);
      });

      section.appendChild(imageGrid);
      imageInnerWrapper.appendChild(section);
    });

    imageWrapper.appendChild(imageInnerWrapper);
    this.overviewEl.appendChild(imageWrapper);

    if (pswp.element) {
      pswp.element.appendChild(this.overviewEl);
    }

    // Allow scrolling
    this.overviewEl.addEventListener(
      'wheel',
      (e) => {
        e.stopPropagation();
      },
      { capture: true, passive: true }
    );
  }

  closeOverview() {
    this.overviewEl?.remove();
  }
}
