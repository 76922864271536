import { useMemo } from 'react';
import { UserData } from '@dap/admin/types';
import { hasBrandadminAppAccess, isAdmin, shouldBeRedirectedToKjedeadmin } from '@dap/admin/utils';
import { routes } from '@dap/common/consts';
import { useSelectedBrandKey, useUser } from '@dap/common/data-access';
import { useAppTFunction } from '@dap/common/i18n';
import { useGetBrandNavigation } from '@dap/sanity/data-access';
import {
  ObjectType,
  NavigationItem,
  NavigationItemIconType,
  CustomLinkType,
} from '@dap/sanity/types';
import { NavRoute, NavWithSubRoutes, SubRoute } from '@shared/components';
import {
  AdministrationIcon,
  BriefcaseIcon,
  CalendarIcon,
  FremdriftIcon,
  HomeIcon,
  NewsIcon,
  SanityIcon,
} from '@shared/custom-icons';
import { useLocation, matchPath } from 'react-router-dom';
import { getUrlAndTitleFromCustomLink } from '@dap/sanity/utils';
import { hasSanityDapAccess, hasSanityHuskjedeAccess } from '@dap/sanity/utils';

export type Route = NavRoute | NavWithSubRoutes;

const iconTypeToIcon = (icon: NavigationItemIconType): React.FC => {
  switch (icon) {
    case NavigationItemIconType.Briefcase:
      return BriefcaseIcon;
    case NavigationItemIconType.Calendar:
      return CalendarIcon;
    case NavigationItemIconType.News:
      return NewsIcon;
    case NavigationItemIconType.Fremdrift:
      return FremdriftIcon;
    default:
      return FremdriftIcon;
  }
};

// Parses a NavigationItem object and returns a NavRoute or SubRoute object
const parseNavItem = (
  item: NavigationItem,
  currentPathName: string,
  isSubMenuItem = false
): NavRoute | SubRoute | undefined => {
  let isVisible = true;

  const itemLink = item.link;

  if (!itemLink) {
    return;
  }

  const [href, resolvedTitle] = getUrlAndTitleFromCustomLink(itemLink);

  switch (itemLink._type) {
    case CustomLinkType.StaticModule: {
      // Find the static module in the brand configuration array of modules
      //
      // We need to fetch by looking up in the array, since the module is not a reference, just an item in an array
      const module = itemLink.staticModuleData;
      if (!module) {
        // console.warn('Could not resolve static module for navigation item');
        break;
      }

      isVisible = !!module.show;
      break;
    }

    case CustomLinkType.OrderModule: {
      // Get the referenced order module
      const module = itemLink.referencedModule;
      if (!module) {
        // console.warn('Could not resolve order module for navigation item');
        break;
      }
      isVisible = !!module.show;
      break;
    }
  }

  // Fetch title from the items label, if not set use the resolvedTitle from the link, before restoring to a undefined (which will prevent the link from rendering)
  const title = item.label ?? resolvedTitle ?? undefined;

  // If module is not visible (show property is toggled off) or doesn't have a href or title, do not return a route object
  if (!isVisible || !href || !title) {
    // console.warn('Hiding navigation item due to missing or invalid properties', item);
    return;
  }

  const isActive = !!matchPath(`${href}/*`, currentPathName);

  let route: NavRoute | SubRoute | undefined;
  if (!isSubMenuItem) {
    route = {
      title,
      Icon: iconTypeToIcon(item.icon),
      to: href,
      isActive,
    } as NavRoute;
  } else {
    route = {
      title,
      to: href,
      isActive,
    } as SubRoute;
  }

  return route;
};

export const useNavRoutes = (): Route[] => {
  const userData = useUser();
  const brandKey = useSelectedBrandKey();

  const t = useAppTFunction();
  const { pathname } = useLocation();

  const brandNavigation = useGetBrandNavigation({ brandKey });

  return useMemo(() => {
    if (!userData) {
      return [];
    }
    const isBrandadminPage = matchPath(routes.brandadmin, pathname);
    const isBrandadminDataownerPage = matchPath(
      `${routes.brandadmin}/${routes.dataowner}/*`,
      pathname
    );
    const isBrandadminLocationPage = matchPath(
      `${routes.brandadmin}/${routes.location}/*`,
      pathname
    );
    const isBrandadminEmployeePage = matchPath(
      `${routes.brandadmin}/${routes.employee}/*`,
      pathname
    );
    const isBrandadminMePage = matchPath(`${routes.brandadmin}/${routes.me}/*`, pathname);
    const isSanityPage = matchPath(`${routes.sanity}/*`, pathname);
    const isHomeRoute = matchPath(routes.home, pathname);

    const userHasBrandadminAccess = hasBrandadminAppAccess(userData);

    const userShouldBeRedirectedToKjedeadmin = shouldBeRedirectedToKjedeadmin(userData, brandKey);

    const homeNavConfig: NavRoute = {
      title: t('common.navigation.dashboard'),
      Icon: HomeIcon,
      isActive: !!isHomeRoute,
      to: routes.home,
    };

    // Get subroutes for the administration route
    const getAdministrationSubRoutes = (userData: UserData, brandKey: string): SubRoute[] => {
      const administration = {
        title: t('common.navigation.brandadmin'),
        isActive:
          !!isBrandadminPage ||
          !!isBrandadminDataownerPage ||
          !!isBrandadminLocationPage ||
          !!isBrandadminEmployeePage,
        to: routes.brandadmin,
      };

      const me = {
        title: t('common.navigation.me'),
        isActive: !!isBrandadminMePage,
        to: `${routes.brandadmin}/${routes.me}`,
      };

      return [administration, me];
    };

    // Define the brandadmin navigation config with subroutes
    const brandadminNavConfig: NavRoute | NavWithSubRoutes | undefined =
      !userShouldBeRedirectedToKjedeadmin && userHasBrandadminAccess
        ? {
            title: t('common.navigation.brandadmin'),
            Icon: AdministrationIcon,
            isActive: !isAdmin(userData, brandKey) && !!isBrandadminPage,
            to: routes.brandadmin,
            subRoutes: getAdministrationSubRoutes(userData, brandKey),
          }
        : undefined;

    const customNav: Route[] = brandNavigation
      ? brandNavigation.items.reduce((menu, item) => {
          switch (item._type) {
            case ObjectType.NavigationSubMenu:
              {
                const subRoutes = item.items
                  .map((i) => parseNavItem(i, pathname, true))
                  .filter(Boolean) as SubRoute[];

                // If we don't have any valid subroutes, skip this menu item
                if (subRoutes.length === 0) {
                  break;
                }

                const route: NavWithSubRoutes = {
                  title: item.label,
                  Icon: AdministrationIcon,
                  isActive: false, // Use false, to let it be active if any subroutes are active
                  to: subRoutes[0].to, // Use the first subroute as the main route
                  subRoutes,
                  initialOpen: false,
                };

                menu.push(route);
              }
              break;

            case ObjectType.NavigationItem: {
              const route = parseNavItem(item, pathname) as NavRoute | NavWithSubRoutes;
              if (!route) {
                break;
              }
              menu.push(route);
              break;
            }
          }
          return menu;
        }, [] as Route[])
      : [];

    const sanityMainNavConfig: NavRoute | undefined =
      hasSanityDapAccess(userData) || hasSanityHuskjedeAccess(userData)
        ? {
            title: 'Sanity',
            Icon: SanityIcon,
            isActive: !!isSanityPage,
            to: `${routes.sanity}`,
          }
        : undefined;

    return [homeNavConfig, brandadminNavConfig, ...customNav, sanityMainNavConfig].filter(
      (navConfig) => !!navConfig
    ) as Route[];
  }, [brandKey, brandNavigation, pathname, t, userData]);
};
