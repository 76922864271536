import { DocumentType } from '@dap/sanity/types';
import { contentBlocksQuery } from './contentBlockQueries';
import groq from 'groq';

export interface SubjectQueryProps {
  subject: string;
}

export const subjectQuery = ({ subject }: SubjectQueryProps) => {
  const query = groq`
    *[
      _type == "${DocumentType.Subject}"
      && slug.current == "${subject}"
    ][0] {
      title,
      "slug": slug.current,
      palette
    }
  `;

  return query;
};

export interface SubjectFrontpageQueryProps {
  subject: string;
  brandKey: string;
}

export const subjectFrontpageQuery = ({ subject, brandKey }: SubjectFrontpageQueryProps) => {
  const query = groq`
    *[
      _type == "${DocumentType.SubjectFrontpage}"
      && (!defined(brands) || "${brandKey}" in brands)
      && subject->slug.current == "${subject}"
    ][0] {
      mainContent[]{
        ${contentBlocksQuery}
      },
      secondaryContent[]{
        ${contentBlocksQuery}
      }
    }
  `;

  return query;
};

export interface SubjectTopicPageQueryProps {
  subject: string;
  topic: string;
  brandKey: string;
}

export const subjectTopicPageQuery = ({ subject, topic, brandKey }: SubjectTopicPageQueryProps) => {
  const query = groq`
    *[
      _type == "${DocumentType.SubjectTopicPage}"
      && (!defined(brands) || "${brandKey}" in brands)
      && subject->slug.current == "${subject}"
      && slug.current == "${topic}"
    ][0] {
      title,
      "slug": slug.current,
      text,
      image,
      content[]{
        ${contentBlocksQuery}
      },
    }
  `;

  return query;
};

// Fetches all subjects that are available for a specific brand
export const brandSubjectsQuery = groq`
  title,
  "slug": slug.current,
  isAvailable,
  "topics": *[
    _type == '${DocumentType.SubjectTopicPage}'
    && references(^._id)
    && !(_id in path("drafts.**"))
    && (!defined(brands) || ^.^.brand in brands)
  ] {
    title,
    "slug": slug.current,
  },
  "hasFrontpage": count(*[
    _type == 'subjectFrontpage'
    && references(^._id)
    && !(_id in path("drafts.**"))
    && (!defined(brands) || ^.^.brand in brands)
  ]) > 0,
  "showArticles": count(*[
    _type == 'subjectArticle'
    && references(^._id)
    && !(_id in path("drafts.**"))
    && (!defined(brands) || ^.^.brand in brands)
  ]) > 0,
  "showEvents": count(*[
    _type == 'subjectEvent'
    && references(^._id)
    && !(_id in path("drafts.**"))
    && (!defined(brands) || ^.^.brand in brands)
  ]) > 0
`;
