import { Alert, Button, Card, Typography } from '@mui/material';
import { messages } from '@dap/common/i18n';
import { useTranslation } from 'react-i18next';

interface Props {
  message: string;
  logout: () => void;
}

export default function LandingPageErrorCard({ message, logout }: Props) {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        py: '45px',
        px: '60px',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        alignItems: 'center',
      }}
    >
      {' '}
      <Alert severity="error">
        <Typography textAlign="center">{message}</Typography>
      </Alert>
      <Button onClick={logout} variant="contained" color="primary" sx={{ alignSelf: 'stretch' }}>
        {t(messages.common.logout)}
      </Button>
    </Card>
  );
}
