import { Cancel } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  OutlinedTextFieldProps,
} from '@mui/material';
import { WarningIcon } from '@shared/custom-icons';
import { KeyboardEvent, forwardRef } from 'react';
import { InfoTooltip } from '../Tooltip';
import { FormControlWrapper, FormLabelWrapper, HelperText } from './Components';

interface Props
  extends Pick<
    OutlinedTextFieldProps,
    | 'onChange'
    | 'onClick'
    | 'onBlur'
    | 'name'
    | 'value'
    | 'rows'
    | 'multiline'
    | 'size'
    | 'disabled'
    | 'onKeyDown'
    | 'defaultValue'
    | 'autoFocus'
  > {
  label: string;
  error?: string;
  placeholder?: string;
  required?: boolean;
  margin?: 'normal' | 'dense' | 'none';
  fullWidth?: boolean;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  tooltipText?: string;
  reset?: () => void;
  labelDirection?: 'row' | 'column';
  success?: string;
  labelWidth?: number;
}

const InputText = forwardRef(
  (
    {
      name,
      label,
      error,
      required,
      margin = 'normal',
      fullWidth,
      value,
      endAdornment,
      startAdornment,
      tooltipText,
      size = 'medium',
      reset,
      disabled,
      labelDirection = 'column',
      success,
      onKeyDown,
      labelWidth = 100,
      ...rest
    }: Props,
    ref
  ) => {
    const inputId = `${name}-id`;
    const formLabelId = `${name}-label`;
    const helperTextId = `${name}-helperText`;

    return (
      <FormControl
        sx={{ minWidth: 100 }}
        margin={margin}
        error={!!error}
        fullWidth={fullWidth}
        required={required}
        disabled={disabled}
        component={FormControlWrapper}
        labelDirection={labelDirection}
      >
        {label && (
          <FormLabelWrapper direction={labelDirection} width={labelWidth} size={size}>
            <FormLabel htmlFor={inputId} id={formLabelId}>
              {label}
            </FormLabel>
            {tooltipText && <InfoTooltip tooltipText={tooltipText} disabled={disabled} />}
          </FormLabelWrapper>
        )}

        <Box flex="1 0 0">
          <OutlinedInput
            {...rest}
            size={size}
            id={inputId}
            fullWidth={fullWidth}
            inputRef={ref}
            value={value}
            aria-describedby={helperTextId}
            autoComplete="off"
            startAdornment={
              startAdornment ? (
                <InputAdornment position="start">{startAdornment}</InputAdornment>
              ) : null
            }
            endAdornment={
              error ? (
                <InputAdornment position="end">
                  <WarningIcon color="warning" />
                </InputAdornment>
              ) : value && reset ? (
                <InputAdornment position="end">
                  <IconButton onClick={reset} sx={{ padding: 0 }}>
                    <Cancel color="disabled" />
                  </IconButton>
                </InputAdornment>
              ) : endAdornment ? (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ) : null
            }
            inputProps={{
              onKeyDown: onKeyDown
                ? (event: KeyboardEvent<HTMLInputElement>) => onKeyDown(event)
                : undefined,
            }}
          />

          {error && (
            <HelperText id={helperTextId} status={'error'}>
              {error}
            </HelperText>
          )}

          {success && (
            <HelperText id={helperTextId} status={'success'}>
              {success}
            </HelperText>
          )}
        </Box>
      </FormControl>
    );
  }
);

export default InputText;
