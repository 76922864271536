import { SERVICES_V2, SERVICES_V2_KEY } from '@dap/admin/config';
import { AppBasic, AppDetailed } from '@dap/admin/types';
import { appBasicMapper, appDetailedMapper } from '@dap/common/utils';
import { brandadminApi } from './brandadminApi';
import { ServiceTags } from './tags';
import { AppBasicDTO } from '@dap/generated-types';

const tags = [...Object.values(ServiceTags)];

export const serviceApi = brandadminApi.enhanceEndpoints({ addTagTypes: tags }).injectEndpoints({
  endpoints: (build) => ({
    getService: build.query<AppDetailed, AppDetailed['key']>({
      query: (key) => ({
        url: SERVICES_V2_KEY(key),
      }),
      transformResponse: appDetailedMapper,
    }),
    getServices: build.query<Array<AppBasic>, void>({
      query: () => SERVICES_V2,
      transformResponse: (services: AppBasicDTO[]) =>
        appBasicMapper(services).sort((a, b) => (a.name < b.name ? -1 : 1)),
      providesTags: [ServiceTags.SERVICES],
    }),
  }),
});

export const { useGetServiceQuery, useGetServicesQuery } = serviceApi;
