import { InformationModelType, OrgUnitRole, RoleBrandView, RoleDetailed } from '@dap/admin/types';

export function getSelectedRoles(roles: Array<RoleDetailed>) {
  return roles.filter((role) => role.selected);
}

export function getBrandRoles(
  roleDetailed: Array<RoleDetailed>,
  brandKey: string
): Array<OrgUnitRole> {
  return roleDetailed.map((role) => ({
    role: role,
    orgUnitKey: brandKey,
    type: InformationModelType.Brand,
  }));
}

export function mapRoleDetailedToRoleBrandView(roleDetailed: RoleDetailed): RoleBrandView {
  const role: RoleBrandView = {
    key: roleDetailed.key,
    title: roleDetailed.title,
    isAdmin: roleDetailed.isAdmin,
    motimateRole: false,
    selfAssign: false,
  };
  return role;
}

export function mapRoleDetailedArrayToRoleBrandViewArray(
  roleDetailed: RoleDetailed[]
): RoleBrandView[] {
  return roleDetailed.map((role) => mapRoleDetailedToRoleBrandView(role));
}
