import { alpha, Card, Link, Stack, SxProps, Theme, Typography, useTheme, Box } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import PreviewSanityImage from '../previewContent/PreviewSanityImage';
import { previewCardImageWidth, previewCardImageHeight } from './eventConstants';
import { messages } from '@dap/common/i18n';
import { SanityEventPreview } from '@dap/sanity/types';
import { maxLinesSx } from '@shared/utils';

interface Props {
  event: SanityEventPreview;
  onClick?: () => void;
}

export default function CalendarEventPreviewCardContent({ event, onClick }: Props) {
  const { palette } = useTheme();
  const { t } = useTranslation(['events']);
  const date = new Date(event.eventInformation.dateTimeRange.startDate);

  return (
    <Card onClick={onClick} sx={cardStyle} elevation={0}>
      <Stack justifyContent="center" alignItems="left" spacing={0} width="40px">
        <Typography
          variant="h4"
          sx={{
            color: palette.grey[900],
          }}
        >
          {format(date, 'd')}.
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: palette.grey[900],
            fontWeight: 600,
          }}
        >
          {format(date, 'MMM').charAt(0).toUpperCase() + format(date, 'MMM').slice(1)}
        </Typography>
      </Stack>
      {event.image && (
        <Box sx={{ overflow: 'hidden' }}>
          <PreviewSanityImage
            height={previewCardImageHeight}
            imageWidth={previewCardImageWidth}
            image={event.image}
          />
        </Box>
      )}
      <Stack spacing={1} flex="1 1 0">
        <Typography
          variant="body1"
          sx={{
            ...max4LinesTextSx,
            fontWeight: 600,
            lineHeight: 19.2 / 16,
            hyphens: 'auto',
          }}
        >
          {event.title}
        </Typography>
        {(event.eventInformation.registration?.registrationUrl ||
          event.eventInformation.registration?.registrationEmail) && (
          <Link
            onClick={(event) => event.stopPropagation()}
            href={
              event.eventInformation.registration.registrationUrl
                ? event.eventInformation.registration.registrationUrl
                : `mailto:${event.eventInformation.registration.registrationEmail}`
            }
            variant="body2"
            color="primary.main"
            fontWeight={600}
          >
            {t(messages.events.registration)}
          </Link>
        )}
      </Stack>
    </Card>
  );
}

const max4LinesTextSx = maxLinesSx(4);

const cardStyle: SxProps<Theme> = ({ palette }) => ({
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  py: 2,
  marginInline: -2,
  paddingInline: 2,
  textDecoration: 'none',
  border: 0,
  columnGap: 2,
  alignItems: 'center',
  borderRadius: 0,
  '& img': {
    transition: 'transform 350ms ease-in-out',
  },

  ':hover': {
    borderLeft: 'none',
    borderRight: 'none',
    boxShadow: ({ palette }) => `0 0 10px ${alpha(palette.common.black, 0.1)}`,
  },
  '&:hover img': {
    transform: 'scale(1.05)',
  },
});
