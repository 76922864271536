import { AppFeatureConfig } from '@dap/dashboard/types';
import { defaultBrandFeatures } from './defaultBrandFeatures';

const nordbohusFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
  leads: {
    resolveToggle: ({ userHasOmniumLeadsAccess }) => (userHasOmniumLeadsAccess ? 'on' : 'off'),
  },
  mgaBanner: {
    resolveToggle: ({ order_module }) => (order_module?.[0]?.show ? 'on' : 'off'),
  },
  workplace: { resolveToggle: () => 'on' },
};

export const nordbohusFeaturesDev: AppFeatureConfig = { ...nordbohusFeaturesDefault };
export const nordbohusFeaturesStage: AppFeatureConfig = { ...nordbohusFeaturesDefault };
export const nordbohusFeaturesProd: AppFeatureConfig = {
  ...nordbohusFeaturesDefault,
  completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
};
