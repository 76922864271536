import {
  LOCATION_PINMETO,
  LOCATION_PINMETO_MAPPING,
  LOCATION_PINMETO_SYNC,
  LOCATION_V2,
  LOCATION_V2_BULK_EMPLOYEE,
  LOCATION_V2_EMPLOYEES,
  LOCATION_V2_KEY,
  LOCATION_V2_PROPERTIES,
  LOCATION_V2_PROPERTY_KEY,
  LOCATION_V2_SEARCH,
} from '@dap/admin/config';
import {
  CreateProperty,
  EditProperty,
  EmployeeBasicV2,
  Location,
  LocationBasic,
  PatchLocation,
  PropertyDetail,
  UpdatePinMeToMappingRequest,
} from '@dap/admin/types';
import { sortAdminsFirst, sortDeactivatedLast } from '@dap/admin/utils';
import {
  employeeOverviewMapper,
  locationBasicMapper,
  locationDetailMapper,
} from '@dap/common/utils';
import {
  EmployeeOverviewDTO,
  FindLocations,
  LocationBasicDTO,
  LocationDetailDTO,
  PinmetoLocationMappingDTO,
} from '@dap/generated-types';
import { selectBrandKey } from '../redux/brand/brandReducer';
import { RootState } from '../redux/store';
import { AddEmployeesToLocationRequest, RemoveEmployeesFromLocationRequest } from '../redux/types';
import { brandadminApi } from './brandadminApi';
import { BrandTags, DataownerTags, EmployeeTags, LocationTags } from './tags';

const tags = [
  ...Object.values(LocationTags),
  DataownerTags.SEARCH_DATAOWNERS,
  BrandTags.BRAND_LOCATIONS,
  EmployeeTags.EMPLOYEE,
];

export const locationApi = brandadminApi.enhanceEndpoints({ addTagTypes: tags }).injectEndpoints({
  endpoints: (build) => ({
    getLocation: build.query<Location, Location['key']>({
      query: (key) => ({ url: LOCATION_V2_KEY(key) }),
      transformResponse: (response: LocationDetailDTO) => locationDetailMapper(response),
      async onQueryStarted(_key, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const state = getState() as RootState;
          // if the user has access to location but is on the wrong brand, select correct brand.
          if (state.brand.selectedBrandKey !== data.dataowner.brand.key) {
            dispatch(selectBrandKey(data.dataowner?.brand?.key || ''));
          }
        } catch (error) {
          /* empty */
        }
      },
      providesTags: (result) =>
        result
          ? [
              { type: LocationTags.LOCATION, id: result.key },
              LocationTags.LOCATION,
              ...(result.properties
                ? result.properties
                    .filter((property) => property.inherited)
                    .map(({ key }) => ({
                      type: BrandTags.BRAND_INHERITABLE_PROPERTIES,
                      id: key,
                    }))
                : []),
            ]
          : [LocationTags.LOCATION],
    }),
    updateLocation: build.mutation<Location, { locationKey: string; patchFields: PatchLocation }>({
      query: ({ locationKey, patchFields }) => ({
        url: LOCATION_V2_KEY(locationKey),
        method: 'PATCH',
        body: patchFields,
      }),
      transformResponse: (response: LocationDetailDTO) => locationDetailMapper(response),
      invalidatesTags: (_result, _error, arg) => [
        { type: LocationTags.LOCATION, id: arg.locationKey },
      ],
    }),
    deleteLocation: build.mutation<void, { locationKey: string; dataownerKey: string }>({
      query: ({ locationKey }) => ({
        url: LOCATION_V2_KEY(locationKey),
        method: 'DELETE',
      }),
      invalidatesTags: [
        BrandTags.BRAND_LOCATIONS,
        BrandTags.BRAND_DATAOWNERS,
        DataownerTags.SEARCH_DATAOWNERS,
        LocationTags.SEARCH_LOCATIONS,
        DataownerTags.DATAOWNER,
      ],
    }),
    searchLocations: build.query<Array<LocationBasic>, FindLocations>({
      query: (payload) => ({
        url: LOCATION_V2_SEARCH,
        method: 'POST',
        body: payload,
      }),
      providesTags: [LocationTags.SEARCH_LOCATIONS],
      transformResponse: (response: Array<LocationBasicDTO>) => locationBasicMapper(response),
    }),
    updateLocatons: build.mutation<Array<Location>, Array<PatchLocation>>({
      query: (payload) => ({
        url: LOCATION_V2,
        method: 'PATCH',
        body: payload,
      }),
      transformResponse: (response: Array<LocationDetailDTO>) => response.map(locationDetailMapper),
      invalidatesTags: (result, _error, _arg) => [
        ...(result?.map(({ key }) => ({ type: LocationTags.LOCATION, id: key })) || []),
        ...(result ? [LocationTags.SEARCH_LOCATIONS, BrandTags.BRAND_LOCATIONS] : []),
      ],
    }),
    getLocationEmployees: build.query<Array<EmployeeBasicV2>, Location['key']>({
      query: (key) => ({ url: LOCATION_V2_EMPLOYEES(key) + '?extended=true' }),
      transformResponse: (response: EmployeeOverviewDTO, _meta, args) => {
        const mappedResponse = employeeOverviewMapper(response);
        const mergedEmployees = [
          ...mappedResponse.activeEmployees,
          ...mappedResponse.inactiveEmployees,
        ];
        return sortDeactivatedLast(sortAdminsFirst(mergedEmployees));
      },
      providesTags: (result, _error, locationKey) =>
        result
          ? [
              ...result.map(({ userId }) => ({ type: EmployeeTags.EMPLOYEE, id: userId })),
              { type: LocationTags.LOCATION_EMPLOYEES, id: locationKey },
            ]
          : [{ type: LocationTags.LOCATION_EMPLOYEES, id: locationKey }],
    }),
    addEmployeesToLocation: build.mutation<EmployeeBasicV2[], AddEmployeesToLocationRequest>({
      query: ({ locationKey, ...rest }) => ({
        url: LOCATION_V2_EMPLOYEES(locationKey),
        method: 'POST',
        body: [rest], // TODO: Use addEmployeeMapper after MID-901
      }),
      invalidatesTags: (result, _error, arg) =>
        result
          ? [
              { type: LocationTags.LOCATION_EMPLOYEES, id: arg.locationKey },
              { type: EmployeeTags.EMPLOYEE, id: arg.userId },
              DataownerTags.SEARCH_DATAOWNERS,
              LocationTags.SEARCH_LOCATIONS,
              EmployeeTags.SEARCH_EMPLOYEES,
            ]
          : [],
    }),
    removeEmployeesFromLocation: build.mutation<void, RemoveEmployeesFromLocationRequest>({
      query: ({ locationKey, userIds }) => ({
        url: LOCATION_V2_BULK_EMPLOYEE(locationKey),
        method: 'DELETE',
        body: userIds,
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...arg.userIds.map((id) => ({ type: EmployeeTags.EMPLOYEE, id: id })),
        { type: LocationTags.LOCATION_EMPLOYEES, id: arg.locationKey },
        DataownerTags.SEARCH_DATAOWNERS,
        LocationTags.SEARCH_LOCATIONS,
        EmployeeTags.SEARCH_EMPLOYEES,
      ],
    }),
    createLocationProperty: build.mutation<
      PropertyDetail,
      { locationKey: string; property: CreateProperty }
    >({
      query: ({ locationKey, property }) => ({
        url: LOCATION_V2_PROPERTIES(locationKey),
        method: 'POST',
        body: property,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: LocationTags.LOCATION, id: arg.locationKey },
      ],
    }),
    updateLocationProperty: build.mutation<
      PropertyDetail,
      { locationKey: string; propertyKey: string; property: EditProperty }
    >({
      query: ({ locationKey, propertyKey, property }) => ({
        url: LOCATION_V2_PROPERTY_KEY(locationKey, propertyKey),
        method: 'PATCH',
        body: property,
      }),
      invalidatesTags: (result, _error, arg) => [
        ...(result?.inherited
          ? [BrandTags.BRAND_LOCATIONS, { type: LocationTags.LOCATION, id: arg.locationKey }]
          : [{ type: LocationTags.LOCATION, id: arg.locationKey }]),
      ],
    }),
    deleteLocationProperty: build.mutation<void, { locationKey: string; propertyKey: string }>({
      query: ({ locationKey, propertyKey }) => ({
        url: LOCATION_V2_PROPERTY_KEY(locationKey, propertyKey),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: LocationTags.LOCATION, id: arg.locationKey },
      ],
    }),
    syncPinMeTo: build.mutation<Location, Location['key']>({
      query: (locationKey) => ({
        url: LOCATION_PINMETO_SYNC(locationKey),
        method: 'POST',
      }),
      transformResponse: (response: LocationDetailDTO) => locationDetailMapper(response),
      invalidatesTags: (_result, _error, arg) => [{ type: LocationTags.LOCATION, id: arg }],
    }),
    updatePinMeTo: build.mutation<PinmetoLocationMappingDTO, UpdatePinMeToMappingRequest>({
      query: ({ locationKey, mapping }) => ({
        url: LOCATION_PINMETO_MAPPING(locationKey),
        method: 'POST',
        body: mapping,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: LocationTags.LOCATION, id: arg.locationKey },
      ],
    }),
    removePinMeTo: build.mutation<void, Location['key']>({
      query: (locationKey) => ({
        url: LOCATION_PINMETO(locationKey),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: LocationTags.LOCATION, id: arg }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLocationQuery,
  useUpdateLocationMutation,
  useUpdateLocatonsMutation,
  useDeleteLocationMutation,
  useSearchLocationsQuery,
  useGetLocationEmployeesQuery,
  useAddEmployeesToLocationMutation,
  useRemoveEmployeesFromLocationMutation,
  useCreateLocationPropertyMutation,
  useUpdateLocationPropertyMutation,
  useDeleteLocationPropertyMutation,
  useSyncPinMeToMutation,
  useUpdatePinMeToMutation,
  useRemovePinMeToMutation,
} = locationApi;
