export enum BlockType {
  Embed = 'embedBlock',
  EventList = 'eventList',
  FeaturedArticle = 'featuredArticle',
  FeaturedSubjectArticle = 'featuredSubjectArticle',
  ImageCarousel = 'imageCarousel',
  Leads = 'leads',
  LegacyBanner = 'legacyBanner',
  LegacyImageBanner = 'legacyImageBanner',
  LegacyIntranetBanner = 'legacyIntranetBanner',
  LegacyMGABanner = 'legacyMGABanner',
  LegacyProffBanner = 'legacyProffBanner',
  LinkList = 'linkList',
  NewsList = 'newsList',
  PersonList = 'personList',
  RichText = 'richText',
  ServiceMessage = 'serviceMessage',
  SubjectLinkList = 'subjectLinkList',
  SubjectText = 'subjectText',
  Text = 'textBlock',
  Video = 'video',
  WorkplaceFeed = 'workplaceFeed',
}
