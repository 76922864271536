import { useState, useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { Item } from 'react-photoswipe-gallery';
import { Typography, Card, Box, CardMedia, Button, useTheme, styled } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import { useResizeDetector } from 'react-resize-detector';

import { Lightbox } from './Lightbox';

const ASPECT_RATIO = 16 / 9;

export interface ImageCarouselItem {
  original: string;
  thumbnail: string;
  width: number;
  height: number;
  caption?: string;
  altText?: string;
  focalPoint?: { x: number; y: number };
  srcset?: Record<number, string>;
}

interface Props {
  allowFullscreen: boolean;
  showCaptions: boolean;
  images: ImageCarouselItem[];
}

export function ImageCarousel({ allowFullscreen = true, showCaptions = true, images }: Props) {
  const [current, setCurrent] = useState(0);
  const { palette, spacing } = useTheme();

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
    skipSnaps: true,
  });

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState(0);

  // const { width, height, ref } = useResizeDetector();

  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = () => {
      setScrollSnaps(emblaApi.scrollSnapList().length);
      setSelectedIndex(emblaApi.selectedScrollSnap());
      setCanScrollLeft(emblaApi.canScrollPrev());
      setCanScrollRight(emblaApi.canScrollNext());
    };

    onSelect();

    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi || current === undefined) return;
    emblaApi.scrollTo(current, true);
  }, [current, emblaApi]);

  const setActiveIndex = (index: number) => {
    setCurrent(index);
  };

  const invertedAspectRatio = 1 / ASPECT_RATIO;

  /*
  // Use this to clip the image to a square box underneath for the caption text
  // Calculates the clipping path of a shape with the image keeping the aspect ratio, with a square box underneath for the caption text
  const imageHeight = width ? width / ASPECT_RATIO : 0; // The imageHeight of the image in the carousel, keeping the aspect ratio
  const radius = 8;
  const path =
    width && height
      ? `M ${radius},0 H ${width - radius} A ${radius},${radius} 0 0 1 ${width},${radius} V ${
          imageHeight - radius
        } A ${radius},${radius} 0 0 1 ${width - radius},${imageHeight} V ${
          imageHeight + 1
        } H ${width} V ${height} H 0 V ${
          imageHeight + 1
        } H ${radius} V ${imageHeight} A ${radius},${radius} 0 0 1 0,${
          imageHeight - radius
        } V ${radius} A ${radius},${radius} 0 0 1 ${radius},0 Z`
      : undefined;

    <Box
      ref={ref}
      sx={{
        padding: 0,
        position: 'relative',
        overflow: 'hidden',
        clipPath: path ? `path("${path}")` : undefined,
      }}
    >
      */

  return (
    <Box
      sx={{
        padding: 0,
        containerType: 'inline-size',
        position: 'relative',
        overflow: 'hidden',
      }}
      ref={emblaRef}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          transform: 'translate3d(0, 0, 0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1)',
        }}
      >
        <Lightbox onSlideChange={setActiveIndex}>
          {images.map((image, index) => {
            // Generate srcset attribute for the original image
            const srcset = image.srcset
              ? Object.keys(image.srcset)
                  .map((key) => {
                    const url = image.srcset ? image.srcset[parseInt(key)] : '';
                    return `${url} ${key}w`;
                  })
                  .join(',')
              : undefined;

            return (
              <Item
                key={index}
                original={image.original}
                originalSrcset={srcset}
                thumbnail={image.thumbnail}
                width={image.width}
                height={image.height}
                cropped={true}
                caption={showCaptions ? image.caption : undefined}
                alt={image.altText ?? ''}
              >
                {({ ref, open }) => (
                  <ImageButton
                    ref={ref}
                    onClick={(e) => allowFullscreen && open(e)}
                    allowFullscreen={allowFullscreen}
                  >
                    <Card
                      sx={{
                        aspectRatio: ASPECT_RATIO,
                        padding: 0,
                        margin: 0,
                        width: '100cqw',
                      }}
                    >
                      <CardMedia
                        key={index}
                        component="img"
                        image={image.thumbnail}
                        alt={image.altText ?? ''}
                        srcSet={srcset}
                        sx={{
                          position: 'relative',
                          height: '100%',
                          width: '100%',
                          objectFit: 'cover',
                          objectPosition: image.focalPoint
                            ? `${image.focalPoint.x * 100}% ${image.focalPoint.y * 100}%`
                            : 'center',
                        }}
                      />
                    </Card>
                    {showCaptions && (
                      <Typography
                        mt={14 / 8}
                        variant="body1"
                        fontWeight={500}
                        color="grey.500"
                        sx={{
                          display: '-webkit-box',
                          textOverflow: 'ellipsis',
                          width: '100cqw',
                          overflow: 'hidden',
                          '-webkitBoxOrient': 'vertical',
                          '-webkitLineClamp': '2',
                        }}
                      >
                        {image.caption}
                      </Typography>
                    )}
                  </ImageButton>
                )}
              </Item>
            );
          })}
        </Lightbox>
      </Box>

      <Box
        sx={{
          pointerEvent: 'none',
        }}
      >
        <SquareButton
          variant="contained"
          color={palette?.brand ? 'brand' : 'secondary'}
          sx={{
            position: 'absolute',
            left: ({ spacing }) => spacing(3),
            top: `calc(${(invertedAspectRatio / 2) * 100}cqw)`,
            transform: 'translateY(-50%)',
            opacity: !canScrollLeft ? 0 : 1,
            pointerEvents: !canScrollLeft ? 'none' : 'auto',
          }}
          aria-disabled={!canScrollLeft}
          onClick={() => {
            emblaApi?.scrollPrev();
          }}
          aria-label="Forrige bilde"
        >
          <ArrowBackIcon fontSize="small" />
        </SquareButton>

        <Typography
          variant="caption"
          sx={{
            paddingBlock: 0.5,
            paddingInline: 1,
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%) translateY(-100%)',
            top: `calc(${invertedAspectRatio * 100}cqw - ${spacing(3)})`,
            zIndex: 2,
            color: 'common.white',
            background: '#1C1A178C', // Color of caption background is not defined anywhere
          }}
        >
          {selectedIndex + 1} / {scrollSnaps}
        </Typography>

        <SquareButton
          variant="contained"
          color={palette?.brand ? 'brand' : 'secondary'}
          sx={{
            position: 'absolute',
            right: ({ spacing }) => spacing(3),
            top: `calc(${(invertedAspectRatio / 2) * 100}cqw)`,
            transform: 'translateY(-50%)',
            opacity: !canScrollRight ? 0 : 1,
            pointerEvents: !canScrollRight ? 'none' : 'auto',
          }}
          aria-disabled={!canScrollRight}
          onClick={() => {
            emblaApi?.scrollNext();
          }}
          aria-label="Neste bilde"
        >
          <ArrowForwardIcon fontSize="small" />
        </SquareButton>

        {allowFullscreen && (
          <FullscreenIcon
            sx={{
              color: 'common.white',
              position: 'absolute',
              right: spacing(3),
              top: `calc(${invertedAspectRatio * 100}cqw - ${spacing(3)})`,
              transform: 'translateY(-100%)',
              cursor: 'pointer',
              pointerEvents: 'none',
            }}
          />
        )}
      </Box>
    </Box>
  );
}

const ImageButton = styled('button')<{ allowFullscreen: boolean }>(
  ({ allowFullscreen = true }) => ({
    padding: 0,
    border: 'none',
    textAlign: 'left',
    background: 'transparent',
    cursor: allowFullscreen ? 'pointer' : 'default',
  })
);

const SquareButton = styled(Button)(({ theme }) => ({
  height: '40px',
  width: '40px',
  display: 'flex',
  minWidth: '40px',
  padding: 0,
  borderRadius: theme.shape.borderRadius * 2,
}));
