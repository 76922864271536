import {
  Address,
  AppBasic,
  AppDetailed,
  BrandBasic,
  ContactV2,
  Coordinates,
  Dataowner,
  DataownerBasic,
  DataownerSearchArgs,
  DataownerSearchResult,
  Ecommerce,
  EmployeeSearchResult,
  EmployeeStatus,
  ErpInfo,
  ErpType,
  GLN,
  InformationModelType,
  IntegrationConfigurations,
  MgPris,
  OpenHours,
  OpenHoursDay,
  OrgUnitRole,
  Pinmeto,
  RegionV2,
  Role,
  RoleDetailed,
  UserData,
  UserLanguage,
  PropertyOption,
  InheritableProperty,
  PropertyConfig,
  PropertyOptions,
  PropertyDetail,
} from '@dap/admin/types';
import {
  AddressDTO,
  AppBasicDTO,
  AppDetailDTO,
  ContactDTO,
  CoordinatesDTO,
  DataownerBasicDTO,
  DataownerDetailDTO,
  DataownerFilters,
  DataownerSearchDTO,
  EcommerceDTO,
  EmployeeMeDTO,
  EmployeeSearchDTO,
  ErpInfoDTO,
  FindDataowners,
  GlnDTO,
  InheritablePropertyDTO,
  LocationIntegrationDataDTO,
  MgPrisDataDTO,
  OpenHoursDTO,
  OpenHoursDayDTO,
  OrgUnitRoleDTO,
  PinmetoDataDTO,
  PropertyConfigDTO,
  PropertyDetailDTO,
  PropertyOptionsDTO,
  RegionDetailDTO,
  RoleDetailDTO,
} from '@dap/generated-types';
import { Country } from '@shared/types';
import { alphabeticSort, pruneObject } from '@shared/utils';
import { brandBasicMapper, ecommerceAssortmentsMapper } from './brandMappers';
import { locationBasicMapper } from './locationMappers';
import { sortBy } from 'lodash';

export function regionDetailMapper(regionDetailDTO: RegionDetailDTO): RegionV2 {
  const region: RegionV2 = {
    uuid: regionDetailDTO.uuid,
    name: regionDetailDTO.name,
    key: regionDetailDTO.key,
  };
  return region;
}

export function appDetailedMapper(appDetailDTO: AppDetailDTO): AppDetailed {
  const appDetail: AppDetailed = {
    uuid: appDetailDTO.uuid ?? null,
    name: appDetailDTO.name,
    key: appDetailDTO.key,
    updatedAt: appDetailDTO.updatedAt ?? null,
    updatedBy: appDetailDTO.updatedBy ?? null,
    default: appDetailDTO.default ?? false,
    config: { url: appDetailDTO.config?.url ?? null, name: appDetailDTO.config?.name ?? null },
    url: appDetailDTO.url ?? null,
    ssoEnabled: appDetailDTO.ssoEnabled ?? false,
    integrationEnabled: appDetailDTO.integrationEnabled ?? false,
  };
  return appDetail;
}

export function roleBasicMapper(roleDTO?: OrgUnitRoleDTO[]): OrgUnitRole[] {
  return roleDTO
    ? roleDTO.map((roleDTO) => ({
        role: {
          key: '',
          title: '',
          isAdmin: false,
          selected: false,
          ...roleDTO?.role,
        },
        orgUnitKey: roleDTO?.orgUnitKey || '',
        type: roleDTO?.type || '',
      }))
    : [];
}

export function roleDetailedMapper(roleDTO: RoleDetailDTO): RoleDetailed {
  const role: RoleDetailed = {
    key: roleDTO.key,
    title: roleDTO.title,
    isAdmin: roleDTO.isAdmin ?? false,
    selected: roleDTO.selected ?? false,
  };
  return role;
}

export function propertyOptionsMapper(options: PropertyOptionsDTO): PropertyOption {
  const option: PropertyOption = {
    options: options.options ?? [],
  };

  return option;
}

export function propertyMapper(property: PropertyDetailDTO): PropertyDetail {
  const mapped: PropertyDetail = {
    value: property.value || '',
    inherited: property.inherited || false,
    key: property.key || '',
    displayName: property.displayName || null,
    enabled: property.enabled,
    keyGroup: property.keyGroup || null,
    valueType: property.valueType || '',
    valueOptions: property.valueOptions || null,
    isLocked: property.isLocked,
  };
  return mapped;
}

export function propertiesMapper(properties?: PropertyDetailDTO[]): PropertyDetail[] {
  return properties
    ? [...properties.map((property) => propertyMapper(property))].sort(
        alphabeticSort<PropertyDetail>('key')
      )
    : [];
}

export function inheritablePropertyMapper(property: InheritablePropertyDTO): InheritableProperty {
  const mapped: InheritableProperty = {
    dataownerInheritable: property.dataownerInheritable,
    locationInheritable: property.locationInheritable,
    config: propertyConfigMapper(property.config),
  };
  return mapped;
}

export function inheritablePropertiesMapper(
  properties?: InheritablePropertyDTO[]
): InheritableProperty[] {
  return properties
    ? sortBy(
        [...properties.map((property) => inheritablePropertyMapper(property))],
        (property) => property.config.key
      )
    : [];
}

export function propertyConfigMapper(config: PropertyConfigDTO): PropertyConfig {
  const mapped: PropertyConfig = {
    key: config.key ?? '',
    displayName: config.displayName ?? null,
    enabled: config.enabled,
    keyGroup: config.keyGroup ?? null,
    valueType: config.valueType ?? '',
    valueOptions: propertyConfigValueOptionsMapper(config.valueOptions),
    isLocked: config.isLocked,
  };
  return mapped;
}

export function propertyConfigValueOptionsMapper(
  valueOptions?: PropertyOptionsDTO
): PropertyOptions {
  return valueOptions ? { options: valueOptions.options } : { options: [] };
}

export function userDataMapper(user: EmployeeMeDTO): UserData {
  return {
    email: '',
    familyName: '',
    givenName: '',
    phoneNumber: '',
    ...user,
    name: `${user.givenName} ${user.familyName}`,
    brands: user.brands
      ? [...user.brands.map((brand) => brandBasicMapper(brand))].sort(
          alphabeticSort<BrandBasic>('name')
        )
      : [],
    apps: user.apps ? user.apps.map((app) => appDetailedMapper(app)) : [],
    roles: roleBasicMapper(user.roles),
    metadata: {
      verifyEmailSent: '',
      disabledAt: '',
      updatedAt: '',
      createdAt: '',
      ...user.metadata,
      preferredLanguage: (user.metadata?.preferredLanguage as UserLanguage) || 'NO',
    },
    jobTitle: user.jobTitle ?? null,
  };
}

export function dataownerBasicMapper(dataowner?: DataownerBasicDTO): DataownerBasic {
  return {
    uuid: '',
    name: '',
    key: '',
    orgNr: '',
    countEnabledLocations: 0,
    hasUnverifiedEmployees: false,
    ...dataowner,
    brand: brandBasicMapper(dataowner?.brand),
    properties: propertiesMapper(dataowner?.properties),
  };
}

export function dataownersBasicMapper(dataowners: Array<DataownerBasicDTO>): Array<DataownerBasic> {
  return dataowners.map(dataownerBasicMapper);
}

export function addressMapper(addressDTO?: AddressDTO): Address {
  return {
    city: '',
    postalCode: '',
    street: '',
    ...addressDTO,
    country: addressDTO?.country as Country,
  };
}

export function coordinatesMapper(coordinatesDTO?: CoordinatesDTO): Coordinates {
  return {
    long: coordinatesDTO?.longitude,
    lat: coordinatesDTO?.latitude,
  };
}

export function contactMapper(contactDTO?: ContactDTO): ContactV2 {
  return {
    email: '',
    homepage: '',
    phone: '',
    ...contactDTO,
  };
}

export function ecommerceMapper(ecomDTO?: EcommerceDTO): Ecommerce {
  return {
    assortment: '',
    papirflyStoreId: '',
    hasInventoryManagement: false,
    ...ecomDTO,
    ecomAssortment: ecomDTO?.ecomAssortment
      ? ecommerceAssortmentsMapper(ecomDTO.ecomAssortment)
      : null,
  };
}

export function erpInfoMapper(erpInfoDTO?: ErpInfoDTO): ErpInfo {
  return {
    brandId: '',
    fileGroup: '',
    warehouseId: '',
    ...erpInfoDTO,
    erpType: erpInfoDTO?.erpType as ErpType,
  };
}

export function mgPrisMapper(mgPrisDTO?: MgPrisDataDTO): MgPris {
  return {
    memberId: '',
    departmentId: '',
    ...mgPrisDTO,
  };
}

export function OpenHoursDayMappers(openHoursDayDTO?: OpenHoursDayDTO): OpenHoursDay {
  return {
    state: openHoursDayDTO?.state || '',
    span: openHoursDayDTO?.span?.length
      ? [
          ...openHoursDayDTO.span.map(({ open, close }) => ({
            open: open || '',
            close: close || '',
          })),
        ]
      : [{ open: '', close: '' }],
  };
}

export function openHoursMapper(openHoursDTO?: OpenHoursDTO): OpenHours {
  return {
    mon: OpenHoursDayMappers(openHoursDTO?.mon),
    tue: OpenHoursDayMappers(openHoursDTO?.tue),
    wed: OpenHoursDayMappers(openHoursDTO?.wed),
    thu: OpenHoursDayMappers(openHoursDTO?.thu),
    fri: OpenHoursDayMappers(openHoursDTO?.fri),
    sat: OpenHoursDayMappers(openHoursDTO?.sat),
    sun: OpenHoursDayMappers(openHoursDTO?.sun),
  };
}

export function pinmetoMapper(pinmetoDTO?: PinmetoDataDTO): Pinmeto {
  return {
    pinmetoId: '',
    pinmetoAutomatedSync: false,
    lastPinmetoSync: '',
    ...pinmetoDTO,
    openHours: openHoursMapper(pinmetoDTO?.openHours),
    specialOpenHours: null,
  };
}

export function integrationConfigMapper(
  integrationConfigDTO?: LocationIntegrationDataDTO
): IntegrationConfigurations {
  return {
    byggplanleggerId: integrationConfigDTO?.byggplanleggerId || '',
    ecommerce: ecommerceMapper(integrationConfigDTO?.ecommerce),
    erpInfo: erpInfoMapper(integrationConfigDTO?.erpInfo),
    mgPris: mgPrisMapper(integrationConfigDTO?.mgPris),
    omniumAvailable: !!integrationConfigDTO?.omniumAvailable,
    pinmeto: pinmetoMapper(integrationConfigDTO?.pinmeto),
  };
}

export function glnMapper(glnDTO?: GlnDTO[]): GLN[] {
  return glnDTO
    ? glnDTO.map((gln) => ({
        id: gln.id || '',
        updatedAt: gln.updatedAt ?? null,
        updatedBy: gln.updatedBy ?? null,
      }))
    : [];
}

export function appBasicMapper(appsDTO?: AppBasicDTO[]): AppBasic[] {
  return appsDTO
    ? appsDTO.map((app) => ({
        uuid: '',
        ...app,
        default: !!app.default,
      }))
    : [];
}

export function employeeSearchMapper(employees?: EmployeeSearchDTO[]): EmployeeSearchResult[] {
  return employees
    ? employees.map((employee) => ({
        givenName: '',
        familyName: '',
        jobTitle: '',
        locationNames: employee.locationNames ? employee.locationNames : [],
        ...employee,
        status: employeeStatusMapper(employee.status),
      }))
    : [];
}

export function employeeStatusMapper(status: string): EmployeeStatus {
  switch (status) {
    case EmployeeStatus.BLOCKED:
    case EmployeeStatus.DISABLED:
    case EmployeeStatus.UNVERIFIED:
    case EmployeeStatus.ENABLED:
    case EmployeeStatus.TEMP:
      return status as EmployeeStatus;
    default:
      return EmployeeStatus.UNKNOWN;
  }
}

export function dataownerDetailMapper(dataowner: DataownerDetailDTO): Dataowner {
  return {
    uuid: '',
    memberFrom: '',
    memberTo: '',
    orgNr: '',
    domains: [],
    isAdmin: false,
    createdAt: '',
    updatedAt: '',
    ...dataowner,
    isMember: dataowner.isMember ?? false,
    brand: brandBasicMapper(dataowner.brand),
    country: dataowner.country ? (dataowner.country as Country) : 'NO',
    locations: locationBasicMapper(dataowner.locations),
    glns: glnMapper(dataowner.glns),
    properties: propertiesMapper(dataowner.properties),
    apps: appBasicMapper(dataowner.apps),
    availableRoles: roleBasicMapper(dataowner.availableRoles),
  };
}

export function dataownerSearchResultMapper(dataowner: DataownerSearchDTO): DataownerSearchResult {
  return {
    ...dataowner,
    uuid: dataowner.uuid ?? null,
    orgNr: dataowner.orgNr ?? null,
    glns: glnMapper(dataowner.glns),
    brand: brandBasicMapper(dataowner.brand),
    countEnabledLocations: dataowner.countEnabledLocations ?? 0,
    hasUnverifiedEmployees: dataowner.hasUnverifiedEmployees ?? false,
    country: (dataowner.country ?? 'NO') as Country,
    isMember: dataowner.isMember ?? false,
  };
}

export function dataownerSearchArgsMapper(args: DataownerSearchArgs): FindDataowners {
  const filters: DataownerFilters = pruneObject(args.filters);
  return { ...args, filters: filters };
}

/**
 * @deprecated Only in use while we have two types of roles due to different API versions.
 * When every role is an OrgUnitRole - this method should be deleted.
 */
export function mapOrgUnitRolesToOldRoles(roles?: OrgUnitRole[]): Role[] {
  return roles
    ? roles.map((orgRole) => ({
        '@type': orgRole.type as InformationModelType,
        key: orgRole.orgUnitKey,
        role: orgRole.role.key,
        title: orgRole.role.title,
        admin: orgRole.role.isAdmin,
        genericTitle: orgRole.role.title,
      }))
    : [];
}
