import { previewCardImageHeight } from './newsConstants';
import NewsPreviewCard from './NewsPreviewCard';
import { SanityNewsArticlePreview } from '@dap/sanity/types';
import { CardsCarousel } from '../components/CardsCarousel/CardsCarousel';
import { Stack } from '@mui/material';

interface Props {
  newsArticles: SanityNewsArticlePreview[];
  rootUrl: string;
  title: string;
}

export default function NewsCarousel({ newsArticles, rootUrl, title }: Props) {
  const cards = newsArticles.map(({ image, title, intro, url, id, author, publishAt }) => (
    <Stack
      sx={{
        width: 'calc(25cqw - 12px)',
        minWidth: '260px',
      }}
    >
      <NewsPreviewCard
        key={id}
        image={image}
        imgHeight={previewCardImageHeight}
        title={title}
        intro={intro}
        url={`${rootUrl}/${url}`}
        author={author}
        publishedAt={publishAt}
      />
    </Stack>
  ));

  return <CardsCarousel title={title} cards={cards} />;
}
