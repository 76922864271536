import { defaultBrandFeatures } from './defaultBrandFeatures';
import { AppFeatureConfig } from '@dap/dashboard/types';

const comfortFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
  completeRegistrationEmailInfo: defaultBrandFeatures.completeRegistrationEmailInfo,
};

export const comfortFeaturesDev: AppFeatureConfig = { ...comfortFeaturesDefault };
export const comfortFeaturesStage: AppFeatureConfig = { ...comfortFeaturesDefault };
export const comfortFeaturesProd: AppFeatureConfig = {
  ...comfortFeaturesDefault,
  completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
};
