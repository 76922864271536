export const mgprisHeaderHeight = '66px';
export const sideFiltersWidth = '372px';
export const mgprisPageHeight = `calc(100vh - ${mgprisHeaderHeight})`;
export const dataGridBoldClass = 'MuiDataGrid-cell--bold';
export const dataGridCellHoverClass = 'MGPDataGrid-cell--hover';
export const dataGridRowHoverClass = 'MGPDataGrid-row--hover';
export const dataGridCellNoFocusOutlineClass = 'MGPDataGrid-cell-no-focus-outline';
export const dataGridWhiteRowClass = 'MuiDataGrid-row--white';
export const dataGridColorRowClass = 'MuiDataGrid-row--color';
export const dataGridSelectedRowClass = 'MGPDataGrid-row--selected';
export const dataGridHorizontalPaddingXSClass = 'MuiDataGrid-padding-horizontal--x-small';
export const dataGridHorizontalPaddingSmallClass = 'MuiDataGrid-padding-horizontal--small';
export const dataGridNoHorizontalPaddingClass = 'MuiDataGrid-padding-horizontal--no';
