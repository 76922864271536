import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { messages } from '@dap/common/i18n';
import WelcomeInfo from './WelcomeInfo';
import LandingPageIllustration from './LandingPageIllustration';
import LandingPageLayout from './LandingPageLayout';
import LandingPageErrorCard from './LandingPageErrorCard';

interface Props {
  logoutCallback: () => void;
}

const NotAuthorizedPage = ({ logoutCallback }: Props) => {
  const { t } = useTranslation();

  return (
    <LandingPageLayout>
      <Stack alignItems="center" width="400px">
        <WelcomeInfo />
        <LandingPageErrorCard message={t(messages.common.noAccess)} logout={logoutCallback} />
      </Stack>
      <LandingPageIllustration />
    </LandingPageLayout>
  );
};

export default NotAuthorizedPage;
