import { store } from '@dap/common/data-access';
import { setupi18n } from '@dap/common/i18n';
import { SanityContextProvider } from '@dap/sanity/utils';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import 'dayjs/locale/nb';
import 'dayjs/locale/sv';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import App from './App';
import { config, env } from './config';
import { AuthContextContainer, AuthProvider } from './contexts/Auth';
import { SnackbarMessagesContainer } from './contexts/SnackbarMessagesContext';
import { AuthContainer } from './containers/AuthContainer';
import { RecoilRoot } from 'recoil';
import { intranetTheme } from '@shared/styles';

LicenseInfo.setLicenseKey(config.muiLicenseKey);

dayjs.extend(isLeapYear);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.updateLocale('nb', {
  weekdaysShort: ['søn', 'man', 'tir', 'ons', 'tor', 'fre', 'lør'],
});

setupi18n();

if (env !== 'local') {
  Sentry.init({
    dsn: config.sentryDsn,
    autoSessionTracking: true,
    environment: env,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    release: config.version,
  });
}

const rootContainer = document.getElementById('root');

if (rootContainer) {
  createRoot(rootContainer).render(
    <React.StrictMode>
      <HelmetProvider>
        <ThemeProvider theme={intranetTheme}>
          <RecoilRoot>
            <CssBaseline />
            <Provider store={store}>
              <Router>
                <AuthProvider>
                  <AuthContextContainer>
                    <AuthContainer>
                      <SnackbarMessagesContainer>
                        <SanityContextProvider
                          projectDetails={{
                            projectId: config.sanity.projectId,
                            dataset: config.sanity.dataset,
                          }}
                        >
                          <App />
                        </SanityContextProvider>
                      </SnackbarMessagesContainer>
                    </AuthContainer>
                  </AuthContextContainer>
                </AuthProvider>
              </Router>
            </Provider>
          </RecoilRoot>
        </ThemeProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
}
