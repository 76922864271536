import { Stack, Typography } from '@mui/material';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import PreviewSanityImage from '../previewContent/PreviewSanityImage';
import { proffArticlePreviewImageHeight } from './proffArticleConstants';
import { PreviewCard } from '@dap/common/ui';
import { maxLinesSx } from '@shared/utils';

interface Props {
  url: string;
  image: SanityImageSource;
  title: string;
  intro: string;
}

function ProffArticlePreviewCard({ url, image, title, intro }: Props) {
  return (
    <PreviewCard
      to={url}
      variant="outlined"
      sx={{
        gap: 2,
      }}
    >
      <Stack spacing={1}>
        <PreviewSanityImage height={proffArticlePreviewImageHeight} image={image} noBorderRadius />
        <Stack sx={{ paddingInline: 2, paddingBottom: 2 }}>
          <Typography variant="h4" sx={max2LinesTextSx}>
            {title}
          </Typography>
          <Typography variant="body3" sx={max3LinesTextSx}>
            {intro}
          </Typography>
        </Stack>
      </Stack>
    </PreviewCard>
  );
}

const max2LinesTextSx = maxLinesSx(2);
const max3LinesTextSx = maxLinesSx(3);

export default ProffArticlePreviewCard;
