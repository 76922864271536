import routes from './routes';

type DataownerKeyProps = { dataownerKey: string };
type LocationKeyProps = { locationKey: string };
type EmployeeIdProps = { employeeId: string };
type EventIdProps = { eventId: string };
type ModuleProps = { moduleSlug: string };
type ModuleCategoryProps = { categorySlug: string; moduleSlug: string };
type SubjectProps = { subject: string };
type SubjectContentProps = { subject: string; slug: string };

export const getDashboardRoute = () => `/${routes.home}`;

export const getBrandadminRoute = () => `/${routes.brandadmin}`;

export const getBrandDetailsRoute = () => `${getBrandadminRoute()}/${routes.brandDetails}`;
export const getBrandDetailsConfigurationsRoute = () =>
  `${getBrandDetailsRoute()}/${routes.configurations}`;
export const getBrandDetailsAdministratorsRoute = () =>
  `${getBrandDetailsRoute()}/${routes.administrators}`;
export const getBrandPropertiesRoute = () => `${getBrandDetailsRoute()}/${routes.properties}`;
export const getBrandDetailsChangelogRoute = () => `${getBrandDetailsRoute()}/${routes.changelog}`;

export const getDataownerRoute = ({ dataownerKey }: DataownerKeyProps) =>
  `${getBrandadminRoute()}/${routes.dataowner}/${dataownerKey}`;
export const getDataownerDetailsRoute = ({ dataownerKey }: DataownerKeyProps) =>
  `${getBrandadminRoute()}/${routes.dataowner}/${dataownerKey}/${routes.details}`;
export const getDataownerLocationsRoute = ({ dataownerKey }: DataownerKeyProps) =>
  `${getBrandadminRoute()}/${routes.dataowner}/${dataownerKey}/${routes.locations}`;
export const getDataownerAdministratorsRoute = ({ dataownerKey }: DataownerKeyProps) =>
  `${getBrandadminRoute()}/${routes.dataowner}/${dataownerKey}/${routes.administrators}`;
export const getDataownerPropertiesRoute = ({ dataownerKey }: DataownerKeyProps) =>
  `${getBrandadminRoute()}/${routes.dataowner}/${dataownerKey}/${routes.properties}`;
export const getDataownerChangelogRoute = ({ dataownerKey }: DataownerKeyProps) =>
  `${getBrandadminRoute()}/${routes.dataowner}/${dataownerKey}/${routes.changelog}`;

export const getLocationRoute = ({ locationKey }: LocationKeyProps) =>
  `${getBrandadminRoute()}/${routes.location}/${locationKey}`;
export const getLocationEmployeesRoute = ({ locationKey }: LocationKeyProps) =>
  `${getBrandadminRoute()}/${routes.location}/${locationKey}/${routes.employees}`;
export const getLocationDetailsRoute = ({ locationKey }: LocationKeyProps) =>
  `${getBrandadminRoute()}/${routes.location}/${locationKey}/${routes.details}`;
export const getLocationPropertiesRoute = ({ locationKey }: LocationKeyProps) =>
  `${getBrandadminRoute()}/${routes.location}/${locationKey}/${routes.properties}`;

export const getLocationIntegrationsRoute = ({ locationKey }: LocationKeyProps) =>
  `${getBrandadminRoute()}/${routes.location}/${locationKey}/${routes.integrations}`;

export const getLocationChangelogRoute = ({ locationKey }: LocationKeyProps) =>
  `${getBrandadminRoute()}/${routes.location}/${locationKey}/${routes.changelog}`;

export const getEmployeeRoute = ({ employeeId }: EmployeeIdProps) =>
  `${getBrandadminRoute()}/${routes.employee}/${employeeId}`;

export const getAllEventsRoute = () => `/${routes.events}`;

export const getEventRoute = ({ eventId }: EventIdProps) => `${getAllEventsRoute()}/${eventId}`;

export const getAllNewsRoute = () => `/${routes.news}`;

export const getAllServicesRoute = () => `/${routes.services}`;

export const getModuleRoute = ({ moduleSlug }: ModuleProps) => `/${routes.module}/${moduleSlug}`;

export const getMosaicModuleRoute = ({ moduleSlug }: ModuleProps) =>
  `/${routes.module}/${routes.mosaic}/${moduleSlug}`;

export const getModuleCategoryRoute = ({ categorySlug, moduleSlug }: ModuleCategoryProps) =>
  `${getModuleRoute({ moduleSlug: moduleSlug })}/${categorySlug}`;

export const getOrderRoute = ({ moduleSlug }: ModuleProps) =>
  `/${routes.module}/${routes.order}/${moduleSlug}`;
export const getOrderCategoryRoute = ({ categorySlug, moduleSlug }: ModuleCategoryProps) =>
  `${getOrderRoute({ moduleSlug })}/${categorySlug}`;
export const getOrderSummaryRoute = ({ moduleSlug }: ModuleProps) =>
  `${getOrderRoute({ moduleSlug })}/${routes.orderSummary}`;
export const getContactUsRoute = ({ moduleSlug }: ModuleProps) =>
  `${getOrderRoute({ moduleSlug })}/${routes.contact}`;

export const getTopicPageRoute = (topicSlug: string) => `/${routes.topic}/${topicSlug}`;

// Subject routes

export const getSubjectIndexRoute = ({ subject }: SubjectProps) => `/${routes.subject}/${subject}`;

export const getSubjectTopicPageRoute = ({ subject, slug }: SubjectContentProps) =>
  `/${routes.subject}/${subject}/${routes.topic}/${slug}`;

export const getSubjectArticleIndexRoute = ({ subject }: SubjectProps) =>
  `/${routes.subject}/${subject}/${routes.articles}`;

export const getSubjectArticlePageRoute = ({ subject, slug }: SubjectContentProps) =>
  `/${routes.subject}/${subject}/${routes.articles}/${slug}`;

export const getSubjectEventIndexRoute = ({ subject }: SubjectProps) =>
  `/${routes.subject}/${subject}/${routes.calendar}`;

export const getSubjectEventPageRoute = ({ subject, slug }: SubjectContentProps) =>
  `/${routes.subject}/${subject}/${routes.calendar}/${slug}`;
