import { Stack, Typography } from '@mui/material';
import { messages } from '@dap/common/i18n';
import { useTranslation } from 'react-i18next';

export default function SanityImageErrorComponent() {
  const { t } = useTranslation(['common']);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      color="common.white"
      bgcolor="error.main"
      padding={2}
    >
      <Typography variant="body1">{t(messages.common.error.sanityImage)}</Typography>
    </Stack>
  );
}
