import { messages } from '@dap/common/i18n';
import { ButtonProps, Tooltip } from '@mui/material';
import { ContainedButton } from '@shared/components';
import { useTranslation } from 'react-i18next';

interface Props extends Pick<ButtonProps, 'disabled' | 'sx'> {
  email: string;
}

export default function SendEmailButton({ email, ...buttonProps }: Props) {
  const { t } = useTranslation('common');

  return (
    <Tooltip title={t(messages.common.email.sendEmailInfo)} sx={{ alignItems: 'center' }}>
      {/* Must be wrapped in div https://mui.com/material-ui/react-tooltip/#disabled-elements */}
      <div>
        <ContainedButton
          onClick={() => (window.location.href = `mailto:${email}`)}
          size="small"
          loading={false}
          {...buttonProps}
        >
          {t(messages.common.email.sendEmail)}
        </ContainedButton>
      </div>
    </Tooltip>
  );
}
