import { useState, useEffect } from 'react';
import { Card, Typography, Box, useTheme, IconButton } from '@mui/material';
import ReactPlayer from 'react-player/lazy';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useInView } from 'react-intersection-observer';

interface Props {
  title: string;
  vimeoId: string;
  poster?: string;
  muted: boolean;
  loop: boolean;
  autoPlay: boolean;
}

export function Video({ title, vimeoId, poster, muted, loop, autoPlay }: Props) {
  const { breakpoints, palette } = useTheme();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [isPausedByUser, setIsPausedByUser] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const isAutoPlayLoop = loop && autoPlay;

  useEffect(() => {
    if (!autoPlay) {
      return;
    }

    if (inView && !isPlaying && !isPausedByUser) {
      setIsPlaying(true);
    } else if (!inView && isPlaying) {
      setIsPlaying(false);
    }
  }, [inView, autoPlay, isPlaying, isPausedByUser, vimeoId]);

  const showPoster =
    !isAutoPlayLoop &&
    (isEnded || (!isPlaying && !isPaused) || (!isPlaying && isPaused && !isPausedByUser));

  return (
    <Box sx={{ containerType: 'inline-size' }}>
      {/* Uncomment this block to debug player status */}
      {/* <Box
        sx={{
          position: 'sticky',
          top: '120px',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            padding: 1,
            boxShadow: '0 0 0 2px red',
            background: '#f004',
            color: '#fff',
          }}
        >
          <ul
            style={{
              listStyleType: 'none',
              padding: 0,
              margin: 0,
              display: 'flex',
              gap: '0.5em',
              fontFamily: 'monospace',
              justifyContent: 'space-between',
            }}
          >
            {Object.entries({
              isPlaying,
              isLoaded,
              isPaused,
              isPausedByUser,
              isEnded,
              inView,
              isAutoPlayLoop,
              showPoster,
            }).map(([index, value]) => (
              <li key={index}>
                {value && <span>{index}</span>}
                {!value && <span style={{ opacity: 0.2 }}>{index}</span>}
              </li>
            ))}
          </ul>
        </Box>
      </Box> */}
      <Card
        sx={{
          backgroundColor: 'grey.900',
          color: 'common.white',
          padding: 3,

          [`@container (min-width: ${breakpoints.values.sm}px)`]: {
            padding: 6,
          },
          [`@container (min-width: ${breakpoints.values.md}px)`]: {
            padding: 12,
          },
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            variant="h6"
            component="span"
            sx={{
              display: 'inline-block',
              marginBottom: 2,
              borderBottom: ({ palette }) => `2px solid ${palette.secondary[900]}`,
              [`@container (min-width: ${breakpoints.values.md}px)`]: {
                marginBottom: 3,
              },
            }}
          >
            Video
          </Typography>
          <Typography
            variant="h3"
            component="h2"
            sx={{
              textWrap: 'balance',
              hyphens: 'auto',
              marginBottom: 3,
              [`@container (min-width: ${breakpoints.values.md}px)`]: {
                marginBottom: 6,
              },
            }}
          >
            {title}
          </Typography>
          <Box
            ref={ref}
            data-chromatic="ignore" // https://www.chromatic.com/docs/ignoring-elements/#ignoring-elements-inline
            sx={{
              aspectRatio: 16 / 9,
              borderRadius: 2,
              overflow: 'hidden',
              position: 'relative',
              background: `oklch(from ${palette.grey[900]} calc(l*0.8) c h)`,
            }}
          >
            {(isPlaying || isLoaded || isAutoPlayLoop) && (
              <ReactPlayer
                url={`https://vimeo.com/${vimeoId}`}
                width="100%"
                height="100%"
                controls={!isAutoPlayLoop}
                playing={isPlaying}
                muted={autoPlay ? true : muted}
                loop={loop}
                onEnded={() => {
                  setIsPlaying(false);
                  setIsEnded(true);
                  setIsPaused(false);
                  setIsPausedByUser(false);
                }}
                onStart={() => {
                  setIsLoaded(true);
                  setIsPaused(false);
                  setIsPausedByUser(false);
                  setIsEnded(false);
                }}
                onPlay={() => {
                  setIsPlaying(true);
                  setIsPaused(false);
                  setIsPausedByUser(false);
                  setIsEnded(false);
                }}
                onPause={() => {
                  setIsPaused(true);
                  setIsPlaying(false);
                  if (inView) {
                    // Since we cannot listen to clicks on the pause button inside the player, if the video is paused while in view, we assume it's done by the user
                    setIsPausedByUser(true);
                  }
                }}
                config={{
                  vimeo: {
                    title,
                    playerOptions: {
                      portrait: false,
                      vimeo_logo: false,
                      responsive: true,
                    },
                  },
                }}
              />
            )}
            {!isAutoPlayLoop && poster && (
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  right: 0,
                  bottom: 0,
                  backgroundImage: `url(${poster})`,
                  backgroundSize: 'cover',
                  display: 'grid',
                  placeItems: 'center',
                  transition: 'opacity 300ms',
                  opacity: showPoster ? 1 : 0,
                  pointerEvents: showPoster ? 'auto' : 'none',
                }}
              >
                <IconButton
                  onClick={() => {
                    setIsPlaying(true);
                    setIsEnded(false);
                    setIsPaused(false);
                    setIsPausedByUser(false);
                  }}
                  sx={{
                    color: 'common.white',
                    transition: 'color 300ms',
                    '&:hover': {
                      color: 'rgba(from currentColor r g b / 0.5)',
                    },
                    '&:focus-visible': {
                      outline: '2px solid currentColor',
                    },
                  }}
                >
                  <PlayCircleIcon
                    sx={{
                      width: '78px',
                      height: '78px',
                    }}
                  />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
