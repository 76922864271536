export enum DocumentType {
  Article = 'article',
  BrandConfiguration = 'brandConfiguration',
  BrandNavigation = 'brandNavigation',
  Dashboard = 'dashboardPage',
  Event = 'eventArticle',
  Person = 'person',
  Subject = 'subject',
  SubjectArticle = 'subjectArticle',
  SubjectTopicPage = 'subjectTopicPage',
  SubjectFrontpage = 'subjectFrontpage',
  SubjectEvent = 'subjectEvent',
  Tag = 'tag',
  TopicPage = 'topicPage',
}
