import { defaultBrandFeatures } from './defaultBrandFeatures';
import { AppFeatureConfig } from '@dap/dashboard/types';

const boligpartnerFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
  completeRegistrationEmailInfo: defaultBrandFeatures.completeRegistrationEmailInfo,
};

export const boligpartnerFeaturesDev: AppFeatureConfig = { ...boligpartnerFeaturesDefault };
export const boligpartnerFeaturesStage: AppFeatureConfig = { ...boligpartnerFeaturesDefault };
export const boligpartnerFeaturesProd: AppFeatureConfig = {
  ...boligpartnerFeaturesDefault,
  completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
};
