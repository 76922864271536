import { AppFeatureConfig } from '@dap/dashboard/types';
import { defaultBrandFeatures } from './defaultBrandFeatures';

export const fargerikeBrandFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
  workplace: {
    resolveToggle: () => 'on',
  },
};

export const fargerikeBrandFeaturesDev: AppFeatureConfig = { ...fargerikeBrandFeaturesDefault };
export const fargerikeBrandFeaturesStage: AppFeatureConfig = { ...fargerikeBrandFeaturesDefault };
export const fargerikeBrandFeaturesProd: AppFeatureConfig = { ...fargerikeBrandFeaturesDefault };
