import { kuloerBannerUri } from '@assets/images';
import { AppFeatureConfig } from '@dap/dashboard/types';
import { defaultBrandFeatures } from './defaultBrandFeatures';

export const kuloerBrandFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
  dashboardBanner: { resolveToggle: () => 'on', config: { image: kuloerBannerUri } },
};

export const kuloerBrandFeaturesDev: AppFeatureConfig = { ...kuloerBrandFeaturesDefault };
export const kuloerBrandFeaturesStage: AppFeatureConfig = { ...kuloerBrandFeaturesDefault };
export const kuloerBrandFeaturesProd: AppFeatureConfig = {
  ...kuloerBrandFeaturesDefault,
  completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
};
