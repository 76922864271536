import { defaultBrandFeatures } from './defaultBrandFeatures';
import { AppFeatureConfig } from '@dap/dashboard/types';

const blinkhusFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
  completeRegistrationEmailInfo: defaultBrandFeatures.completeRegistrationEmailInfo,
  mgaBanner: {
    resolveToggle: ({ order_module }) => (order_module?.[0]?.show ? 'on' : 'off'),
  },
  workplace: { resolveToggle: () => 'on' },
  workplaceBanner: { resolveToggle: () => 'off' },
  workplaceEmbedded: defaultBrandFeatures.workplaceEmbedded,
  leads: {
    resolveToggle: ({ userHasOmniumLeadsAccess }) => (userHasOmniumLeadsAccess ? 'on' : 'off'),
  },
  dashboardBanner: { resolveToggle: () => 'off' },
};

export const blinkhusFeaturesDev: AppFeatureConfig = { ...blinkhusFeaturesDefault };
export const blinkhusFeaturesStage: AppFeatureConfig = { ...blinkhusFeaturesDefault };
export const blinkhusFeaturesProd: AppFeatureConfig = {
  ...blinkhusFeaturesDefault,
  completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
};
