import { useMemo } from 'react';
import {
  getSubjectIndexRoute,
  getSubjectArticleIndexRoute,
  getSubjectTopicPageRoute,
  getSubjectEventIndexRoute,
} from '@dap/common/consts';
import { useSelectedBrandKey } from '@dap/common/data-access';
import { useGetBrandConfig } from '@dap/sanity/data-access';
import { NavWithSubRoutes, SubRoute } from '@shared/components';
import { useLocation, matchPath } from 'react-router-dom';
import { FiAperture } from 'react-icons/fi';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { messages } from '@dap/common/i18n';
import { useTranslation } from 'react-i18next';

// Wrap feather icon in a MUI SvgIcon component, to be used in the sidebar
const SubjectIcon = ({ fontSize }: Pick<SvgIconProps, 'fontSize'>) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <FiAperture />
    </SvgIcon>
  );
};

// Iterates the subject configuration for a brand and creates the navigation rouets to use
export const useSubjectRoutes = (): NavWithSubRoutes[] => {
  const brandKey = useSelectedBrandKey();

  const { t } = useTranslation(['common']);
  const { pathname } = useLocation();

  const brandConfiguration = useGetBrandConfig({ brandKey });

  const subjectRoutes = useMemo(() => {
    if (!brandConfiguration) {
      return [];
    }

    const items: NavWithSubRoutes[] = brandConfiguration?.subjects
      ? brandConfiguration.subjects
          .filter((subject) => subject.hasFrontpage)
          .reduce((menu, subject) => {
            // Create routes for any topics in the subject
            const topicRoutes =
              subject.topics?.map((topic) => {
                return {
                  title: topic.title,
                  to: getSubjectTopicPageRoute({ subject: subject.slug, slug: topic.slug }),
                  isActive: !!matchPath(
                    getSubjectTopicPageRoute({ subject: subject.slug, slug: topic.slug }),
                    pathname
                  ),
                } as SubRoute;
              }) ?? [];

            const frontPage: SubRoute = {
              title: 'Forside',
              to: getSubjectIndexRoute({ subject: subject.slug }),
              isActive: !!matchPath(getSubjectIndexRoute({ subject: subject.slug }), pathname),
            } as SubRoute;

            // If we have categories, create subroutes. Add the frontpage as the first subroute
            const subRoutes = [
              frontPage,
              subject.showArticles
                ? ({
                    title: t(messages.common.articles),
                    to: getSubjectArticleIndexRoute({ subject: subject.slug }),
                    isActive: !!matchPath(
                      `${getSubjectArticleIndexRoute({ subject: subject.slug })}/*`,
                      pathname
                    ),
                  } as SubRoute)
                : undefined,
              subject.showEvents
                ? ({
                    title: t(messages.common.calendar),
                    to: getSubjectEventIndexRoute({ subject: subject.slug }),
                    isActive: !!matchPath(
                      `${getSubjectEventIndexRoute({ subject: subject.slug })}/*`,
                      pathname
                    ),
                  } as SubRoute)
                : undefined,
              ...topicRoutes,
            ].filter(Boolean);

            // Return the final route object
            const route = {
              title: subject.title,
              Icon: SubjectIcon,
              isActive: false, // Use false, to let it be active if any subroutes are active
              to: frontPage.to, // Use the first subroute as the main route
              subRoutes,
              initialOpen: false,
            } as NavWithSubRoutes;

            menu.push(route);
            return menu;
          }, [] as NavWithSubRoutes[])
      : [];

    return items;
  }, [brandConfiguration, pathname]);

  return subjectRoutes;
};
