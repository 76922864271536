import { ExpandCircleDownRounded } from '@mui/icons-material';
import { IconButton as MuiIconButton, Stack, styled } from '@mui/material';
import { messages } from '@dap/common/i18n';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SnapItem, SnapList } from 'react-snaplist-carousel';
import { FlexRow } from '../Layout';
import { useCarousel } from '@shared/hooks';

interface Props {
  items: Array<ReactElement>;
  itemsSpacing?: number;
}

export default function Carousel({ items, itemsSpacing = 1 }: Props) {
  const { t } = useTranslation();

  const { snapListRef, firstItemIsVisible, lastItemIsVisible, scrollForward, scrollBack } =
    useCarousel(items.length);

  return (
    <Stack>
      <FlexRow>
        <IconButton
          disabled={firstItemIsVisible}
          onClick={scrollBack}
          aria-label={t(`common:${messages.common.goToPrevious}`)}
        >
          <ExpandCircleDownRounded
            color="inherit"
            fontSize="inherit"
            style={{ transform: 'rotate(90deg)' }}
          />
        </IconButton>
        <SnapList ref={snapListRef} direction="horizontal">
          {items?.map((item, index) => (
            <SnapItem
              snapAlign="center"
              key={item.key}
              margin={{
                right: `${index === items.length - 1 ? 0 : itemsSpacing}rem`,
                left: `${index === 0 ? 0 : itemsSpacing}rem`,
              }}
            >
              {item}
            </SnapItem>
          ))}
        </SnapList>
        <IconButton
          disabled={lastItemIsVisible}
          onClick={scrollForward}
          aria-label={t(`common:${messages.common.goToNext}`)}
        >
          <ExpandCircleDownRounded
            color="inherit"
            fontSize="inherit"
            style={{ transform: 'rotate(-90deg)' }}
          />
        </IconButton>
      </FlexRow>
    </Stack>
  );
}

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  '&:disabled': {
    opacity: 0.25,
  },
  '&:hover': {
    background: theme.palette.grey[100],
  },
  color: 'inherit',
  fontSize: '3rem',
}));
