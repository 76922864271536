import { brandadminI18nResources, brandI18nResources } from '@dap/admin/i18n';
import commonEn from './en/common.json';
import eventsEn from './en/events.json';
import fremdriftEn from './en/fremdrift.json';
import helpCentreEn from './en/helpCentre.json';
import intranetBannerEn from './en/intranetBanner.json';
import mgaEn from './en/mga.json';
import mosaicEn from './en/mosaic.json';
import newsArticlesEn from './en/newsArticles.json';
import orderEn from './en/order.json';
import proffEn from './en/proff.json';
import servicesEn from './en/services.json';
import validationEn from './en/validation.json';
import workplaceEn from './en/workplace.json';
import commonNo from './no/common.json';
import eventsNo from './no/events.json';
import fremdriftNo from './no/fremdrift.json';
import helpCentreNo from './no/helpCentre.json';
import intranetBannerNo from './no/intranetBanner.json';
import mgaNo from './no/mga.json';
import mosaicNo from './no/mosaic.json';
import newsArticlesNo from './no/newsArticles.json';
import orderNo from './no/order.json';
import proffNo from './no/proff.json';
import servicesNo from './no/services.json';
import validationNo from './no/validation.json';
import workplaceNo from './no/workplace.json';
import commonSv from './sv/common.json';
import eventsSv from './sv/events.json';
import fremdriftSv from './sv/fremdrift.json';
import helpCentreSv from './sv/helpCentre.json';
import intranetBannerSv from './sv/intranetBanner.json';
import mgaSv from './sv/mga.json';
import mosaicSv from './sv/mosaic.json';
import newsArticlesSv from './sv/newsArticles.json';
import orderSv from './sv/order.json';
import proffSv from './sv/proff.json';
import servicesSv from './sv/services.json';
import validationSv from './sv/validation.json';
import workplaceSv from './sv/workplace.json';
import { sharedLanguageResources } from '@shared/i18n';

export const defaultResources = {
  common: commonNo,
  events: eventsNo,
  validation: validationNo,
  newsArticles: newsArticlesNo,
  services: servicesNo,
  workplace: workplaceNo,
  proff: proffNo,
  fremdrift: fremdriftNo,
  helpCentre: helpCentreNo,
  mosaic: mosaicNo,
  order: orderNo,
  intranetBanner: intranetBannerNo,
  mga: mgaNo,
  ...brandadminI18nResources.no,
  ...brandI18nResources.no,
  ...sharedLanguageResources.no,
};

export type AppResources = typeof defaultResources;

export type AppLanguageResources = {
  no: AppResources;
  sv: AppResources;
  en: AppResources;
};

/**
 * 'Resources' typing makes sure no language files have missing translations, where Norwegian is default
 * 'I18nResource' is present to please TS in init function at the bottom
 **/
export const appLanguageResources: AppLanguageResources = {
  no: defaultResources,
  en: {
    common: commonEn,
    events: eventsEn,
    validation: validationEn,
    newsArticles: newsArticlesEn,
    services: servicesEn,
    workplace: workplaceEn,
    proff: proffEn,
    fremdrift: fremdriftEn,
    helpCentre: helpCentreEn,
    mosaic: mosaicEn,
    order: orderEn,
    intranetBanner: intranetBannerEn,
    mga: mgaEn,
    ...brandadminI18nResources.en,
    ...brandI18nResources.en,
    ...sharedLanguageResources.en,
  },
  sv: {
    common: commonSv,
    events: eventsSv,
    validation: validationSv,
    newsArticles: newsArticlesSv,
    services: servicesSv,
    workplace: workplaceSv,
    proff: proffSv,
    fremdrift: fremdriftSv,
    helpCentre: helpCentreSv,
    mosaic: mosaicSv,
    order: orderSv,
    intranetBanner: intranetBannerSv,
    mga: mgaSv,
    ...brandadminI18nResources.sv,
    ...brandI18nResources.sv,
    ...sharedLanguageResources.se,
  },
};

export type NonBrandNamespaces = 'common' | 'validation' | 'newsArticles' | 'services' | 'mosaic';
