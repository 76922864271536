import { BrandConfigurationRequest, ModuleType, DocumentType } from '@dap/sanity/types';
import { groqQuery } from '@shared/utils';
import groq from 'groq';

export const proffModuleQuery = groq`
  _id,
  _type,
  show,
  "slug": module_slug.current,
  header,
  frontPageProffImage
`;

export const newsModuleQuery = groq`
  _type,
  header,
  show,
  infoText
`;

export const eventsModuleQuery = groq`
  _type,
  header,
  show
`;

export const servicesModuleQuery = groq`
  _type,
  show,
  header,
  infoText
`;

export const fremdriftModuleQuery = groq`
  _id,
  _type,
  show,
  "slug": module_slug.current,
  header`;

export const orderModuleQuery = groq`
  _id,
  _type,
  show,
  "slug": module_slug.current,
  header`;

export const mosaicModuleQuery = groq`
  _id,
  _type,
  show,
  "slug": module_slug.current,
  header`;

export const helpCenterModuleQuery = groq`
  _type,
  header,
  show`;

export const staticModuleQuery = groq`
  module_type,
  "staticModuleData": *[
    _type == "${DocumentType.BrandConfiguration}"
    && coalesce(^.^.^.^.brand, ^.^.^.brand) == brand
    && !(_id in path("drafts.*"))
  ][]{
    modules,
    "staticModuleData": modules[_type match ^.^.module_type][0]{
      show,
      header
    }
  }[staticModuleData.show][0]{
    ...
  }.staticModuleData`;

export const getModuleConfigQuery = ({ brandKey }: BrandConfigurationRequest) =>
  groqQuery({
    filters: [`_type=="brandConfiguration" && brand=="${brandKey}"`],
    projections: [
      `'${ModuleType.helpCentreModule}':modules[_type match "${ModuleType.helpCentreModule}"][0]{
          ...,
          "body": textBlock
        }`,
      `'${ModuleType.fremdriftModule}':*[_type match "${ModuleType.fremdriftModule}" &&  (_id in ^.modules[]._ref) && count((categories[]->brand)["${brandKey}" in @]) > 0]{
          ...,
          "slug": module_slug.current,
          categories["${brandKey}" in @->.brand]->{
            "id": _id,
            "slug": category_slug.current,
            title,
            "image": image.asset->url,
            "media": {
                "mediaType": media.mediaType,
                "videoSrc": {"url": media.videoSrc.url},
                "imageSrc": {"url": media.imageSrc.asset->url, alt},
            },
            "body": textBlock,
            "contacts": person_array[]->
          }
        }`,
      `'${ModuleType.mosaicModule}':modules[_type match "${ModuleType.mosaicModule}"][]->{
            ...,
            callToAction{
                ...,
                internalUrl -> {_type, "slug": module_slug.current}
            },
            contact->{
                name,
                email,
                "jobtitle": jobbtittel,
                phone,
                "image": image.asset->url,
              },
              attachments[]{ label, "url": asset->url },
              mosaicBuilder[]{
                "image": image.asset->url,
                url,
                linkText,
                title,
                subtitle,
                size,
                _type,
                _key,
              },
              "image": image.asset->url,
              "slug": module_slug.current
          }`,
      `'${ModuleType.orderModule}':*[_type == "${ModuleType.orderModule}" && "${brandKey}" in brand][]{
            "id": _id,
            show,
            header,
            _type,
            categories[]->{
                "id": _id,
                title,
                "slug": category_slug.current,
            },
            "logo": image.asset->url,
          title,
          usp,
          "slug": module_slug.current
        }`,
      `'${ModuleType.proffModule}':modules[_type match "${ModuleType.proffModule}"][]->{
            ...,
            "slug": module_slug.current,
            categories[]->{
              "id": _id,
              "slug": category_slug.current,
              title,
              "image": image.asset->url,
              "media": {
                mediaType,
                videoSrc,
                "imageSrc": {"url": imageSrc.asset->url, alt},
              },
              "body": textBlock,
              "contacts": person_array[]->
            }
          }`,
      `'${ModuleType.servicesModule}':modules[_type match "${ModuleType.servicesModule}"][0]`,
      `'${ModuleType.eventsModule}':modules[_type match "${ModuleType.eventsModule}"][0]`,
      `'${ModuleType.newsModule}':modules[_type match "${ModuleType.newsModule}"][0]`,
    ],
    slice: { start: 0 },
  });

export const getHelpCentreModule = ({ brandKey }: BrandConfigurationRequest) =>
  groq`*[_type=='brandConfiguration' && brand=='${brandKey}'][0]
  .modules[_type match '${ModuleType.helpCentreModule}'][0]
  {..., 'body': textBlock}`;
