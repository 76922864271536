import { Stack } from '@mui/material';
import WelcomeInfo from './WelcomeInfo';
import { messages } from '@dap/common/i18n';
import LandingPageIllustration from './LandingPageIllustration';
import LandingPageLayout from './LandingPageLayout';
import { useTranslation } from 'react-i18next';
import LandingPageErrorCard from './LandingPageErrorCard';

interface Props {
  logoutCallback: () => void;
}

export default function PublicErrorPage({ logoutCallback }: Props) {
  const { t } = useTranslation();

  return (
    <LandingPageLayout>
      <Stack alignItems="center" width="400px">
        <WelcomeInfo />
        <LandingPageErrorCard
          message={t(messages.common.landingPage.userError)}
          logout={logoutCallback}
        />
      </Stack>
      <LandingPageIllustration />
    </LandingPageLayout>
  );
}
