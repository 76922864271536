import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'direction' && prop !== 'size' && prop !== 'width',
})<Props>(({ theme: { breakpoints, spacing }, direction, size, width }) => ({
  ...(direction === 'column' && {
    paddingBottom: spacing(0.5),
  }),

  ...(direction === 'row' && {
    [breakpoints.up('sm')]: {
      padding: size === 'medium' ? spacing(2, 0) : spacing(0.5, 0),
      ...(width && { width: width }),
    },
  }),

  [breakpoints.down('sm')]: {
    paddingBottom: spacing(0.5),
  },

  '> .Mui-IconButton': {
    marginTop: '-5px',
    marginBottom: '-5px',
  },
}));

interface Props {
  direction: 'row' | 'column';
  width?: number;
  size: 'small' | 'medium' | 'large';
}

export default function FormLabelWrapper({
  children,
  width,
  direction,
  size,
}: PropsWithChildren<Props>) {
  return (
    <Wrapper width={width} size={size} direction={direction}>
      {children}
    </Wrapper>
  );
}
