import { EnabledOption, LocationFilterSchema } from '@dap/admin/types';
import { FindLocations } from '@dap/generated-types';
import { containsOnly, pruneObject, pruneObjectFromEmptyList } from '@shared/utils';

export const mapLocationSearchAndFilterSchemaToApiData = ({
  locationPropertyFilter,
  enabledStatusFilter,
  regionFilter,
  sort,
}: LocationFilterSchema): FindLocations => {
  const data: FindLocations = {
    filters: {
      locationPropertyFilter: locationPropertyFilter,
      regionFilter: regionFilter,
      enabledStatusFilter: containsOnly(enabledStatusFilter, EnabledOption.ENABLED)
        ? true
        : containsOnly(enabledStatusFilter, EnabledOption.DISABLED)
        ? false
        : undefined,
      includeChiefExecutives: true,
    },
    operator: 'AND',
  };
  return pruneObject(pruneObjectFromEmptyList(data));
};
