/**
 * Copied, with some modifications, from:
 * https://github.com/vovayatsyuk/photoswipe-object-position
 *
 * Added types, fixed issue with itemData width/height on other property
 */
import type PhotoSwipeLightbox from 'photoswipe/lightbox';

function getCroppedBoundsOffset(
  position: string,
  imageSize: number,
  thumbSize: number,
  zoomLevel: number
) {
  const float = parseFloat(position);
  return position.indexOf('%') > 0 ? ((thumbSize - imageSize * zoomLevel) * float) / 100 : float;
}

function getCroppedZoomPan(position: string, min: number, max: number) {
  const float = parseFloat(position);
  return position.indexOf('%') > 0 ? min + ((max - min) * float) / 100 : float;
}

function getThumbnail(el?: HTMLElement) {
  return el?.querySelector('img');
}

function getObjectPosition(el?: HTMLElement | null) {
  return el ? getComputedStyle(el).getPropertyValue('object-position').split(' ') : ['50%', '50%'];
}

export default class ObjectPosition {
  constructor(lightbox: PhotoSwipeLightbox) {
    /**
     * Make pan adjustments if large image doens't fit the viewport.
     *
     * Examples:
     * 1. When thumb object-position is 50% 0 (top part is initially visible)
     *    make sure you'll see the top part of the large image as well.
     * 2. When thumb object-position is 50% 100% (bottom part is initially visible)
     *    make sure you'll see the bottom part of the large image as well.
     */
    lightbox.on('initialZoomPan', (event) => {
      const slide = event.slide;

      const [positionX, positionY] = getObjectPosition(getThumbnail(slide.data.element));

      if (positionX !== '50%' && slide.pan.x < 0) {
        slide.pan.x = getCroppedZoomPan(positionX, slide.bounds.min.x, slide.bounds.max.x);
      }

      if (positionY !== '50%' && slide.pan.y < 0) {
        slide.pan.y = getCroppedZoomPan(positionY, slide.bounds.min.y, slide.bounds.max.y);
      }
    });

    /**
     * Fix opening animation when thumb object-position is not 50% 50%.
     * https://github.com/dimsemenov/PhotoSwipe/pull/1868
     */
    // @ts-ignore
    lightbox.pswp.addFilter('thumbBounds', (thumbBounds, itemData) => {
      if (!thumbBounds || !thumbBounds.innerRect) {
        return thumbBounds;
      }

      const thumbEl = getThumbnail(itemData.element);
      if (!thumbEl) {
        return thumbBounds;
      }

      const itemWidth = itemData.width ?? itemData.w ?? 1;
      const itemHeight = itemData.height ?? itemData.h ?? 1;

      const thumbAreaRect = thumbEl.getBoundingClientRect();
      const fillZoomLevel = thumbBounds.w / itemWidth;
      const [positionX, positionY] = getObjectPosition(thumbEl);

      if (positionX !== '50%') {
        const offsetX = getCroppedBoundsOffset(
          positionX,
          itemWidth,
          thumbAreaRect.width,
          fillZoomLevel
        );
        thumbBounds.x = thumbAreaRect.left + offsetX;
        thumbBounds.innerRect.x = offsetX;
      }

      if (positionY !== '50%') {
        const offsetY = getCroppedBoundsOffset(
          positionY,
          itemHeight,
          thumbAreaRect.height,
          fillZoomLevel
        );
        thumbBounds.y = thumbAreaRect.top + offsetY;
        thumbBounds.innerRect.y = offsetY;
      }

      return thumbBounds;
    });
  }
}
