import { CircularProgress, Container } from '@mui/material';
import { CenterElement } from '@dap/common/ui';

function LoadingPage() {
  return (
    <Container sx={{ height: '100vh' }}>
      <CenterElement>
        <CircularProgress color="primary" />
      </CenterElement>
    </Container>
  );
}

export default LoadingPage;
