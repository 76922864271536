import { CheckCircle } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { FlexRow } from '../Layout';
import { useAppTFunction } from '@dap/common/i18n';
import { CopyIcon } from '@shared/custom-icons';

interface Props {
  value: string;
  label?: React.ReactNode;
}

const CopyValueToClipboard = ({ value, label }: Props) => {
  const t = useAppTFunction();
  const [copied, setCopied] = useState<boolean>(false);

  const copy = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 700);
  };
  return (
    <FlexRow flexWrap={'nowrap'}>
      {!!label && label}
      <Tooltip title={`${t('common.copy.copyToClipboard')}`}>
        <IconButton
          aria-label={`${t('common.copy.copyToClipboard')} ${label ?? ''}`}
          size="small"
          onClick={copy}
        >
          {copied ? (
            <CheckCircle fontSize="small" color="success" />
          ) : (
            <CopyIcon fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
    </FlexRow>
  );
};

export default CopyValueToClipboard;
