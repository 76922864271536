import { CheckCircle } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import { useState } from 'react';
import { CopyIcon } from '@shared/custom-icons';
import { useAppTFunction } from '@dap/common/i18n';

interface Props extends ButtonProps {
  value: string;
}

export default function CopyToClipboardButton({ value, ...rest }: Props) {
  const t = useAppTFunction();
  const [copied, setCopied] = useState<boolean>(false);

  const copy = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 700);
  };
  return (
    <Button
      {...rest}
      onClick={copy}
      startIcon={
        copied ? (
          <CheckCircle fontSize="small" color="success" />
        ) : (
          <CopyIcon fontSize="small" color="inherit" />
        )
      }
    >
      {t('common.copy.copyToClipboard')}
    </Button>
  );
}
