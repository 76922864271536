import { LocalStorage } from '@shared/constants';
import { LanguageCapitalLettersCode, LanguageCode } from '@shared/types';
import { getDefaultLanguageSettingFromLocale } from '@shared/utils';
import { UserData, UserLanguage } from '@dap/admin/types';

export const getLanguageKeyFromUserLanguage = (languageKey: UserLanguage): LanguageCode => {
  switch (languageKey) {
    case LanguageCapitalLettersCode.Norwegian:
      return LanguageCode.Norwegian;
    case LanguageCapitalLettersCode.Swedish:
      return LanguageCode.Swedish;
    default:
      return LanguageCode.English;
  }
};

export const getUserLanguageSetting = (user?: UserData): LanguageCode => {
  //remove deprecated data in local storage 2022.03.15
  const storedUserLanguageSetting = window.localStorage.getItem(LocalStorage.SELECTED_LANGUAGE);
  if (storedUserLanguageSetting) {
    window.localStorage.removeItem(LocalStorage.SELECTED_LANGUAGE);
  }

  if (user?.metadata.preferredLanguage) {
    return getLanguageKeyFromUserLanguage(user.metadata.preferredLanguage);
  } else {
    return getDefaultLanguageSettingFromLocale();
  }
};
