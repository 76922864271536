import {
  ExtraInfoType,
  KeyInfoType,
  RegistrationType,
  EventSourceType,
  DocumentType,
} from '@dap/sanity/types';
import { groqQuery } from '@shared/utils';

export interface GetEventPreviewsQueryProps {
  date: string;
  brandKey: string;
  source?: EventSourceType;
  maxNumberToLoad?: number;
  subject?: string;
}

export const getEventPreviewsQuery = ({
  date,
  brandKey,
  maxNumberToLoad,
  source = 'upcoming',
  subject,
}: GetEventPreviewsQueryProps) => {
  let dateFilter = '';
  let order: 'asc' | 'desc' = 'asc';

  switch (source) {
    case 'upcoming':
      dateFilter = `eventInformation.dateTimeRange.startDate >= "${date}"`;
      break;

    case 'upcoming_and_current':
      dateFilter = `(
        (
          eventInformation.dateTimeRange.startDate >= "${date}"
        ) || (
          defined(eventInformation.dateTimeRange.endDate)
          && eventInformation.dateTimeRange.startDate < "${date}"
          && eventInformation.dateTimeRange.endDate >= "${date}"
        )
      )`;
      break;

    case 'current':
      dateFilter = `(
        defined(eventInformation.dateTimeRange.endDate)
        && eventInformation.dateTimeRange.startDate < "${date}"
        && eventInformation.dateTimeRange.endDate >= "${date}"
      )`;
      break;

    case 'past':
      dateFilter = `(
        (
          !defined(eventInformation.dateTimeRange.endDate)
          && eventInformation.dateTimeRange.startDate < "${date}"
        ) || (
          defined(eventInformation.dateTimeRange.endDate)
          && eventInformation.dateTimeRange.endDate < "${date}"
        )
      )`;
      order = 'desc';
      break;
  }

  // Filters to use when querying brand events
  const brandFilters = [
    `_type == "${DocumentType.Event}"`,
    `"${brandKey}" in brand`,
    'defined(title)',
    'defined(eventInformation.dateTimeRange.startDate)',
    dateFilter,
  ];

  // Filters to use quering subject events
  const subjectFilters = [
    `_type == "${DocumentType.SubjectEvent}"`,
    `subject->slug.current == "${subject}"`,
    `((defined(brands) && "${brandKey}" in brands) || !defined(brands))`,
    'defined(title)',
    'defined(eventInformation.dateTimeRange.startDate)',
    dateFilter,
  ];

  const filters = subject ? subjectFilters : brandFilters;

  const query = groqQuery({
    filters: filters,
    ordering: [{ fieldname: 'eventInformation.dateTimeRange.startDate', order }],
    projections: [
      '"id": _id',
      'title',
      '"url": url.current',
      `eventInformation{
        ...,
        "registration": {
          "registrationUrl": registration[_type == "${RegistrationType.url}"][0].registrationUrl,
          "registrationEmail": registration[_type == "${RegistrationType.email}"][0].registrationEmail
        },
        extraInfo{
          "person": extraInfo[_type == "${ExtraInfoType.personRef}"] {
              title,
              "name": ref-> {name}.name
            },
          "link": extraInfo[_type == "${ExtraInfoType.externalLink}"] {
              title,
              url
            },
          "text": extraInfo[_type == "${ExtraInfoType.freeText}"] {
              title,
              value
          }
        }
      }`,
      'intro',
      '"image": image.asset->url',
    ],
    slice: maxNumberToLoad ? { start: 0, end: maxNumberToLoad } : undefined,
  });

  return query;
};

export interface GetEventQueryProps {
  slug: string;
  brandKey: string;
  subject?: string;
}

export const getEventQuery = ({ slug, brandKey, subject }: GetEventQueryProps) => {
  // Filters to use when querying brand events
  const brandFilter = [
    `_type == "${DocumentType.Event}"`,
    `url.current == "${slug}"`,
    `"${brandKey}" in brand`,
    'defined(url)',
  ];

  // Filters to use when querying subject events
  const subjectFilter = [
    `_type == "${DocumentType.SubjectEvent}"`,
    `url.current == "${slug}"`,
    `subject->slug.current == "${subject}"`,
    `((defined(brands) && "${brandKey}" in brands) || !defined(brands))`,
    'defined(url)',
  ];

  const filters = subject ? subjectFilter : brandFilter;

  return groqQuery({
    filters: filters,
    ordering: [{ fieldname: 'eventInformation.dateTimeRange.startDate', order: 'asc' }],
    projections: [
      '...',
      `eventInformation{
          ...,
          "registration": {
            "registrationUrl": registration[_type == "${RegistrationType.url}"][0].registrationUrl,
            "registrationEmail": registration[_type == "${RegistrationType.email}"][0].registrationEmail
          },
          "extraInfo": {
            "person": extraInfo[_type == "${ExtraInfoType.personRef}"] {
                title,
                "name": ref-> {name}.name
              },
            "link": extraInfo[_type == "${ExtraInfoType.externalLink}"] {
                title,
                url
              },
            "text": extraInfo[_type == "${ExtraInfoType.freeText}"] {
                title,
                value
            }
          }
        }`,
      `"body": textBlock[]{
        ...,
        _type == "image" => {
          ...,
          asset->
        }
      }`,
      `"keyInfo": {
        "email": keyInfo.fields[_type match "${KeyInfoType.email}"],
        "link": keyInfo.fields[_type match "${KeyInfoType.link}"],
        "text": keyInfo.fields[_type match "${KeyInfoType.text}"],
      }`,
      `attachments[]{
        label,
        "url": asset->url
      }`,
      `author->`,
    ],
    slice: { start: 0 },
  });
};
