import { KeyInfoField } from '@dap/sanity/types';
import { Stack, Typography } from '@mui/material';
import InlineExternalLink from './InlineExternalLink';

interface Props {
  email: KeyInfoField;
}

// const linkIconStyle: SxProps<Theme> = { mr: 2, width: '14px', height: '14px' };

export default function KeyInfoEmail({ email }: Props) {
  const href = email.value.startsWith('mailto:') ? email.value : `mailto:${email.value}`;
  return (
    <Stack>
      <Typography variant="h6" component="h3">
        {email.title}
      </Typography>
      <InlineExternalLink variant="body2" href={href} noWrap>
        {email.value}
      </InlineExternalLink>
    </Stack>
  );
}
