import ReactGA from 'react-ga4';
import { useAppSelector } from '@dap/common/data-access';
import { InformationModelType, OrgUnitRole, UserData } from '@dap/admin/types';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RoleType } from '@dap/common/types';
import { config } from '../config';

const isLocationAdminRole = (role: OrgUnitRole) =>
  role.type === InformationModelType.Location && role.role.isAdmin;
const isDataownerAdminRole = (role: OrgUnitRole) =>
  role.type === InformationModelType.Dataowner && role.role.isAdmin;
const isBrandAdminRole = (role: OrgUnitRole) =>
  role.type === InformationModelType.Brand && role.role.isAdmin;

const getHighestAdminRole = (user: UserData) => {
  const userRoles = user.roles || [];
  return user.metadata.superuser
    ? RoleType.SuperUser
    : userRoles.some(isBrandAdminRole)
    ? RoleType.BrandAdmin
    : userRoles.some(isDataownerAdminRole)
    ? RoleType.DataownerAdmin
    : userRoles.some(isLocationAdminRole)
    ? RoleType.LocationAdmin
    : 'none';
};

const useGoogleAnalyticsEffect = () => {
  const [hasSetGADimensions, setHasSetGaDimensions] = useState<boolean>(false);
  const selectedBrandKey = useAppSelector(({ brand }) => brand.selectedBrandKey);
  const user = useAppSelector(({ user }) => user.userData.data);
  const location = useLocation();

  useEffect(() => {
    if (config.gaMeasurementId && !hasSetGADimensions && user && !!selectedBrandKey) {
      ReactGA.initialize(config.gaMeasurementId, {
        gtagOptions: {
          mg_brand: selectedBrandKey,
          mg_app: 'dap',
        },
      });
      ReactGA.gtag('set', 'user_properties', {
        admin_role: getHighestAdminRole(user),
      });
      setHasSetGaDimensions(true);
      ReactGA.event('page_view');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, selectedBrandKey]);

  useEffect(() => {
    ReactGA.event('page_view');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};

export default useGoogleAnalyticsEffect;
