export enum ObjectType {
  CallToAction = 'callToAction',
  CustomLink = 'customLink',
  EventInformation = 'eventInformation',
  EventRegistration = 'eventRegistration',
  ExternalLinkField = 'externalLinkField',
  ImageCarouselItem = 'imageCarouselItem',
  InternalLinkField = 'internalLinkField',
  LinkListItem = 'linkListItem',
  NavigationItem = 'navigationItem',
  NavigationSubMenu = 'navigationSubMenu',
  NavigationSubMenuItem = 'navigationSubMenuItem',
  SubjectLinkListItem = 'subjectLinkListItem',
}
