import { useNavigate, useMatch } from 'react-router-dom';
import { AppBar, Divider, Stack, Toolbar, useTheme } from '@mui/material';
import { BrandSelect, BrandSelectOption, LanguageSwitch } from '@dap/common/ui';
import { DAPLogo } from '@dap/common/ui';
import { mgprisHeaderHeight } from '@shared/styles';
import { LanguageCapitalLettersCode, LanguageCode } from '@shared/types';
import { getBrandadminRoute } from '@dap/common/consts';
import {
  selectBrandKey,
  useAppDispatch,
  useSelectedBrandKey,
  useUser,
} from '@dap/common/data-access';
// import { brandStylesConfig } from '@dap/common/styles';
// import { RecognizedBrands } from '@dap/common/types';

interface HeaderProps {
  switchLanguage: (languageCode: LanguageCapitalLettersCode) => void;
  language: LanguageCode;
}

// TODO Seems illogical to need to convert between language codes
function convertLanguageCodeThing(code: LanguageCode) {
  switch (code) {
    case LanguageCode.Norwegian:
      return 'no';
    case LanguageCode.Swedish:
      return 'se';
    default:
      return 'en';
  }
}

export default function Header({ switchLanguage, language }: HeaderProps) {
  const navigate = useNavigate();
  const userData = useUser();
  const selectedBrandKey = useSelectedBrandKey();
  const dispatch = useAppDispatch();
  const isBrandAdminRoute = useMatch(`${getBrandadminRoute()}/*`);
  // const brandStyle =
  //   brandStylesConfig[selectedBrandKey as RecognizedBrands] || brandStylesConfig.default;

  const { palette } = useTheme();

  const selectBrand = (brandKey: string) => {
    dispatch(selectBrandKey(brandKey));
    if (isBrandAdminRoute) {
      navigate(getBrandadminRoute());
    } else navigate('/');
  };

  const brandOptions: BrandSelectOption[] = userData.brands.map((brand) => ({
    name: brand.name,
    key: brand.key,
  }));

  if (!userData) return null;

  // This feels backwards that the components don't use the same language codes
  const convertedLangCode = convertLanguageCodeThing(language);

  // const brandColors = brandStyle.colors;
  // const mainBrandColor = brandColors?.main;
  // const lightBrandColor = brandColors?.light;

  return (
    <AppBar position="fixed" sx={{ height: mgprisHeaderHeight }}>
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction="row" alignItems="center" alignSelf="stretch" paddingY={2.5}>
          <BrandSelect
            brands={brandOptions}
            selectedBrandKey={selectedBrandKey}
            selectedTitle="Digital Arbeidsplass"
            onBrandSelect={selectBrand}
          />

          {/* TODO ASO Implement search field */}
          {/* <Divider
            orientation="vertical"
            sx={{ borderColor: palette.background.default, marginX: 3 }}
          /> */}
        </Stack>

        <Stack direction="row" alignItems="center" marginLeft={2} height="100%">
          <DAPLogo />

          {/* TODO ASO  Embed WaffleMenu <div>Vaffelmeny</div> */}
          <Stack direction="row" alignItems="center" alignSelf="stretch" paddingY={2.5}>
            <Divider
              orientation="vertical"
              sx={{ borderColor: palette.background.default, margin: 3 }}
            />
            {/* TODO ASO  Notification <div>Notifications(?)</div> */}
            <LanguageSwitch
              selectedLanguage={convertedLangCode}
              switchLanguage={(langCode) => {
                // This feels backwards that the components don't use the same language codes
                const convertedLangCode = langCode.toUpperCase() as LanguageCapitalLettersCode;
                switchLanguage(convertedLangCode);
              }}
              languages={['no', 'se', 'en']}
            />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
