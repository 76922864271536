import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  OutlinedTextFieldProps,
  Stack,
} from '@mui/material';
import { AsteriskIcon } from '@shared/custom-icons';
import { forwardRef } from 'react';
import { FlexRow } from '../Layout';
import InfoIconWithTooltip from './InfoIconWithTooltip';

interface Props
  extends Pick<
    OutlinedTextFieldProps,
    'onChange' | 'onBlur' | 'name' | 'value' | 'rows' | 'multiline'
  > {
  label?: string;
  error?: string;
  placeholder?: string;
  required?: boolean;
  margin?: 'normal' | 'dense' | 'none';
  fullWidth?: boolean;
  endAdornment?: React.ReactNode;
  tooltipText?: React.ReactNode;
}

/**
 * @deprecated This component is deprecated, use TextInputField instead
 */

const InputText = forwardRef(
  (
    {
      name,
      label,
      error,
      placeholder,
      required,
      margin = 'normal',
      fullWidth,
      onBlur,
      onChange,
      value,
      rows,
      multiline,
      endAdornment,
      tooltipText,
    }: Props,
    ref
  ) => {
    const inputId = `${name}-id`;
    const formLabelId = label && `${name}-label`;
    const helperTextId = error && `${name}-helperText`;

    return (
      <FormControl margin={margin} error={!!error} fullWidth required={required}>
        {label && (
          <FlexRow alignItems="center" mb={0.5} minHeight="20px">
            <FormLabel htmlFor={inputId} id={formLabelId} sx={formLabelSx}>
              {label}
            </FormLabel>
            {tooltipText && <InfoIconWithTooltip tooltipText={tooltipText} />}
          </FlexRow>
        )}
        <Stack direction="row" alignItems="center">
          <OutlinedInput
            size="small"
            id={inputId}
            fullWidth={fullWidth}
            sx={inputSx}
            placeholder={placeholder}
            inputRef={ref}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            value={value}
            aria-describedby={helperTextId}
            rows={rows}
            multiline={multiline}
            autoComplete="off"
            endAdornment={endAdornment}
          />
          {!label && required && <AsteriskIcon fontSize="inherit" />}
          {!label && tooltipText && <InfoIconWithTooltip tooltipText={tooltipText} />}
        </Stack>
        {error && (
          <FormHelperText id={helperTextId} sx={formHelperTextSx}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default InputText;

const inputSx = {
  '& .MuiOutlinedInput-input': {
    padding: '6px 10px',
  },
};

const formLabelSx = {
  typography: 'h4',
  color: 'text.primary',
};

const formHelperTextSx = {
  ml: 0,
  typography: 'body3',
};
