import {
  DateTimeRange,
  EventAddress,
  SanityBlockContent,
  SanityEventPreview,
} from '@dap/sanity/types';
import { UserData } from '@dap/admin/types';
import { format, isSameDay, parseISO } from 'date-fns';
import { SanityDapUserRole, SanityHuskjedeUserRole, SanityUserRole } from '@dap/common/types';
import { DAP_SANITY_HUSKJEDE_BRANDS } from '@dap/common/consts';

export const sanityBrandNamePlaceholder = '{{brand}}';

export const replacePlaceholderWithBrandName = (text: string, brandName: string) =>
  text.split(sanityBrandNamePlaceholder).join(brandName);

export const replaceBodyBrandNamePlaceholder = (
  sanityBody: SanityBlockContent[],
  brandName: string
): SanityBlockContent[] => {
  return sanityBody.map((bodyPiece) => {
    if (bodyPiece.children) {
      const formattedChildren = bodyPiece.children.map((bodyChild: any) => {
        const text = bodyChild.text
          ? replacePlaceholderWithBrandName(bodyChild.text, brandName)
          : undefined;
        return { ...bodyChild, text };
      });
      return { ...bodyPiece, children: formattedChildren };
    }
    return bodyPiece;
  });
};

export type GroupedEvent = {
  [key: string]: SanityEventPreview[];
};

export const groupEventsByMonthYear = (events: SanityEventPreview[]) => {
  return events.reduce((groupedEvents, event) => {
    const monthYear = format(new Date(event.eventInformation.dateTimeRange.startDate), 'yyyy-MM');
    groupedEvents[monthYear]
      ? groupedEvents[monthYear].push(event)
      : (groupedEvents[monthYear] = [event]);
    return groupedEvents;
  }, {} as GroupedEvent);
};

export function getHrefValueForLink(link: string) {
  if (link.startsWith('https://') || link.startsWith('http://') || link.startsWith('mailto:')) {
    return link;
  }
  return `https://${link}`;
}

export function getFullAddress(address: EventAddress) {
  const postalCodeAndCity =
    address.postalCode && address.city ? `${address.postalCode} ${address.city}` : null;
  const fullAddress = [address.street, postalCodeAndCity, address.addressFreeText]
    .filter(Boolean)
    .join(', ');
  return fullAddress;
}

export function getDateRange(dateTimeRange: DateTimeRange) {
  const startDate = parseISO(dateTimeRange.startDate);
  const endDate = dateTimeRange.endDate ? parseISO(dateTimeRange.endDate) : null;
  const formattedStartDate = format(startDate, 'PPp');

  if (!endDate) {
    return formattedStartDate;
  } else {
    if (isSameDay(startDate, endDate)) {
      return `${formattedStartDate} – ${format(endDate, 'p')}`;
    } else {
      return `${formattedStartDate} – ${format(endDate, 'PPp')}`;
    }
  }
}

// Returns true if the user has the specified role in any org unit
export function hasSanityRole(user: UserData, role: SanityUserRole): boolean {
  return user.roles.some((r) => r.role.key === role);
}

// Returns true if the user has any of the specified roles in any org unit
export function hasSomeSanityRoles(user: UserData, roles: SanityUserRole[]): boolean {
  return user.roles.some((r) => roles.includes(r.role.key as SanityUserRole));
}

// Returns an array of all unique sanity roles delegated to a user
export function currentSanityRoles(user: UserData): SanityUserRole[] {
  return user.roles
    .map((r) =>
      [
        SanityDapUserRole.BrandEditor,
        SanityDapUserRole.BrandAdministrator,
        SanityDapUserRole.ShopEditor,
        SanityDapUserRole.Administrator,
        SanityHuskjedeUserRole.Editor,
        SanityHuskjedeUserRole.Administrator,
      ].includes(r.role.key as SanityUserRole)
        ? (r.role.key as SanityUserRole)
        : undefined
    )
    .filter(Boolean)
    .filter((v, i, a) => a.indexOf(v) === i) as SanityUserRole[];
}

// Returns true if the user is a DAP brand editor for the specified brand
export function isSanityDapBrandEditor(user: UserData, brandKey: string): boolean {
  return user.roles.some(
    (r) => r.role.key === SanityDapUserRole.BrandEditor && r.orgUnitKey === brandKey
  );
}

// Returns true if the user is a DAP brand administrator for the specified brand
export function isSanityDapBrandAdministrator(user: UserData, brandKey: string): boolean {
  return user.roles.some(
    (r) => r.role.key === SanityDapUserRole.BrandAdministrator && r.orgUnitKey === brandKey
  );
}

// Returns true if the user has the DAP shop editor role in any org unit
export function isSanityDapShopEditor(user: UserData): boolean {
  return hasSanityRole(user, SanityDapUserRole.ShopEditor);
}

// Returns true if the user has the DAP subject editor role in any org unit
export function isSanityDapSubjectEditor(user: UserData): boolean {
  return hasSanityRole(user, SanityDapUserRole.SubjectEditor);
}

// Returns true if the user has the DAP administrator role in any org unit
export function isSanityDapAdministrator(user: UserData): boolean {
  return hasSanityRole(user, SanityDapUserRole.Administrator);
}

// Returns true if the user has the huskjede editor role in any org unit
// NOT IN USE
// TODO Should be refactored to check for editor role for a specific brand
// export function isSanityHuskjedeEditor(user: UserData): boolean {
//   return hasSanityRole(user, SanityHuskjedeUserRole.Editor);
// }

// Returns true if the user has the huskjede administrator role in any org unit
// NOT IN USE
// TODO Should be refactored to check for adminstrator role for a specific brand
// export function isSanityHuskjedeAdministrator(user: UserData): boolean {
//   return hasSanityRole(user, SanityHuskjedeUserRole.Administrator);
// }

/**
 * Returns true if the user has access to the DAP sanity studio
 *
 * A user might have access if they have any of the following roles:
 * - BrandEditor
 * - BrandAdministrator
 * - ShopEditor
 * - Administrator
 */
export function hasSanityDapAccess(user: UserData): boolean {
  return hasSomeSanityRoles(user, [
    SanityDapUserRole.BrandEditor,
    SanityDapUserRole.BrandAdministrator,
    SanityDapUserRole.ShopEditor,
    SanityDapUserRole.Administrator,
  ]);
}

// Returns true if the user has a any of the SanityHuskjedeUserRoles and is a member of any of the huskjede brands
export function hasSanityHuskjedeAccess(user: UserData): boolean {
  return (
    user.brands.some((brand) => DAP_SANITY_HUSKJEDE_BRANDS.includes(brand.key)) &&
    user.roles.some((role) =>
      [SanityHuskjedeUserRole.Editor, SanityHuskjedeUserRole.Administrator].includes(
        role.role.key as SanityHuskjedeUserRole
      )
    )
  );
}

// Returns a mocked user object with additional roles to be used in development (for situations where new roles needs to be testet before they have been added correctly to kjedeadmin)
export function mockUserData(user: UserData) {
  const mockedUser: UserData = {
    ...user,
    roles: [
      ...user.roles,
      {
        role: {
          key: SanityHuskjedeUserRole.Administrator,
          title: 'Sanityadministrator, Hus',
          selected: false,
          isAdmin: false,
        },
        orgUnitKey: 'blinkhus',
        type: 'brand',
      },
      {
        role: {
          key: SanityHuskjedeUserRole.Editor,
          title: 'Sanityredaktør, Hus',
          selected: false,
          isAdmin: false,
        },
        orgUnitKey: 'no-systemhus-157',
        type: 'brand',
      },
      {
        role: {
          key: 'prosjektsalg',
          title: 'Prosjektsalg',
          selected: false,
          isAdmin: false,
        },
        orgUnitKey: 'no-nordbohus-byggalf-as',
        type: 'location',
      },
      {
        role: {
          key: SanityHuskjedeUserRole.Editor,
          title: 'Sanityredaktør, Hus',
          selected: false,
          isAdmin: false,
        },
        orgUnitKey: 'no-nordbohus-byggalf-as',
        type: 'brand',
      },
      // {
      //   role: {
      //     key: SanityDapUserRole.BrandAdministrator,
      //     title: 'SanityAdministrator kjede, DAP',
      //     selected: false,
      //     isAdmin: false,
      //   },
      //   orgUnitKey: 'blinkhus',
      //   type: 'brand',
      // },
      // {
      //   role: {
      //     key: SanityDapUserRole.Administrator,
      //     title: 'SanityAdministrator, DAP',
      //     selected: false,
      //     isAdmin: false,
      //   },
      //   orgUnitKey: 'blinkhus',
      //   type: 'brand',
      // },
    ],
  };

  // console.log('mockedUser', mockedUser);

  return mockedUser;
}
