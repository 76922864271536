import { DataownerBasic, EmployeeDetail, LocationBasic, LocationOverview } from '@dap/admin/types';

export function getIfMultipleEmployments(employee: EmployeeDetail): boolean {
  return (
    (employee.brands && employee.brands?.length > 1) ||
    (employee.dataowners && employee.dataowners.length > 1) ||
    (employee.locations && employee.locations.length > 1) ||
    false
  );
}

export const getApplicableDataowners = (
  employeeDataowners: Array<DataownerBasic> | undefined,
  brandDataowners: Array<DataownerBasic> | undefined
) =>
  employeeDataowners && brandDataowners && brandDataowners.length > 0
    ? employeeDataowners.filter(({ key }) => brandDataowners.some((m) => m.key === key))
    : [];

export const getApplicableLocations = (
  employeeLocations: Array<LocationBasic> | undefined,
  brandLocations: Array<LocationOverview> | undefined
) =>
  employeeLocations && brandLocations && brandLocations.length > 0
    ? employeeLocations.filter(({ key }) => brandLocations.some((loc) => loc.key === key))
    : [];
