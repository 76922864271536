import { EmployeeDetail } from '@dap/admin/types';
import { useOutletContext } from 'react-router-dom';

export interface EmployeeOutletState {
  selectedEmployee: EmployeeDetail;
}

function useEmployeeRouteOutletContext() {
  const employeeOutletState = useOutletContext<EmployeeOutletState | undefined>();

  if (!employeeOutletState) {
    throw new Error(
      `useEmployeeRouteOutletContext is ${employeeOutletState}. Make sure to use this context in a direct Route child to a Route exposing an Outlet with this context.`
    );
  }

  return employeeOutletState;
}

export default useEmployeeRouteOutletContext;
