import { PROPERTY_VALUE_TYPES } from '@dap/admin/config';
import { brandadminApi } from './brandadminApi';
import { PropertyTags } from './tags';

const tags = [...Object.values(PropertyTags)];

export const propertyApi = brandadminApi.enhanceEndpoints({ addTagTypes: tags }).injectEndpoints({
  endpoints: (build) => ({
    getValueTypes: build.query<Array<string>, void>({
      query: () => PROPERTY_VALUE_TYPES(),
      providesTags: [PropertyTags.VALUE_TYPES],
    }),
  }),
});

export const { useGetValueTypesQuery } = propertyApi;
