import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { Snackbar } from '@dap/common/ui';
import { SnackType } from '@dap/common/types';

interface SnackbarMessagesProps {
  sendMessage: (variant: SnackType, message: string) => void;
}

const SnackbarMessagesContext = createContext<SnackbarMessagesProps | null>(null);

const SnackbarMessagesContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const [variant, setVariant] = useState<SnackType>();
  const [message, setMessage] = useState<string>('');

  const sendMessage = (variant: SnackType, message: string) => {
    setVariant(variant);
    setMessage(message);
  };

  return (
    <SnackbarMessagesProvider sendMessage={sendMessage}>
      {children}
      {message && variant && (
        <Snackbar
          open={!!message}
          variant={variant}
          message={message}
          onClose={() => {
            setVariant(undefined);
            setMessage('');
          }}
        />
      )}
    </SnackbarMessagesProvider>
  );
};

const SnackbarMessagesProvider: React.FC<PropsWithChildren<SnackbarMessagesProps>> = ({
  children,
  ...rest
}) => <SnackbarMessagesContext.Provider value={rest}>{children}</SnackbarMessagesContext.Provider>;

const useSnackbarMessagesContext = () => {
  const context = useContext(SnackbarMessagesContext);

  if (context === null) {
    throw new Error('useSnackbarMessagesContext must be used within a SnackbarMessagesProvider');
  }

  return context;
};

export { SnackbarMessagesContainer, useSnackbarMessagesContext, SnackbarMessagesProvider };
