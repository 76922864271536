import {
  alpha,
  Button,
  FormHelperText,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { messages } from '@dap/common/i18n';
import { useTranslation } from 'react-i18next';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ForwardedRef, forwardRef, useRef } from 'react';
import { useDragAndDrop } from '@shared/hooks';
import { CenterElement } from '../Layout';
import { ErrorOutlineRounded } from '@mui/icons-material';

interface Props {
  selectedFiles: Array<File>;
  onChange: (files: Array<File>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  name: string;
  error?: string;
}

function FileUploadInput(
  { onChange, selectedFiles, onBlur, name, error }: Props,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { t } = useTranslation('common');
  const { isDragging, onDrag, onDrop, onInputChange } = useDragAndDrop();
  const inputRef = useRef<null | HTMLInputElement>(null);

  const handleAddFiles = (files: Array<File>) => {
    const deduplicatedFiles = files.filter(
      (file) => !selectedFiles.some(({ name }) => file.name === name)
    );
    const newSelectedFiles = selectedFiles.concat(deduplicatedFiles);
    onChange(newSelectedFiles);
  };

  const handleDelete = (file: File) => {
    onChange(selectedFiles.filter((attachment: File) => attachment.name !== file.name));
  };

  const handleOnKeyDown = (
    event: React.KeyboardEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLSpanElement>
  ) => {
    if (event.key === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      inputRef.current?.click();
    }
  };

  return (
    <>
      <input
        hidden
        type="file"
        id="input-file-upload"
        multiple
        name={name}
        onBlur={onBlur}
        onChange={onInputChange(handleAddFiles)}
        ref={inputRef}
      />
      <FileUploadDragAndDropArea
        ref={ref}
        isDragActive={isDragging}
        onDragEnter={onDrag}
        onDragLeave={onDrag}
        onDragOver={onDrag}
        onDrop={onDrop(handleAddFiles)}
        error={!!error}
      >
        <FileUploadBox error={!!error}>
          <CenterElement flexDirection="column">
            <AddPhotoAlternateIcon sx={{ fontSize: 40, mb: 2 }} />
            <Typography component="div" variant="body2">
              {t(messages.common.fileUpload.uploadHere)}
              <Link
                color="primary.main"
                component="label"
                id="label-file-upload"
                htmlFor="input-file-upload"
                onKeyDown={handleOnKeyDown}
                tabIndex={0}
              >
                {t(messages.common.fileUpload.chooseFile)}
              </Link>
            </Typography>
            <Stack direction="row" spacing={1}>
              <ErrorOutlineRounded fontSize="small" />
              <Typography variant="body2">
                {t(messages.common.fileUpload.max, { max: '300 MB' })}
              </Typography>
            </Stack>
          </CenterElement>
        </FileUploadBox>
        {!!selectedFiles.length && (
          <List sx={{ pt: 2, pb: 0, width: '100%' }}>
            {selectedFiles.map((file: File, index) => (
              <FileItem
                key={`${file.name}-${index}`}
                secondaryAction={
                  <Button
                    color="inherit"
                    startIcon={<DeleteOutlinedIcon />}
                    onClick={() => handleDelete(file)}
                  >
                    {t(messages.common.delete)}
                  </Button>
                }
              >
                <ListItemIcon>
                  <AttachmentIcon />
                </ListItemIcon>
                {file.name}
              </FileItem>
            ))}
          </List>
        )}
      </FileUploadDragAndDropArea>
      {error && (
        <FormHelperText component="div">
          <Stack direction="row" spacing={1}>
            <ErrorOutlineRounded color="warning" fontSize="small" />
            <Typography typography="body2" fontWeight={500} color="warning.main">
              {error}
            </Typography>
          </Stack>
        </FormHelperText>
      )}
    </>
  );
}

export default forwardRef(FileUploadInput);

const FileUploadDragAndDropArea = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDragActive' && prop !== 'error',
})<{ isDragActive: boolean; error: boolean }>(({ isDragActive, error, theme }) => ({
  minHeight: '200px',
  width: '100%',
  backgroundColor: isDragActive ? theme.palette.grey[200] : theme.palette.grey[100],
  border: `1px solid ${error ? theme.palette.warning.main : theme.palette.grey[400]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const FileUploadBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error: boolean }>(({ error, theme }) => ({
  border: `1px dashed ${error ? theme.palette.warning.main : theme.palette.grey[400]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
}));

const FileItem = styled(ListItem)(({ theme }) => ({
  background: alpha(theme.palette.primary.main, 0.05),
  border: `1px solid ${alpha(theme.palette.primary.dark, 0.1)}`,
  borderRadius: '5px',
  marginBottom: theme.spacing(1),
  ...theme.typography.body2,
}));
