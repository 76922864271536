import { EMPLOYEE_ME } from '@dap/admin/config';
import { PatchEmployeeDto, UserData } from '@dap/admin/types';
import { userDataMapper } from '@dap/common/utils';
import { brandadminApi } from './brandadminApi';
import { UserTags, EmployeeTags } from './tags';

const tags = [...Object.values(UserTags.USER), EmployeeTags.EMPLOYEE];

const userApi = brandadminApi.enhanceEndpoints({ addTagTypes: tags }).injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<UserData, void>({
      query: () => ({ url: EMPLOYEE_ME }),
      transformResponse: userDataMapper,
      providesTags: [UserTags.USER],
    }),
    updateUser: build.mutation<UserData, PatchEmployeeDto>({
      query: (payload) => ({
        method: 'PATCH',
        url: EMPLOYEE_ME,
        body: payload,
        transformResponse: userDataMapper,
      }),
      invalidatesTags: (result, _error, _arg) => [
        UserTags.USER,
        { type: EmployeeTags.EMPLOYEE, id: result?.userId },
      ],
    }),
  }),
  overrideExisting: false,
});

export const { useGetUserQuery, useUpdateUserMutation } = userApi;
