import { BrandadminBaseQueryFn } from '@dap/admin/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthState } from '@shared/state/auth';

export const brandadminApiBaseQuery = fetchBaseQuery({
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as { auth: AuthState }).auth.token;

    if (!token) {
      throw new Error('No access token');
    }
    headers.set('authorization', `Bearer ${token}`);
    return headers;
  },
});

export const brandadminApi = createApi({
  reducerPath: 'brandadmin',
  baseQuery: brandadminApiBaseQuery as BrandadminBaseQueryFn,
  endpoints: () => ({}),
});
