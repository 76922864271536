import { AppFeatureConfig } from '@dap/dashboard/types';

export const defaultBrandFeatures: AppFeatureConfig = {
  completeRegistrationEmailInfo: { resolveToggle: () => 'on' },
  workplace: { resolveToggle: () => 'off' },
  workplaceBanner: { resolveToggle: () => 'off' },
  workplaceEmbedded: {
    resolveToggle: () => 'off',
    config: { src: '' },
  },
  leads: { resolveToggle: () => 'off' },
  news: {
    resolveToggle: ({ news_module }) => (news_module?.show ? 'on' : 'off'),
  },
  proffConcepts: {
    resolveToggle: ({ proff_module }) => (proff_module?.[0]?.show ? 'on' : 'off'),
  },
  events: {
    resolveToggle: ({ events_module }) => (events_module?.show ? 'on' : 'off'),
  },
  order: {
    resolveToggle: ({ order_module }) => (order_module?.[0]?.show ? 'on' : 'off'),
  },
  dashboardBanner: { resolveToggle: () => 'off' },
  intranetBanner: { resolveToggle: () => 'off' },
  mgaBanner: { resolveToggle: () => 'off' },
  serviceMessage: { resolveToggle: () => 'off' },
};
