import { xlByggIconUri } from '@assets/images';
import { AppFeatureConfig } from '@dap/dashboard/types';
import { defaultBrandFeatures } from './defaultBrandFeatures';

const xlbyggFeaturesDefault: AppFeatureConfig = {
  ...defaultBrandFeatures,
  workplace: { resolveToggle: () => 'on' },
  proffConcepts: {
    resolveToggle: ({ proff_module }) => (proff_module?.[0]?.show ? 'off' : 'off'),
    config: {
      proffIcon: xlByggIconUri,
    },
  },
};

export const xlbyggFeaturesDev: AppFeatureConfig = {
  ...xlbyggFeaturesDefault,
};
export const xlbyggFeaturesStage: AppFeatureConfig = {
  ...xlbyggFeaturesDefault,
};
export const xlbyggFeaturesProd: AppFeatureConfig = {
  ...xlbyggFeaturesDefault,
  completeRegistrationEmailInfo: { resolveToggle: () => 'off' },
};
