import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

interface Props {
  rows: {
    cells: {
      value: string;
    }[];
    _key: string;
  }[];
  title?: string;
  topRowIsHeader?: boolean;
}

const SanityTable: React.FC<Props> = ({ rows, title, topRowIsHeader }) => {
  if (!rows || !rows.length) {
    console.error('Missing "rows" field in table configuration');
    return null;
  }

  const headRow = topRowIsHeader ? rows[0] : undefined;
  const bodyRows = topRowIsHeader ? rows.slice(1) : rows;

  return (
    <Box sx={{ margin: '2rem 0' }}>
      {!!title && (
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
      )}
      <TableContainer>
        <Table size="small" stickyHeader>
          {headRow && (
            <TableHead sx={{ fontWeight: 'bold' }}>
              <TableRow>
                {headRow?.cells.map(({ value }) => (
                  <TableCell key={value} sx={{ wordBreak: 'keep-all', minWidth: '150px' }}>
                    <Typography sx={{ fontWeight: 'bold' }}>{value}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {bodyRows.map(({ cells, _key }) => (
              <TableRow key={_key}>
                {cells.map(({ value }, index) => (
                  <TableCell key={index} sx={{ wordBreak: 'keep-all', minWidth: '150px' }}>
                    <Typography>{value}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SanityTable;
