import { RTKMutationError, RTKQueryError } from '@dap/admin/types';

export function getErrorMessage(error: RTKMutationError | RTKQueryError) {
  if (!error) {
    return;
  }

  if ('data' in error && 'message' in error.data) {
    return error.data.message;
  } else if ('data' in error && 'detail' in error.data) {
    return error.data.detail;
  } else {
    return '';
  }
}
