import { Stack, Typography, Box } from '@mui/material';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { messages } from '@dap/common/i18n';
import { maxLinesSx } from '@shared/utils';
import dayjs from 'dayjs';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PreviewSanityImage from '../previewContent/PreviewSanityImage';
import { Person as PersonType } from '@dap/sanity/types';
import { PreviewCard } from '@dap/common/ui';
import { PersonOutlineOutlined, Person, CalendarTodayOutlined } from '@mui/icons-material';

interface Props {
  url: string;
  image: SanityImageSource;
  imgHeight: number;
  title: string;
  intro: string;
  publishedAt: string;
  author?: PersonType[];
}

const NewsPreviewCard: React.FC<Props> = ({
  url,
  image,
  imgHeight,
  title,
  intro,
  publishedAt,
  author,
}) => {
  const { t } = useTranslation('newsArticles');

  const publishedString = useMemo(() => {
    if (!publishedAt) {
      return '';
    }

    const daysPublishedAgo = dayjs().diff(publishedAt, 'day');
    if (daysPublishedAgo === 0) {
      return t(messages.newsArticles.today);
    }
    return t(messages.newsArticles.updatedAgo, {
      amount: daysPublishedAgo,
      timeUnit: 'd',
    });
  }, [publishedAt, t]);

  const hasAuthor = author && author.length > 0;

  return (
    <PreviewCard to={url} variant="elevation">
      <Stack spacing={2}>
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <PreviewSanityImage height={144} image={image} />
        </Box>
        <Typography
          variant="h6"
          sx={{
            ...max2LinesTextSx,
            lineHeight: 21.24 / 18,
            'a:hover &': {
              textDecoration: 'underline',
            },
          }}
          component="span"
        >
          {title}
        </Typography>
      </Stack>
      <Box
        display="grid"
        gridTemplateColumns={'1fr 1fr'}
        sx={{
          borderRadius: 0,
        }}
      >
        <Stack
          direction="row"
          alignItems="flex-start"
          gap={1}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {hasAuthor ? <PersonOutlineOutlined /> : <Person />}
          {hasAuthor && (
            <Typography component="span" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {author.length > 1 ? `${author[0].name} +${author.length - 1}` : author[0].name}
            </Typography>
          )}
        </Stack>
        {publishedString && (
          <Stack direction="row" justifyContent={'flex-end'} gap={1}>
            <CalendarTodayOutlined />
            {publishedString}
          </Stack>
        )}
      </Box>
    </PreviewCard>
  );
};

const max2LinesTextSx = maxLinesSx(2);

export default memo(NewsPreviewCard);
