import { Location, LocationWizardDTO } from '@dap/admin/types';
import { LoadingState, LoadingStatus } from '@dap/common/types';
import { locationDetailMapper } from '@dap/common/utils';
import { LocationDetailDTO } from '@dap/generated-types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AjaxError } from '@shared/fetch-utils';
import { selectBrandKey } from '../brand/brandReducer';

export interface LocationState {
  createNewLocation: LoadingState<Location, AjaxError> & { chainedErrors?: Array<string> };
  createNewLocationAddAdmin: { error?: string };
  createNewLocationAddApps: { error?: string };
}

export const locationInitialState: LocationState = {
  createNewLocation: { status: LoadingStatus.notLoaded },
  createNewLocationAddAdmin: { error: '' },
  createNewLocationAddApps: { error: '' },
};

export const locationSlice = createSlice({
  name: 'location',
  initialState: locationInitialState,
  reducers: {
    createNewLocationRequest: (state, action: PayloadAction<LocationWizardDTO>) => {
      state.createNewLocation.error = undefined;
      state.createNewLocation.chainedErrors = [];
      state.createNewLocation.status = LoadingStatus.loading;
    },
    createNewLocationSuccess: (
      state,
      action: PayloadAction<{ location: LocationDetailDTO; chainedErrors?: Array<string> }>
    ) => {
      state.createNewLocation.chainedErrors = action.payload.chainedErrors;
      state.createNewLocation.data = locationDetailMapper(action.payload.location);
      state.createNewLocation.status = LoadingStatus.success;
    },
    createNewLocationFailure: (state, action: PayloadAction<AjaxError>) => {
      state.createNewLocation.error = action.payload;
      state.createNewLocation.status = LoadingStatus.error;
    },
    createNewLocationAddAdminFailure: (state, action: PayloadAction<{ error: string }>) => {
      state.createNewLocationAddAdmin.error = action.payload.error;
    },
    createNewLocationAddAppsFailure: (state, action: PayloadAction<{ error: string }>) => {
      state.createNewLocationAddApps.error = action.payload.error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(selectBrandKey, () => locationInitialState);
  },
});

export const {
  createNewLocationFailure,
  createNewLocationRequest,
  createNewLocationSuccess,
  createNewLocationAddAdminFailure,
  createNewLocationAddAppsFailure,
} = locationSlice.actions;

export default locationSlice.reducer;
