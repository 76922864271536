import { Card, Typography } from '@mui/material';

type ColorBarColor = 'honey' | 'lilac' | 'avocado' | 'cappuccino';

type ColorMap = {
  [key: string]: [string, boolean];
};

const colorMap: ColorMap = {
  honey: ['#F3C677', true],
  lilac: ['#8E8DBE', false],
  avocado: ['#A5AA52', false],
  cappuccino: ['#C69C72', false],
};

export interface ColorBarProps {
  title: string;
  color?: ColorBarColor;
}

export function ColorBar({ title, color = 'honey' }: ColorBarProps) {
  // If no title is provided, return null
  if (!title) {
    return null;
  }

  const [backgroundColor, useDarkTextColor] = colorMap[color];
  const textColor = useDarkTextColor ? 'grey.800' : 'common.white';

  return (
    <Card sx={{ backgroundColor: backgroundColor, padding: 1.25, paddingLeft: 6 }}>
      <Typography variant="body1" fontWeight={500} color={textColor}>
        {title}
      </Typography>
    </Card>
  );
}
