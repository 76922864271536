import { CustomError, QueryError, UserData } from '@dap/admin/types';
import {
  selectBrandKey,
  useAppDispatch,
  useGetBrandQuery,
  useSelectedBrandKey,
  useUser,
} from '@dap/common/data-access';
import { useAppTFunction } from '@dap/common/i18n';
import { PublicLoadingPage } from '@dap/common/pages';
import {
  ErrorTemplate,
  LoadingErrorComponent,
  MainContent,
  RtkQueryResultWrapper,
} from '@dap/common/ui';
import { brandKeyFromCurrentUrl } from '@dap/common/utils';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { LocalStorage } from '@shared/constants';
import { AjaxError } from '@shared/fetch-utils';
import { useMountEffect } from '@shared/hooks';
import { reportException } from '@shared/reporting';
import { PropsWithChildren } from 'react';

const getInitialBrandKey = (user: UserData | undefined) => {
  const localStorageBrandKey = localStorage.getItem(LocalStorage.BRAND_KEY);

  return (
    brandKeyFromCurrentUrl ||
    (user?.metadata.superuser && localStorageBrandKey) ||
    user?.brands?.find(({ key }) => key === localStorageBrandKey)?.key ||
    user?.brands?.[0]?.key
  );
};

interface Props extends PropsWithChildren {
  logoutCallback: () => void;
}

function AuthorizedPageContainer({ children, logoutCallback }: Props) {
  const dispatch = useAppDispatch();
  const user = useUser();
  const brandKey = useSelectedBrandKey();

  const getBrandQueryResult = useGetBrandQuery(brandKey, { skip: !brandKey });

  useMountEffect(() => {
    const initBrandKey = getInitialBrandKey(user);
    if (initBrandKey) {
      dispatch(selectBrandKey(initBrandKey));
    } else {
      reportException(new Error('AuthorizedPageContainer rendered without user access'), {
        userId: user?.userId,
      });
      logoutCallback();
    }
  });

  return (
    <RtkQueryResultWrapper
      loadingState={getBrandQueryResult}
      LoadingComponent={PublicLoadingPage}
      ErrorComponent={ErrorComponent}
    >
      {() => (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{children}</>
      )}
    </RtkQueryResultWrapper>
  );
}

const ErrorComponent: LoadingErrorComponent<CustomError | QueryError | AjaxError> = (error) => {
  const t = useAppTFunction();

  return (
    <MainContent sx={{ height: '100vh' }}>
      <ErrorTemplate
        icon={<PriorityHighIcon />}
        title={t('error.fetch.specific', { data: t('brandadmin.brand.title.definite') })}
        subtitle={t('error.fetch.description') + ' ' + t('error.contact')}
        errorMessage={JSON.stringify(error)}
      />
    </MainContent>
  );
};

export default AuthorizedPageContainer;
