import { useState } from 'react';
import { messages } from '@dap/common/i18n';
import { CheckboxLabelWithCount } from '@dap/common/ui';
import { SanityServicePreview, SimpleCategory } from '@dap/sanity/types';
import { Card, Stack, Typography, Divider } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ServicePreviewsList from './ServicePreviewsList';
import SanityPageHeader from '../pageContent/SanityPageHeader';
import { SanityBlockContent } from '@dap/sanity/types';
import { SanityBody } from '../pageContent';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { ContainedButton, SearchInputField } from '@shared/components';
import { RadioToggleGroup } from '../components/RadioToggleGroup/RadioToggleGroup';
import TuneIcon from '@mui/icons-material/Tune';
import { ActiveFilters, FilterDrawer } from '../components';
import Fuse from 'fuse.js';

enum ServiceType {
  Central = 'sentrale',
  Local = 'lokale',
}

interface CategoryItem {
  id: string;
  title: string;
  count: number;
}

interface Props {
  title: string;
  info?: SanityBlockContent[];
  services: SanityServicePreview[];
  allServicesRoute: string;
}

const fuseOptions = {
  keys: ['title', 'intro', 'tags'],
  includeScore: true,
  includeMatches: true,
  ignoreLocation: true,
  threshold: 0.3,
};

function ServicesPage({ title, info, services, allServicesRoute }: Props) {
  const [showFilters, setShowFilters] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation(['services', 'common']);
  const categories = searchParams.get('categories');
  const query = searchParams.get('q');
  const type = searchParams.get('type');
  const [queryValue] = useDebounce(query ?? '', 1000 / 30); // Debounce query from url to improve performance

  const typeOptions = [
    {
      label: t(messages.services.filters.type.central),
      value: ServiceType.Central,
    },
    {
      label: t(messages.services.filters.type.local),
      value: ServiceType.Local,
    },
  ];

  // On mount, create array of all unique categories from all services, with a count of how many services are in each category, sorted by title
  const allCategories = useMemo(() => {
    // Flatten all categories from all services
    const allCategories: SimpleCategory[] =
      services?.flatMap(({ categories }) => categories ?? []) || [];

    // Remove duplicates
    const uniqueCategories = allCategories.reduce((accumulator, current) => {
      const existingCategory = accumulator.find((obj) => obj.id === current.id);

      if (existingCategory) {
        existingCategory.count++;
        return accumulator;
      }

      return [...accumulator, { ...current, count: 1 }];
    }, [] as CategoryItem[]);

    // Sort categories by title
    return uniqueCategories.sort((a, b) => a.title.localeCompare(b.title));
  }, []);

  // Iterate categoried and create array of CheckboxGroupOptionItem items for filter drawer
  const mappedCategories = useMemo(() => {
    return allCategories.map((category) => ({
      value: category.id,
      label: <CheckboxLabelWithCount label={category.title} count={category.count} />,
    }));
  }, [allCategories]);

  // Filter items by categories and local/central to limit scope of fuse index
  const itemsByCategories = useMemo(() => {
    if (!categories && !type) {
      return services;
    }

    // Filter services by categories and type
    const filtered = services.filter((service) => {
      // A service is considerd a local service if it only exists for one brand (which will be the current brand as the services list is groq'ed on matching the brand)
      const isLocal = service.brand.length === 1;
      const matchesCategories =
        !categories ||
        service.categories?.some((category) => categories.split(',').includes(category.id));
      const matchesType =
        !type ||
        (type === ServiceType.Central && !isLocal) ||
        (type === ServiceType.Local && isLocal);

      return matchesCategories && matchesType;
    });

    return filtered;
  }, [services, categories, type]);

  // Create a new Fuse instance with the items filtered by tags and type (for potentially better performance)
  const fuse = useMemo(() => {
    const f = new Fuse(itemsByCategories, fuseOptions);

    return f;
  }, [itemsByCategories]);

  // Filter the reduced set of items on the query string using Fuse
  const filteredItems = useMemo(() => {
    if (!queryValue) {
      return itemsByCategories;
    }

    const itemsByQuery = queryValue
      ? fuse.search(queryValue).map((result) => {
          return result.item;
        })
      : itemsByCategories;

    return itemsByQuery;
  }, [itemsByCategories, queryValue, fuse]);

  // Create list of tags that are checked
  const activeCategories = useMemo(
    () =>
      categories
        ? (categories
            .split(',')
            .map((c) => {
              const title = allCategories.find((cat) => cat.id === c)?.title;

              if (!title) return undefined;

              return {
                value: c,
                label: title,
              };
            })
            .filter(Boolean) as { value: string; label: string }[])
        : [],
    [categories, allCategories]
  );

  // Group all services by category
  const groupServicesByCategories = useMemo(
    () =>
      filteredItems.reduce((acc, service) => {
        const filteredServiceCategories = service.categories?.filter(
          (category) => !categories || categories?.split(',').includes(category.id)
        );

        filteredServiceCategories?.forEach((category) => {
          if (!acc[category.title]) {
            acc[category.title] = [];
          }
          acc[category.title].push(service);
        });
        return acc;
      }, {} as Record<string, SanityServicePreview[]>),
    [filteredItems]
  );

  // Sorted groups by number of services in each category
  const sortedCategoriesByCount = useMemo(
    () =>
      Object.entries(groupServicesByCategories)
        .sort(([, servicesA], [, servicesB]) => servicesB.length - servicesA.length)
        .reduce((acc, [title, services]) => {
          acc[title] = services;
          return acc;
        }, {} as Record<string, SanityServicePreview[]>),
    [groupServicesByCategories]
  );

  const updateSearchParams = ({
    params = {},
    clearParams,
  }: {
    params?: Record<string, string>;
    clearParams?: string[];
  }) => {
    // Convert URLSearchParams object to object of key, value pairs
    const currentParams: Record<string, string> = {};
    for (const [key, value] of searchParams.entries()) {
      currentParams[key] = value;
    }

    const nextParams = {
      ...currentParams,
      ...params,
    };

    clearParams?.forEach((param) => {
      delete nextParams[param];
    });

    setSearchParams(nextParams);
  };

  // Handles changes to the input field
  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value === '') {
      updateSearchParams({ clearParams: ['q'] });
      return;
    }

    updateSearchParams({ params: { q: event.target.value } });
  };

  const hasFiltersOrQuery = categories || query;

  return (
    <Stack spacing={3}>
      <Card sx={{ padding: 6 }}>
        <SanityPageHeader header={title} sx={{ marginBottom: 3 }} />
        {info && (
          <SanityBody
            body={info}
            variant="h6"
            sx={{
              fontWeight: 500,
              marginTop: 3,
            }}
          />
        )}

        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Stack
            spacing={3}
            direction="row"
            mt={3}
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Stack spacing={3} direction="row">
              <SearchInputField
                variant="filled"
                value={query ?? ''}
                onChange={handleQueryChange}
                onReset={() => updateSearchParams({ clearParams: ['q'] })}
                placeholder={t(messages.services.filters.placeholder)}
              />
              <ContainedButton
                startIcon={<TuneIcon sx={{ transform: 'rotate(90deg)' }} />}
                onClick={() => setShowFilters(!showFilters)}
                size="small"
                sx={{ whiteSpace: 'nowrap' }}
              >
                {showFilters
                  ? t(`common:${messages.common.filter.close}`)
                  : t(`common:${messages.common.filter.open}`)}
              </ContainedButton>
            </Stack>
            <RadioToggleGroup
              name="type"
              label={t(messages.services.toggle.label)}
              labelAll={t(messages.services.toggle.all)}
              size="small"
              options={typeOptions}
              selectedOption={type ?? ''}
              onChangeOption={(name, value) => {
                if (value === '') {
                  updateSearchParams({ clearParams: [name] });
                  return;
                }
                updateSearchParams({ params: { [name]: value } });
              }}
            />
          </Stack>
        </form>
      </Card>

      <FilterDrawer
        title={t(`common:${messages.common.filter.name}`)}
        open={showFilters}
        onClose={() => setShowFilters(false)}
        onChangeFilter={(name, value) => {
          if (value.length === 0) {
            updateSearchParams({ clearParams: [name] });
            return;
          }

          updateSearchParams({ params: { [name]: value.join(',') } });
        }}
        onChangeToggle={(name, value) => {
          if (value === '') {
            updateSearchParams({ clearParams: [name] });
            return;
          }

          updateSearchParams({ params: { [name]: value } });
        }}
        toggles={[
          {
            name: 'type',
            label: t(messages.services.toggle.label),
            labelAll: t(messages.services.toggle.all),
            options: typeOptions,
            selectedOption: type ?? '',
          },
        ]}
        filters={[
          {
            name: 'categories',
            items: mappedCategories,
            selectedItems: categories?.split(',') ?? [],
          },
        ]}
      />

      <Stack spacing={3} direction="row" width="100%">
        <Card sx={{ padding: 4, flex: '1 1 auto' }}>
          <Stack direction="row">
            <Typography
              variant="body1"
              sx={{
                whiteSpace: 'nowrap',
                flexShrink: 0,
                lineHeight: '32px',
              }}
            >
              {t(messages.services.results, {
                count: filteredItems.length,
                total: services.length,
              })}
            </Typography>
            {hasFiltersOrQuery && (
              <Divider orientation="vertical" sx={{ marginInline: 3, height: '32px' }} />
            )}
            <ActiveFilters
              filters={activeCategories}
              onClickFilter={(value) => {
                const next = categories ? categories.split(',').filter((c) => c !== value) : [];

                if (next.length === 0) {
                  updateSearchParams({ clearParams: ['categories'] });
                  return;
                }

                updateSearchParams({ params: { categories: next.join(',') } });
              }}
              resetLabel={t(`common:${messages.common.filter.clear}`)}
              onClickReset={() => {
                setSearchParams({});
              }}
              query={query ?? undefined}
              onClearQuery={() => {
                updateSearchParams({ clearParams: ['q'] });
              }}
            />
          </Stack>
        </Card>
      </Stack>
      {Object.entries(sortedCategoriesByCount).map(([category, services]) => (
        <Card key={category}>
          <Typography variant="h3" component="h2" sx={{ marginBottom: 3 }}>
            {category}
          </Typography>
          <ServicePreviewsList
            servicePreviews={services}
            emptyListMessage={t(messages.services.filters.noMatch, { ns: 'services' })}
          />
        </Card>
      ))}
    </Stack>
  );
}

export default ServicesPage;
