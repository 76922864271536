import React from 'react';
import { Box, BoxProps } from '@mui/material';

export interface Props extends BoxProps {
  childMargin?: string;
}

const FlexColumn: React.FC<Props> = ({ children, childMargin, ...props }) => (
  <Box {...props} display="flex" flexDirection="column" sx={{ '& > *': { m: childMargin } }}>
    {children}
  </Box>
);

export default FlexColumn;
